import httpClient from "../../../httpClient";
import { API_ROLE_LISTING, API_USER_CASE_TRANSFER, API_USER_INFO, API_USER_PROFILE, API_USER_RESTORE, API_ROLE_ALL_LISTING, API_USER_IMAGE, API_USER_LOGOUT } from "../../../config";

export const rolesListing = {
    get: () => httpClient.get(API_ROLE_ALL_LISTING),
};

export const userProfile = {
    get: (id) => httpClient.get(API_USER_PROFILE + '/' + id),
};

export const userCaseTransfer = {
    post: (data) => httpClient.post(API_USER_CASE_TRANSFER, data),
};

export const userRestore = {
    get: (id) => httpClient.get(API_USER_RESTORE + '/' + id),
};
export const userLogout = {
    get: () => httpClient.get(API_USER_LOGOUT),
};

export const userImage = {
    get: (params) => {
        const path = params.path ? `?path=${params.path}` : '';
        return httpClient.get(`${API_USER_IMAGE}${path}`);
    }
}
