
import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from 'react-epic-spinners';
import Pagination from "../../components/pagination";
import deleteProcedure from "../../api/services/deleteProcedure";
import procedureListing from './../../api/services/procedureListing';
import CreateProcedure from "../../components/procedure/createProcedure";
import UpdateProcedureInfo from "../../components/procedure/updateProcedureInfo";
import { useAuth } from "../../api/services/auth";
import { Navigate } from "react-router-dom";
import { userPermissions } from "../../enums";

const Procedures = (props) => {
    const { userExist, permission } = useAuth()
    const [proceduresList, setProceduresList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [createUser, setShowCreateUser] = useState(false);
    const [token, setToken] = useState(localStorage.getItem("authToken"));
    const [showLoader, setShowLoader] = useState(false);
    const [selectedProcedure, setSelectedProcedure] = useState({});
    const [selectedProcedureId, setSelectedProcedureId] = useState(null);
    const [positionListing, setPositionListing] = useState([]);
    const [loading, setLoading] = useState(true);

    const [formData, setFormData] = useState({
        name: '',
    });
    let oneRequest = true

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Procedure Management | Cappagh";
    }, []);

    // Get token from LocalStorage
    useEffect(() => {
        setToken(localStorage.getItem("authToken"));

        if (token === undefined) {
            window.location.pathname = "/";
        }
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        procedureListing.getProcedures(pageNo)
            .then((res) => {
                handleScrollToTop()
                setProceduresList(res.data);
                setPagination(res.meta);
            }
            ).catch(error => console.error('Error fetching data:', error));
    };

    // Handle_Show_Filters
    const handleShowFilter = () => {
        setShowFilters(!showFilters)
        procedureListing.getProcedures()
            .then((res) => {
                setProceduresList(res.data);
                setPagination(res.meta);
            }
            ).catch(error => console.error('Error fetching data:', error));
    }

    // Handle_Show_Create_User
    const handleShowCreateUser = () => {
        setShowCreateUser(!createUser)
    }

    // Handle_Show_Update_User
    const handleShowUpdateUser = () => {
        setShowUpdateUser(!showUpdateUser)
    }

    // Handle_Delete_Procedure
    const handleDeleteClick = (userId) => {
        setSelectedProcedureId(userId);
    };

    // Handle_Delete_User
    const handleDeleteUser = () => {
        setShowLoader(true);

        deleteProcedure.delete(selectedProcedureId)
            .then((res) => {

                setShowLoader(false);
                toast.success(res.message);
                procedureListing.getProcedures(1)
                    .then((res) => {
                        setProceduresList(res.data);
                        setPagination(res.meta);
                    }
                    ).catch(error => console.error('Error fetching data:', error));

            }
            ).catch((error) => {
                setShowLoader(false);
                toast.error(error.data.message);
            });
        setSelectedProcedureId(null);
    };

    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSearch = () => {
        // Call the API with the form data
        procedureListing.getProcedures(1, formData)
            .then((res) => {
                setProceduresList([...res.data]);
                setPagination({ ...res.meta });
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    // Get_Users_Listing
    useEffect(() => {
        if (oneRequest) {
            oneRequest = false;
            procedureListing.getProcedures(1)
                .then((res) => {
                    setLoading(false)
                    setProceduresList(res.data);
                    setPagination(res.meta);
                }
                ).catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                });

        }
    }, [selectedProcedureId]);


    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <>
            <div className="dbContentWrap">
                {/* Create_User_Form */}
                {!showFilters && !showUpdateUser && createUser &&
                    <CreateProcedure
                        createUser={createUser}
                        setShowCreateUser={setShowCreateUser}
                        token={token}
                        setProceduresList={setProceduresList}
                        setPagination={setPagination}
                    />
                }

                {/* Update_UserInfo_Form */}
                {!showFilters && !createUser && showUpdateUser &&
                    <UpdateProcedureInfo
                        setShowUpdateUser={setShowUpdateUser}
                        selectedProcedure={selectedProcedure}
                        handleShowUpdateUser={handleShowUpdateUser}
                        token={token}
                        setProceduresList={setProceduresList}
                        setPagination={setPagination}
                    />
                }
                {/* Users_Listing */}
                <div className="tableWidget">
                    <article className="widgetWrap">
                        <div className="widgetHeader">
                            <span>Procedure Management</span>
                            <div className="tabHeadActionWrap">
                                {permission?.some(p => (p.name === userPermissions.PROCEDURES_CREATE)) && (
                                    <button onClick={() => {
                                        setShowCreateUser(true);
                                        setShowFilters(false);
                                        setShowUpdateUser(false);
                                        handleScrollToTop();
                                    }} type="button" className={`actionBtn ${createUser && 'active'}`}>Add Procedure <i className="fa-solid fa-user-plus"></i></button>
                                )}
                            </div>
                        </div>
                        <div className="tableWrap">
                            <table className="table fontSmall textCenter w100">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(proceduresList && (proceduresList?.length > 0)) ? proceduresList.map(item => (
                                        <tr key={item.id}>
                                            <td data-label="Name">{item.name}</td>
                                            <td data-label="Status">{item.status == '1' ? "Active" : "Inactive"}</td>

                                            <td data-label="Actions">
                                                <div>
                                                    {permission?.some(p => (p.name === userPermissions.PROCEDURES_UPDATE)) && (
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleScrollToTop();
                                                                setShowUpdateUser(true)
                                                                setShowFilters(false);
                                                                setShowCreateUser(false);
                                                                setSelectedProcedure({ ...item })
                                                            }}
                                                            className="actionIcn edit fa-solid fa-pencil"
                                                        ></button>
                                                    )}
                                                    {permission?.some(p => (p.name === userPermissions.PROCEDURES_DELETE)) && (

                                                        <button
                                                            onClick={() => {
                                                                handleDeleteClick(item.id)
                                                            }}
                                                            type="button"
                                                            className="actionIcn delete fa-solid fa-trash"
                                                        ></button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )) : loading ?
                                        <tr>
                                            <td colSpan={9}><HalfCircleSpinner
                                                size={18}
                                                color='black'
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                            /></td>
                                        </tr> :
                                        <tr>
                                            <td colSpan={8}>No record found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="widgetFooter">
                            <div className="textCenter">
                                {pagination && (Object.keys(pagination)?.length > 0) && (
                                    <Pagination pagination={pagination} handlePageClick={handlePageClick} />
                                )}
                            </div>
                        </div>
                    </article>
                </div>
            </div>
            {/* Delete_User_Popup */}
            <Popup className="deleteUserModal" open={selectedProcedureId !== null} onClose={() => setSelectedProcedureId(null)}>
                {selectedProcedureId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to delete this Procedure.</p>
                        <div className="btnWrap">
                            <button onClick={() => setSelectedProcedureId(null)} type="button" className="btn btnSecondary">Cancel</button>
                            <button
                                onClick={handleDeleteUser}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Delete
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    );
}

export default Procedures;