import React, { useEffect, useState } from 'react'
import { sortData } from '../../utils';
import Popup from 'reactjs-popup';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { toast } from 'react-toastify';
import { useAuth } from '../../api/services/auth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { closeFlagCaseList, openFlagCaseList } from '../../api/services/cases';
import FlagCasesRow from './../../components/caseItemRow/flagCasesRow';
import CaseSearch from '../../components/case-listing/caseSearch';
import Pagination from '../../components/pagination';
import bookmarkCasee from '../../api/services/bookmarkCase';
import { flagcaseBookmarkCase } from '../../api/services/flagcase';


function FlaggedCases() {
    const location = useLocation()
    const [active, setActive] = useState(location?.state?.upcoming ? true : false);
    const [openCases, setOpenedCases] = useState([]);
    const [closeCases, setClosedCases] = useState([]);
    const [selectedCaseBookmark, setSelectedCaseBookmark] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedCaseId, setSelectedCaseId] = useState(null);
    const [publishedLoading, setOpenedLoading] = useState(true);
    const [upcomingLoading, setClosedLoading] = useState(true);
    const [bookmarkCaseType, setBookmarkCaseType] = useState('')
    const [filteredSearch, setFilteredSearch] = useState(false)
    const [showFilters, setShowFilters] = useState(false);
    const [pagination, setPagination] = useState({});
    const [upcommingPagination, setClosedPagination] = useState({});
    const [formData, setFormData] = useState({
        comorbidity_id: '',
        complication_id: '',
        procedure_id: '',
        consultant_id: '',
        flag_status: '',
        reference: '',
        procedure_date: '',
        state: 'all'
    });
    const [publishedSorting, setPublishedSorting] = useState({
        column: null,
        order: 'asc'
    });
    const [upcommingSorting, setUpcommingSorting] = useState({
        column: null,
        order: 'asc'
    });

    // Get_Current_Cases_Listing
    useEffect(() => {
        openFlagCaseList.getCases(1)
            .then((res) => {
                setOpenedLoading(false)
                setOpenedCases(res.data.map(item => ({
                    ...item,
                    status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                })));
                setPagination({ ...res.meta });
            }
            ).catch(error => {
                setOpenedLoading(false)
                console.error('Error fetching data:', error)
            });

        closeFlagCaseList.getCases(1)
            .then((res) => {
                setClosedCases(res.data.map(item => ({
                    ...item,
                    status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                })));
                setClosedPagination({ ...res.meta });
                setClosedLoading(false)
            }
            ).catch(error => {
                setClosedLoading(false)
                console.error('Error fetching data:', error)
            });
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };

    // Handle_Bookmark_Click
    const handleBookmarkCaseClick = (caseId, bookmark, caseType) => {
        setBookmarkCaseType(caseType)
        setSelectedCaseBookmark(bookmark);
        setSelectedCaseId(caseId);
        if (bookmark === 0) {
            setSelectedCaseBookmark(1);
        } else {
            setSelectedCaseBookmark(0);
        }
    };

    // Handle_Bookmark_Case
    const handleBookmarkCase = () => {
        setShowLoader(true);

        let caseId = selectedCaseId;
        let bookmark = selectedCaseBookmark;

        const requestBody = {
            bookmark: bookmark,
        };

        flagcaseBookmarkCase
            .bookmark(caseId, requestBody)
            .then((res) => {
                setShowLoader(false);
                toast.success(res.message);
                if (bookmarkCaseType === 'opened') {
                    openFlagCaseList.getCases(1)
                        .then((res) => {
                            setOpenedCases(res.data);
                            setPagination({ ...res.meta });
                        }
                        ).catch(error => {
                            console.error('Error fetching data:', error)
                        });

                } else if (bookmarkCaseType === 'closed') {
                    closeFlagCaseList.getCases(1)
                        .then((res) => {
                            setClosedCases(res.data);
                            setClosedPagination({ ...res.meta });
                        }
                        ).catch(error => {
                            console.error('Error fetching data:', error)
                        });
                }
            }
            ).catch((error) => {
                setShowLoader(false);
                toast.error(error.data.message);
            }
            );
        setSelectedCaseId(null);
        setSelectedCaseBookmark(null);
        setBookmarkCaseType('')
    };

    // Handle_Sort_Table_Data
    const handleOpenedSort = (columnName) => {
        setPublishedSorting((prevSorting) => ({
            column: columnName,
            order: prevSorting.column === columnName && prevSorting.order === 'asc' ? 'desc' : 'asc',
        }));

        // Sort the data based on the selected column and order
        const sortedData = sortData(openCases, publishedSorting, setPublishedSorting, columnName, publishedSorting.order);
        setOpenedCases(sortedData);
    };

    const isOpenedColumnSorted = (columnName) => {
        return publishedSorting.column === columnName;
    };

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        openFlagCaseList.getCases(1)
            .then((res) => {
                handleScrollToTop()
                setOpenedLoading(false)
                setOpenedCases(res.data);
                setPagination({ ...res.meta });
                setPublishedSorting({
                    column: null,
                    order: 'asc'
                });
            }
            ).catch(error => {
                setOpenedLoading(false)
                console.error('Error fetching data:', error)
            });
    };

    // Hadle_Page_Click
    const handleUpcommingPageClick = (pageNo) => {
        closeFlagCaseList.getCases(1)
            .then((res) => {
                handleScrollToTop()
                setClosedCases(res.data);
                setClosedPagination({ ...res.meta });
                setClosedLoading(false)
                setUpcommingSorting({
                    column: null,
                    order: 'asc'
                });
            }
            ).catch(error => {
                setClosedLoading(false)
                console.error('Error fetching data:', error)
            });
    };

    // Handle_Sort_Table_Data
    const handleClosedSort = (columnName) => {
        setUpcommingSorting((prevSorting) => ({
            column: columnName,
            order: prevSorting.column === columnName && prevSorting.order === 'asc' ? 'desc' : 'asc',
        }));

        // Sort the data based on the selected column and order
        const sortedData = sortData(closeCases, upcommingSorting, setUpcommingSorting, columnName, upcommingSorting.order);
        setClosedCases(sortedData);
    };

    const isClosedColumnSorted = (columnName) => {
        return upcommingSorting.column === columnName;
    };

    const handleSwitch = (status) => {
        setShowFilters(false);
        setPublishedSorting({
            column: null,
            order: 'asc'
        })
        setUpcommingSorting({
            column: null,
            order: 'asc'
        })
        setFormData({
            comorbidity_id: '',
            complication_id: '',
            procedure_id: '',
            consultant_id: '',
            flag_status: '',
            state: 'all'
        })
        if (status !== 'active') {
            setActive(false)
            openFlagCaseList.getCases(1)
                .then((res) => {
                    setOpenedLoading(false)
                    setOpenedCases(res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    })));
                    setPagination({ ...res.meta });
                }
                ).catch(error => {
                    setOpenedLoading(false)
                    console.error('Error fetching data:', error)
                });

        } else {
            setActive(true)
            closeFlagCaseList.getCases(1)
                .then((res) => {
                    setClosedCases(res.data.map(item => ({
                        ...item,
                        status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                    })));
                    setClosedPagination({ ...res.meta });
                    setClosedLoading(false)
                }
                ).catch(error => {
                    setClosedLoading(false)
                    console.error('Error fetching data:', error)
                });
        }
    }
    const handleOpenedFilter = (e) => {
        const state = e.target.value;
        setFormData({ ...formData, state })
        openFlagCaseList.getCases(1, { ...formData, state })
            .then((res) => {
                setOpenedCases([...res.data.map(item => ({
                    ...item,
                    status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                }))]);
                setPagination(res.meta);
            }
            ).catch(error => {
                console.error('Error fetching data:', error)
            });
    }

    const handleClosedFilter = (e) => {
        const state = e.target.value;
        setFormData({ ...formData, state })
        closeFlagCaseList.getCases(1, { ...formData, state })
            .then((res) => {
                setClosedCases([...res.data.map(item => ({
                    ...item,
                    status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                }))]);
                setClosedPagination(res.meta);
            }
            ).catch(error => {
                console.error('Error fetching data:', error)
            });
    }


    return (
        <div>
            <div className="dbContentWrap">
                {showFilters && <CaseSearch
                    setFilteredSearch={setFilteredSearch}
                    setLoading={!active ? setOpenedLoading : setClosedLoading}
                    caseCall={!active ? 'opened' : 'closed'}
                    setShowFilters={setShowFilters}
                    setCasesList={!active ? setOpenedCases : setClosedCases}
                    formData={formData}
                    setFormData={setFormData}
                    setPagination={!active ? setPagination : setClosedPagination}
                />}
                <div className="tableWidget">
                    <ul className="navTabs listUnstyled">
                        <li onClick={() => {
                            handleSwitch('inActive')

                        }} className={!active ? "active" : ""}><a>Opened Cases</a></li>
                        <li onClick={() => {
                            handleSwitch('active')

                        }} className={active ? "active" : ""}><a >Closed Cases</a></li>
                    </ul>
                    <div className="tabsContentWrap">
                        {!active && (
                            <div id="tab1" className="tabContent">
                                <article className="widgetWrap">
                                    <div className="widgetHeader">
                                        <span>Group Cases  <div className="selectWrap posRelative caseFilters">
                                            <select onChange={handleOpenedFilter} name="cases" className="widgetSelect">
                                                <option value="all">All</option>
                                                <option value="1">One Week</option>
                                                <option value="2">Two Week</option>
                                            </select>
                                        </div></span>
                                        <div className="tabHeadActionWrap">
                                            <button onClick={() => {
                                                setShowFilters(true);
                                            }} type="button" className={`actionBtn ${showFilters && 'active'}`}>Search Filter <i className="fa-sharp fa-solid fa-filters"></i></button>
                                        </div>
                                    </div>
                                    <div className="tableWrap">
                                        <table className="table fontSmall textCenter w100">
                                            <thead>
                                                <tr>
                                                    <th>Ref</th>
                                                    <th
                                                        className={`sortable ${isOpenedColumnSorted("date") ? " sorted" : ""
                                                            }`}
                                                        onClick={() => handleOpenedSort("date")}
                                                    >
                                                        Date <i className="icn fa-solid fa-caret-down"></i>
                                                    </th>
                                                    <th
                                                        className={`sortable ${isOpenedColumnSorted("flagged-by") ? " sorted" : ""
                                                            }`}
                                                        onClick={() => handleOpenedSort("flagged-by")}
                                                    >
                                                        Flagged By{" "}
                                                        <i className="icn fa-solid fa-caret-down"></i>
                                                    </th>
                                                    <th>
                                                        Consultant{" "}
                                                        <i className="fa-regular fa-magnifying-glass"></i>
                                                    </th>
                                                    <th>
                                                        Procedure{" "}
                                                        <i className="fa-regular fa-magnifying-glass"></i>
                                                    </th>
                                                    <th>
                                                        Comorbidities{" "}
                                                        <i className="fa-regular fa-magnifying-glass"></i>
                                                    </th>
                                                    <th>
                                                        Complications{" "}
                                                        <i className="fa-regular fa-magnifying-glass"></i>
                                                    </th>
                                                    <th
                                                        className={`sortable ${isOpenedColumnSorted("status") ? " sorted" : ""
                                                            }`}
                                                        onClick={() => handleOpenedSort("status")}
                                                    >
                                                        Status <i className="icn fa-solid fa-caret-down"></i>
                                                    </th>
                                                    <th>Comments </th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(openCases && (openCases?.length > 0)) ? openCases.map((data) => (
                                                    <FlagCasesRow
                                                        data={data}
                                                        handleBookmarkCaseClick={handleBookmarkCaseClick}
                                                        setCasesList={setOpenedCases}
                                                        status='opened'
                                                    />
                                                )) : publishedLoading ?
                                                    <tr>
                                                        <td colSpan={10}><HalfCircleSpinner
                                                            size={18}
                                                            color='black'
                                                            style={{
                                                                display: "inline-block",
                                                                marginLeft: "7px",
                                                                position: "relative",
                                                                top: "2px",
                                                            }}
                                                        /></td>
                                                    </tr> :
                                                    <tr>
                                                        <td colSpan={10}>No record found</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <div className="widgetFooter">
                                        <div className="textCenter">
                                            <Link to="/published-cases">View More</Link>
                                        </div>
                                    </div> */}
                                    <div className="widgetFooter">
                                        <div className="textCenter">
                                            {pagination && (Object.keys(pagination)?.length > 0) && (
                                                <Pagination pagination={pagination} handlePageClick={handlePageClick} />
                                            )}
                                        </div>
                                    </div>
                                </article>
                            </div>
                        )}
                        {active && (
                            <div id="tab2" className="tabContent">
                                <article className="widgetWrap">
                                    <div className="widgetHeader">
                                        <span>Group Cases <div className="selectWrap posRelative caseFilters">
                                            <select onChange={handleClosedFilter} name="cases" className="widgetSelect">
                                                <option value="all">All</option>
                                                <option value="1">One Week</option>
                                                <option value="2">Two Week</option>
                                            </select>
                                        </div></span>
                                        <div className="tabHeadActionWrap">
                                            <button onClick={() => {
                                                setShowFilters(true);
                                            }} type="button" className={`actionBtn ${showFilters && 'active'}`}>Search Filter <i className="fa-sharp fa-solid fa-filters"></i></button>
                                        </div>
                                    </div>
                                    <div className="tableWrap">
                                        <table className="table fontSmall textCenter w100">
                                            <thead>
                                                <tr>
                                                    <th>Ref</th>
                                                    <th
                                                        className={`sortable ${isClosedColumnSorted("date") ? " sorted" : ""
                                                            }`}
                                                        onClick={() => handleClosedSort("date")}
                                                    >
                                                        Date <i className="icn fa-solid fa-caret-down"></i>
                                                    </th>
                                                    <th
                                                        className={`sortable ${isClosedColumnSorted("flagged-by") ? " sorted" : ""
                                                            }`}
                                                        onClick={() => handleClosedSort("flagged-by")}
                                                    >
                                                        Flagged By{" "}
                                                        <i className="icn fa-solid fa-caret-down"></i>
                                                    </th>
                                                    <th>
                                                        Consultant{" "}
                                                        <i className="fa-regular fa-magnifying-glass"></i>
                                                    </th>
                                                    <th>
                                                        Procedure{" "}
                                                        <i className="fa-regular fa-magnifying-glass"></i>
                                                    </th>
                                                    <th>
                                                        Comorbidities{" "}
                                                        <i className="fa-regular fa-magnifying-glass"></i>
                                                    </th>
                                                    <th>
                                                        Complications{" "}
                                                        <i className="fa-regular fa-magnifying-glass"></i>
                                                    </th>
                                                    <th
                                                        className={`sortable ${isClosedColumnSorted("status") ? " sorted" : ""
                                                            }`}
                                                        onClick={() => handleClosedSort("status")}
                                                    >
                                                        Status <i className="icn fa-solid fa-caret-down"></i>
                                                    </th>
                                                    <th>Comments </th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(closeCases && (closeCases?.length > 0)) ? closeCases.map((data) => (
                                                    <FlagCasesRow
                                                        data={data}
                                                        handleBookmarkCaseClick={handleBookmarkCaseClick}
                                                        setCasesList={setClosedCases}
                                                        status='closed'
                                                    />
                                                )) : upcomingLoading ?
                                                    <tr>
                                                        <td colSpan={10}><HalfCircleSpinner
                                                            size={18}
                                                            color='black'
                                                            style={{
                                                                display: "inline-block",
                                                                marginLeft: "7px",
                                                                position: "relative",
                                                                top: "2px",
                                                            }}
                                                        /></td>
                                                    </tr> :
                                                    <tr>
                                                        <td colSpan={10}>No record found</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="widgetFooter">
                                        <div className="textCenter">
                                            {upcommingPagination && (Object.keys(upcommingPagination)?.length > 0) && (
                                                <Pagination pagination={upcommingPagination} handlePageClick={handleUpcommingPageClick} />
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className="widgetFooter">
                                        <div className="textCenter">
                                            <Link to="/upcoming-cases">View More</Link>
                                        </div>
                                    </div> */}
                                </article>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Bookmark_Case_Popup */}
            <Popup
                className="deleteUserModal"
                open={selectedCaseId !== null && selectedCaseBookmark !== null}
                onClose={() => {
                    setSelectedCaseId(null)
                    setSelectedCaseBookmark(null)
                }}
            >
                {selectedCaseId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to {selectedCaseBookmark === 0 ? 'close bookmark on' : 'bookmark'}  this case?</p>
                        <div className="btnWrap">
                            <button
                                onClick={() => {
                                    setSelectedCaseId(null)
                                    setSelectedCaseBookmark(null)
                                    setBookmarkCaseType('')
                                }}
                                type="button"
                                className="btn btnSecondary"
                            >Cancel</button>
                            <button
                                onClick={handleBookmarkCase}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Confirm
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>

        </div>
    )
}

export default FlaggedCases