import { API_MY_CASES } from "../../config";
import httpClient from "../../httpClient";


const myCasesConsultant = {
    get: (pageNo, params) => {
        params = params && typeof params === 'object' ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== '' && value !== null)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const endpoint = API_MY_CASES + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

        return httpClient.get(endpoint);
    },
};

export default myCasesConsultant;
