import React, { useState, useEffect } from "react";
import { useAuth } from "../../api/services/auth";
import { Navigate, useNavigate } from "react-router-dom";
import bookmarkCasee from "../../api/services/bookmarkCase";
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from 'react-epic-spinners';
import Popup from 'reactjs-popup';
import Pagination from "../../components/pagination";
import { sortData } from "../../utils";
import CaseSearch from "../../components/case-listing/caseSearch";
import myCasesConsultant from "../../api/services/my-consultant-cases";
import { userPermissions, userRoles } from "../../enums";
import { deleteCase } from "../../api/services/cases";
import MyCaseRow from "../../components/caseItemRow/myCasesRow";

function MyCases() {
    const navigate = useNavigate()
    const { userExist, user, permission } = useAuth()
    const [token, setToken] = useState(localStorage.getItem("authToken"));
    const [casesList, setCasesList] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [selectedCaseBookmark, setSelectedCaseBookmark] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedCaseId, setSelectedCaseId] = useState(null);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(true);
    const [filteredSearch, setFilteredSearch] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    const [formData, setFormData] = useState({
        comorbidity_id: '',
        complication_id: '',
        procedure_id: '',
        consultant_id: '',
        flag_status: '',
        reference: '',
        procedure_date: '',
        state: "all"
    });
    const [sorting, setSorting] = useState({
        column: null,
        order: 'asc'
    });
    let oneRequest = true;

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Upcoming Cases | Cappagh";
    }, []);

    // Get token from LocalStorage
    useEffect(() => {
        setToken(localStorage.getItem("authToken"));

        if (token === undefined) {
            window.location.pathname = "/";
        }
    }, []);

    // Get_Cases_Listing
    useEffect(() => {
        if (oneRequest) {
            oneRequest = false;
            myCasesConsultant.get(1)
                .then((res) => {
                    setCasesList(res.data);
                    setPagination(res.meta);
                    setLoading(false)

                }
                ).catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                });
        }
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };


    // Handle_Bookmark_Click
    const handleBookmarkCaseClick = (caseId, bookmark) => {
        setSelectedCaseBookmark(bookmark);
        setSelectedCaseId(caseId);
        if (bookmark === 0) {
            setSelectedCaseBookmark(1);
        } else {
            setSelectedCaseBookmark(0);
        }
    };

    // Handle_Bookmark_Case
    const handleBookmarkCase = () => {
        setShowLoader(true);

        let caseId = selectedCaseId;
        let bookmark = selectedCaseBookmark;

        const requestBody = {
            bookmark: bookmark,
        };

        bookmarkCasee.bookmark(caseId, requestBody)
            .then((res) => {
                setShowLoader(false);
                toast.success(res.message);

                myCasesConsultant.get(1)
                    .then((res) => {
                        setCasesList(res.data);
                    }
                    ).catch(error => console.error('Error fetching data:', error));
                setPagination(res.meta);
            }
            ).catch((error) => {
                setShowLoader(false);
                toast.error(error.data.message);
            }
            );
        setSelectedCaseId(null);
        setSelectedCaseBookmark(null);
    };

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        setLoading(true)
        if (filteredSearch) {
            myCasesConsultant.get(pageNo, formData)
                .then((res) => {
                    handleScrollToTop()
                    setCasesList(res.data);
                    setPagination(res.meta);
                    setSorting({
                        column: null,
                        order: 'asc'
                    })
                    setLoading(false)
                    if (res?.meta?.last_page > 1) {
                        setFilteredSearch(true)
                    } else {
                        setFilteredSearch(false)
                    }
                }
                ).catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                });
        } else {
            myCasesConsultant.get(pageNo)
                .then((res) => {
                    handleScrollToTop()
                    setCasesList(res.data);
                    setPagination(res.meta);
                }
                ).catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                });
        }
    };


    // Handle_Sort_Table_Data
    const handleSort = (columnName) => {
        setSorting((prevSorting) => ({
            column: columnName,
            order: prevSorting.column === columnName && prevSorting.order === 'asc' ? 'desc' : 'asc',
        }));

        // Sort the data based on the selected column and order
        const sortedData = sortData(casesList, sorting, setSorting, columnName, sorting.order);
        setCasesList(sortedData);
    };

    const isColumnSorted = (columnName) => {
        return sorting.column === columnName;
    };

    // Handle_Delete_CASE
    const handleDeleteCase = () => {
        setLoading(true);
        deleteCase.delete(deleteId)
            .then((res) => {
                setLoading(false);
                toast.success(res.message);
                myCasesConsultant.get(1)
                    .then((res) => {
                        setCasesList(res.data.map(item => ({
                            ...item,
                            status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                        })));
                        setPagination(res.meta);
                        setLoading(false)
                        setDeleteId(null)
                    }
                    ).catch(error => {
                        setLoading(false)
                        setDeleteId(null)
                        console.error('Error fetching data:', error)
                    });

            }
            ).catch((error) => {
                setLoading(false);
                setDeleteId(null)
                toast.error(error.data.message);
            });
    };

    const handleFilter = (e) => {
        const state = e.target.value;
        myCasesConsultant.get(1, { ...formData, state })
            .then((res) => {
                setCasesList(res.data.map(item => ({
                    ...item,
                    status: item && item.status ? item.status.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase()) : "-"
                })));
                setPagination(res.meta);
                setLoading(false)
            }
            ).catch(error => {
                setLoading(false)
                console.error('Error fetching data:', error)
            });
    }


    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <>
            <div className="dbContentWrap">
                {showFilters && <CaseSearch
                    setFilteredSearch={setFilteredSearch}
                    myCase={'myCase'}
                    setLoading={setLoading}
                    setShowFilters={setShowFilters}
                    setCasesList={setCasesList}
                    setPagination={setPagination}
                    formData={formData}
                    setFormData={setFormData}
                />}
                {/* Cases_Listing */}
                <div className="tableWidget">
                    <article className="widgetWrap">
                        <div className="widgetHeader">
                            <span>My Cases  <div className="selectWrap posRelative caseFilters">
                                <select onChange={handleFilter} name="cases" className="widgetSelect">
                                    <option value="all">All</option>
                                    <option value="1">One Week</option>
                                    <option value="2">Two Weeks</option>
                                </select>
                            </div></span>
                            <div className="tabHeadActionWrap">
                                <button onClick={() => {
                                    setShowFilters(true);
                                }} type="button" className={`actionBtn ${showFilters && 'active'}`}>Search Filter <i className="fa-sharp fa-solid fa-filters"></i></button>
                            </div>
                        </div>
                        <div className="tableWrap">
                            <table className="table fontSmall textCenter w100">
                                <thead>
                                    <tr>
                                        <th
                                            className={`sortable ${isColumnSorted('reference') ? ' sorted' : ''}`}
                                            onClick={() => handleSort('reference')}
                                        >Ref <i className="icn fa-solid fa-caret-down"></i></th>
                                        <th
                                            className={`sortable ${isColumnSorted('date') ? ' sorted' : ''}`}
                                            onClick={() => handleSort('date')}
                                        >Date <i className="icn fa-solid fa-caret-down"></i></th>
                                        <th>Procedure  <i className="fa-regular fa-magnifying-glass"></i></th>
                                        <th>Comorbidities  <i className="fa-regular fa-magnifying-glass"></i></th>
                                        <th>Complications  <i className="fa-regular fa-magnifying-glass"></i></th>
                                        <th>Case Notes</th>
                                        <th>Comments</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(casesList && (casesList?.length > 0)) ? casesList.map((data) => (
                                        <MyCaseRow
                                            data={data}
                                            handleBookmarkCaseClick={handleBookmarkCaseClick}
                                            setCasesList={setCasesList}
                                            setDeleteId={setDeleteId}
                                        />
                                    )) : loading ?
                                        <tr>
                                            <td colSpan={9}><HalfCircleSpinner
                                                size={18}
                                                color='black'
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                            /></td>
                                        </tr> :
                                        <tr>
                                            <td colSpan={9}>No record found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="widgetFooter">
                            <div className="textCenter">
                                {pagination && (Object.keys(pagination)?.length > 0) && (
                                    <Pagination pagination={pagination} handlePageClick={handlePageClick} />
                                )}
                            </div>
                        </div>
                    </article>
                </div>
            </div>
            {/* Bookmark_Case_Popup */}
            <Popup
                className="deleteUserModal"
                open={selectedCaseId !== null && selectedCaseBookmark !== null}
                onClose={() => {
                    setSelectedCaseId(null)
                    setSelectedCaseBookmark(null)
                }}
            >
                {selectedCaseId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to bookmark this case.</p>
                        <div className="btnWrap">
                            <button
                                onClick={() => {
                                    setSelectedCaseId(null)
                                    setSelectedCaseBookmark(null)
                                }}
                                type="button"
                                className="btn btnSecondary"
                            >Cancel</button>
                            <button
                                onClick={handleBookmarkCase}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Confirm
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>

            {/* DELETE_Case_Popup */}
            <Popup
                className="deleteUserModal"
                open={deleteId !== null}
                onClose={() => {
                    setDeleteId(null)
                }}
            >
                {deleteId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to Archive this case?</p>
                        <div className="btnWrap">
                            <button
                                onClick={() => {
                                    setDeleteId(null)
                                }}
                                type="button"
                                className="btn btnSecondary"
                            >No</button>
                            <button
                                onClick={handleDeleteCase}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Yes
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    )
}

export default MyCases