import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
    departmentAllUser,
    userDepartmentCases,
} from "../../api/services/departmnent-services";
import SearchUserList from "./searchUserList";
import { userCaseTransfer } from "../../api/services/user-services/role";
import userListing from "../../api/services/userListing";
import { HalfCircleSpinner } from "react-epic-spinners";
import CasesList from "./casesList";
import IndividualCaseUser from "./individualCaseUser";

const DeavtiveUserSearch = ({
    showTransferUserSearch,
    setDepartment,
    showforDepartment,
    setShowForDepartment,
    selectedUserId,
    setPagination,
    setUsersLists,
    setShowTransferUserSearch,
    department,
    setShowDeactivateUser,
    showTransferCase,
    setShowTransferCase,
    selectedUserToDeactivate,
    group,
    setGroup,
    groupListing,
    setGroupListing
}) => {
    const [positionListing, setPositionListing] = useState([]);
    const [firstNameList, setFirstNameList] = useState([]);
    const [surNameList, setSurNameList] = useState([]);
    const [userNameList, setUserNameList] = useState([]);
    const [emailList, setEmailNameList] = useState([]);
    const [userList, setUsersList] = useState([]);
    const [tranferUser, setTransferUser] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [usersLoading, setUsersLoaidng] = useState(true);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [allCasesOfUser, setAllCasesOfUser] = useState([]);
    const [allUsersOfDepartment, setAllUsersOfDepartment] = useState([]);
    const [selectedCases, setSelectedCases] = useState([]);
    const [formData, setFormData] = useState({
        username: "",
        first_name: "",
        surname: "",
        position_id: "",
        email: "",
    });
    // Handle_Search_Change
    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle_Search_API_Call
    const handleSearch = () => {
        departmentAllUser
            .get(department, { ...formData, group_id: group, type: selectedUserToDeactivate.type })
            .then((res) => {
                const data = res.data.filter((item) => item.id !== selectedUserId);
                setUsersList([...data]);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitLoader(true);
        let formData = null;
        if (showTransferUserSearch === "all") {
            formData = {
                old_user_id: selectedUserToDeactivate.id,
                new_user_id: tranferUser.id,
                department_id: department,
                group_id: group
            };
        } else if (showTransferUserSearch === "individually") {
            formData = {
                old_user_id: selectedUserToDeactivate.id,
                new_user_id: tranferUser.id,
                department_id: department,
                group_id: group
            };

            if (selectedCases && selectedCases.length > 0) {
                formData.cases_id = selectedCases;
            }
        }

        userCaseTransfer
            .post(formData)
            .then((res) => {
                if (res.status === 200) {
                    setSubmitLoader(false);
                    toast.success(
                        "User has been deactivated and active cases transferred successfully"
                    );
                    setShowSuccess(true);
                    setShowDeactivateUser(false);
                    setShowTransferCase(false);
                    setShowForDepartment(false);
                    setSelectedCases([]);
                    setGroup(null)
                    setGroupListing([])

                    userListing
                        .getUsers(1)
                        .then((res) => {
                            setUsersLists([...res.data]);
                            setPagination({ ...res.meta });
                        })
                        .catch((error) => {
                            console.error("Error fetching data:", error);
                        });
                    // Set setShowSuccess to false after 5 seconds
                    setTimeout(() => {
                        setShowTransferUserSearch("");
                        setShowSuccess(false);
                        setDepartment(null);
                        setSelectedCases([]);
                    }, 5000);
                }
            })
            .catch((error) => {
                setSubmitLoader(false);
                if (
                    error.data.message == "HasCases" ||
                    error.data.message == "has_cases"
                ) {
                    setShowForDepartment(true);
                    setShowTransferUserSearch("");
                    setShowDeactivateUser(true);
                    setShowSuccess(false);
                    setSelectedCases([]);
                    setGroup(null)
                    setGroupListing([])
                } else {
                    toast.error(error.data.message);
                }
            });
    };

    const handleCancel = () => {
        setShowTransferUserSearch("");
        setShowDeactivateUser(false);
        setShowTransferCase(false);
        setShowForDepartment(false);
        setDepartment(null);
        setGroup(null)
        setGroupListing([])
        setShowSuccess(false);
        setAllCasesOfUser([]);
        setSubmitLoader(false);
        setSelectedCases([]);
    }

    useEffect(() => {
        if (showTransferUserSearch === "all") {
            departmentAllUser
                .get(department, { group_id: group, type: selectedUserToDeactivate.type })
                .then((res) => {
                    setUsersLoaidng(false);
                    const data = res.data.filter((item) => item.id !== selectedUserId);
                    setFirstNameList(
                        Array.from(new Set(data.map((item) => item.first_name))).map(
                            (name) => ({ name, value: name })
                        )
                    );
                    setSurNameList(
                        Array.from(new Set(data.map((item) => item.surname))).map(
                            (name) => ({ name, value: name })
                        )
                    );
                    setUserNameList(
                        Array.from(new Set(data.map((item) => item.username))).map(
                            (name) => ({ name, value: name })
                        )
                    );
                    setEmailNameList(
                        Array.from(new Set(data.map((item) => item.email))).map((name) => ({
                            name,
                            value: name,
                        }))
                    );
                    const positionSet = new Set();
                    const uniquePositions = data.filter(item => item.position_id).reduce((acc, item) => {
                        if (!positionSet.has(item.position_id)) {
                            positionSet.add(item.position_id);
                            acc.push({ name: item.position, value: item.position_id });
                        }
                        return acc;
                    }, []);
                    setPositionListing(uniquePositions);
                })
                .catch((error) => {
                    setUsersLoaidng(false);
                    console.error("Error fetching data:", error);
                });
        }
        if (showTransferUserSearch === "individually") {
            userDepartmentCases
                .get(department, selectedUserId, { group_id: group, type: selectedUserToDeactivate.type })
                .then((response) => {
                    if (response.status === 200) {
                        setAllCasesOfUser(response.data.all_cases);
                        setAllUsersOfDepartment(response.data.all_users);
                        const data = response.data.all_users.filter(
                            (item) => item.id !== selectedUserId
                        );
                        setFirstNameList(
                            Array.from(new Set(data.map((item) => item.first_name))).map(
                                (name) => ({ name, value: name })
                            )
                        );
                        setSurNameList(
                            Array.from(new Set(data.map((item) => item.surname))).map(
                                (name) => ({ name, value: name })
                            )
                        );
                        setUserNameList(
                            Array.from(new Set(data.map((item) => item.username))).map(
                                (name) => ({ name, value: name })
                            )
                        );
                        setEmailNameList(
                            Array.from(new Set(data.map((item) => item.email))).map(
                                (name) => ({ name, value: name })
                            )
                        );
                        const positionSet = new Set();
                        const uniquePositions = data.filter(item => item.position_id).reduce((acc, item) => {
                            if (!positionSet.has(item.position_id)) {
                                positionSet.add(item.position_id);
                                acc.push({ name: item.position, value: item.position_id });
                            }
                            return acc;
                        }, []);
                        setPositionListing(uniquePositions);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, []);

    return (
        <>
            <div className="userForms userDeactiveSearch">
                <article className="widgetWrap">
                    {showTransferUserSearch === "all" && (
                        <>
                            {usersLoading && (
                                <div className="textCenter txtWhite noteTxt">
                                    Users List Loading
                                </div>
                            )}
                            {!usersLoading && showTransferCase && !showSuccess ? (
                                <div className="widgetBody">
                                    <div className="dbRow">
                                        <div className="dbCol dbColLg33">
                                            <div className="formRow">
                                                <label className="txtLabel">Username</label>
                                                <div className="selectWrap posRelative">
                                                    <select
                                                        name="username"
                                                        className="formControl"
                                                        id="username"
                                                        onChange={handleSearchChange}
                                                    >
                                                        <option value="" defaultValue>
                                                            Select Username
                                                        </option>
                                                        {userNameList &&
                                                            userNameList?.length > 0 &&
                                                            userNameList.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dbCol dbColLg33">
                                            <div className="formRow">
                                                <label className="txtLabel">First Name</label>
                                                <div className="selectWrap posRelative">
                                                    <select
                                                        name="first_name"
                                                        className="formControl"
                                                        id="first_name"
                                                        onChange={handleSearchChange}
                                                    >
                                                        <option value="" defaultValue>
                                                            Select First Name
                                                        </option>
                                                        {firstNameList &&
                                                            firstNameList?.length > 0 &&
                                                            firstNameList.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dbCol dbColLg33">
                                            <div className="formRow">
                                                <label className="txtLabel">Surname</label>
                                                <div className="selectWrap posRelative">
                                                    <select
                                                        name="surname"
                                                        className="formControl"
                                                        id="surname"
                                                        onChange={handleSearchChange}
                                                    >
                                                        <option value="" defaultValue>
                                                            Select Surname
                                                        </option>
                                                        {surNameList &&
                                                            surNameList?.length > 0 &&
                                                            surNameList.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dbCol dbColLg33">
                                            <div className="formRow">
                                                <label className="txtLabel">Position</label>
                                                <div className="selectWrap posRelative">
                                                    <select
                                                        name="position_id"
                                                        className="formControl"
                                                        id="position_id"
                                                        onChange={handleSearchChange}
                                                    >
                                                        <option value="" defaultValue>
                                                            Select Position
                                                        </option>
                                                        {positionListing &&
                                                            positionListing?.length > 0 &&
                                                            positionListing.map((item) => (
                                                                <option key={item.id} value={item.value}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dbCol dbColLg33">
                                            <div className="formRow">
                                                <label className="txtLabel">Email</label>
                                                <div className="selectWrap posRelative">
                                                    <select
                                                        name="email"
                                                        className="formControl"
                                                        id="email"
                                                        onChange={handleSearchChange}
                                                    >
                                                        <option value="" defaultValue>
                                                            Select Email
                                                        </option>
                                                        {emailList &&
                                                            emailList?.length > 0 &&
                                                            emailList.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btnWrap">
                                        <button
                                            type="button"
                                            className="btn btnWhite"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btnPrimary"
                                            onClick={handleSearch}
                                        >
                                            Search For User To Transfer Cases To
                                        </button>
                                    </div>
                                    {userList && userList.length > 0 && (
                                        <SearchUserList
                                            setTransferUser={setTransferUser}
                                            userList={userList}
                                            setShowTransferCase={setShowTransferCase}
                                        />
                                    )}
                                </div>
                            ) : (
                                tranferUser &&
                                !showSuccess &&
                                selectedUserToDeactivate && (
                                    <>
                                        <div className="userForms casesTransfer userDeactiveSearch">
                                            <article className="widgetWrap">
                                                <div className="widgetBody">
                                                    <div className="textCenter txtWhite noteTxt txtText">
                                                        You are deactivating this user
                                                    </div>
                                                    <div className="tableWrap mt2">
                                                        <table className="table tableSecondary txtWhite fontSmall textCenter w100">
                                                            <thead>
                                                                <tr>
                                                                    <th>Username</th>
                                                                    <th>First name</th>
                                                                    <th>Surname</th>
                                                                    <th>Position</th>
                                                                    <th>Email</th>
                                                                    <th>Contact info</th>
                                                                    <th>Profile image</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td data-label="Username">
                                                                        {selectedUserToDeactivate.username}
                                                                    </td>
                                                                    <td data-label="First name">
                                                                        {selectedUserToDeactivate.first_name}
                                                                    </td>
                                                                    <td data-label="Surname">
                                                                        {selectedUserToDeactivate.surname}
                                                                    </td>
                                                                    <td
                                                                        data-label="Position"
                                                                        data-text-alignment="left"
                                                                    >
                                                                        {selectedUserToDeactivate.position_name}
                                                                    </td>
                                                                    <td
                                                                        data-label="Email"
                                                                        data-text-alignment="left"
                                                                    >
                                                                        {selectedUserToDeactivate.email}
                                                                    </td>
                                                                    <td
                                                                        data-label="Contact Info"
                                                                        data-text-alignment="left"
                                                                    >
                                                                        {selectedUserToDeactivate.phone_number}
                                                                    </td>
                                                                    <td data-label="Profile Image">
                                                                        <div className="userImageWrap">
                                                                            {selectedUserToDeactivate.image ? (
                                                                                <img
                                                                                    src={selectedUserToDeactivate.image}
                                                                                    className="imgFluid"
                                                                                    alt="image description"
                                                                                    onContextMenu={(e) =>
                                                                                        e.preventDefault()
                                                                                    }
                                                                                // onError={(e) => {
                                                                                //     handleImageError();
                                                                                //     e.target.onerror = null;
                                                                                //     e.target.src =`${Placeholder}`
                                                                                // }}
                                                                                />
                                                                            ) : (
                                                                                <div className="placeholderImage">
                                                                                    ?
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="textCenter txtWhite noteTxt txtText mt5">
                                                        And assigning all active cases to this user
                                                    </div>
                                                    <div className="tableWrap mt2">
                                                        <table className="table tableSecondary txtWhite fontSmall textCenter w100">
                                                            <thead>
                                                                <tr>
                                                                    <th>Username</th>
                                                                    <th>First name</th>
                                                                    <th>Surname</th>
                                                                    <th>Position</th>
                                                                    <th>Email</th>
                                                                    <th>Contact info</th>
                                                                    <th>Profile image</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td data-label="Username">
                                                                        {tranferUser?.username}
                                                                    </td>
                                                                    <td data-label="First name">
                                                                        {tranferUser?.first_name}
                                                                    </td>
                                                                    <td data-label="Surname">
                                                                        {tranferUser.surname}
                                                                    </td>
                                                                    <td
                                                                        data-label="Position"
                                                                        data-text-alignment="left"
                                                                    >
                                                                        {tranferUser?.position}
                                                                    </td>
                                                                    <td
                                                                        data-label="Email"
                                                                        data-text-alignment="left"
                                                                    >
                                                                        {tranferUser?.email}
                                                                    </td>
                                                                    <td
                                                                        data-label="Contact Info"
                                                                        data-text-alignment="left"
                                                                    >
                                                                        {tranferUser?.phone_number}
                                                                    </td>
                                                                    <td data-label="Profile Image">
                                                                        <div className="userImageWrap">
                                                                            {tranferUser.image ? (
                                                                                <img
                                                                                    src={tranferUser.image}
                                                                                    className="imgFluid"
                                                                                    alt="image description"
                                                                                    onContextMenu={(e) =>
                                                                                        e.preventDefault()
                                                                                    }
                                                                                // onError={(e) => {
                                                                                //     handleImageError();
                                                                                //     e.target.onerror = null;
                                                                                //     e.target.src =`${Placeholder}`
                                                                                // }}
                                                                                />
                                                                            ) : (
                                                                                <div className="placeholderImage">
                                                                                    ?
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="btnWrap">
                                                        <button
                                                            type="button"
                                                            className="btn btnWhite btnPrimaryOutline"
                                                            onClick={handleCancel}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btnPrimary"
                                                            onClick={handleSubmit}
                                                        >
                                                            Assign Cases and Deactivate User{" "}
                                                            {submitLoader && (
                                                                <HalfCircleSpinner
                                                                    size={18}
                                                                    style={{
                                                                        display: "inline-block",
                                                                        marginLeft: "7px",
                                                                        position: "relative",
                                                                        top: "2px",
                                                                    }}
                                                                />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    </>
                                )
                            )}
                        </>
                    )}
                    {showTransferUserSearch === "individually" && (
                        <>
                            <div className="widgetBody">
                                {!tranferUser && !showSuccess && (
                                    <>
                                        <div className="dbRow">
                                            <div className="dbCol dbColLg33">
                                                <div className="formRow">
                                                    <label className="txtLabel">Username</label>
                                                    <div className="selectWrap posRelative">
                                                        <select
                                                            name="username"
                                                            className="formControl"
                                                            id="username"
                                                            onChange={handleSearchChange}
                                                        >
                                                            <option value="" defaultValue>
                                                                Select Username
                                                            </option>
                                                            {userNameList &&
                                                                userNameList?.length > 0 &&
                                                                userNameList.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dbCol dbColLg33">
                                                <div className="formRow">
                                                    <label className="txtLabel">First Name</label>
                                                    <div className="selectWrap posRelative">
                                                        <select
                                                            name="first_name"
                                                            className="formControl"
                                                            id="first_name"
                                                            onChange={handleSearchChange}
                                                        >
                                                            <option value="" defaultValue>
                                                                Select First Name
                                                            </option>
                                                            {firstNameList &&
                                                                firstNameList?.length > 0 &&
                                                                firstNameList.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dbCol dbColLg33">
                                                <div className="formRow">
                                                    <label className="txtLabel">Surname</label>
                                                    <div className="selectWrap posRelative">
                                                        <select
                                                            name="surname"
                                                            className="formControl"
                                                            id="surname"
                                                            onChange={handleSearchChange}
                                                        >
                                                            <option value="" defaultValue>
                                                                Select Surname
                                                            </option>
                                                            {surNameList &&
                                                                surNameList?.length > 0 &&
                                                                surNameList.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dbCol dbColLg33">
                                                <div className="formRow">
                                                    <label className="txtLabel">Position</label>
                                                    <div className="selectWrap posRelative">
                                                        <select
                                                            name="position_id"
                                                            className="formControl"
                                                            id="position_id"
                                                            onChange={handleSearchChange}
                                                        >
                                                            <option value="" defaultValue>
                                                                Select Position
                                                            </option>
                                                            {positionListing &&
                                                                positionListing?.length > 0 &&
                                                                positionListing.map((item) => (
                                                                    <option key={item.id} value={item.value}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dbCol dbColLg33">
                                                <div className="formRow">
                                                    <label className="txtLabel">Email</label>
                                                    <div className="selectWrap posRelative">
                                                        <select
                                                            name="email"
                                                            className="formControl"
                                                            id="email"
                                                            onChange={handleSearchChange}
                                                        >
                                                            <option value="" defaultValue>
                                                                Select Email
                                                            </option>
                                                            {emailList &&
                                                                emailList?.length > 0 &&
                                                                emailList.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btnWrap">
                                            <button
                                                type="button"
                                                className="btn btnWhite"
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btnPrimary"
                                                onClick={handleSearch}
                                            >
                                                Search For User To Transfer Cases To
                                            </button>
                                        </div>

                                        {userList && userList.length > 0 && (
                                            <IndividualCaseUser
                                                selectedCases={selectedCases}
                                                setTransferUser={setTransferUser}
                                                userList={userList}
                                                setShowTransferCase={setShowTransferCase}
                                            />
                                        )}
                                    </>
                                )}
                                {tranferUser && !showSuccess && selectedUserToDeactivate && (
                                    <>
                                        <div className="userForms casesTransfer userDeactiveSearch">
                                            <article className="">
                                                <div className="">
                                                    <div className="textCenter txtWhite textText noteTxt">
                                                        You are deactivating this user
                                                    </div>
                                                    <div className="tableWidget">
                                                        <div className="tableWrap mt2">
                                                            <table className="table tableSecondary txtWhite fontSmall textCenter w100">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Username</th>
                                                                        <th>First name</th>
                                                                        <th>Surname</th>
                                                                        <th>Position</th>
                                                                        <th>Email</th>
                                                                        <th>Contact info</th>
                                                                        <th>Profile image</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td data-label="Username">
                                                                            {selectedUserToDeactivate.username}
                                                                        </td>
                                                                        <td data-label="First name">
                                                                            {selectedUserToDeactivate.first_name}
                                                                        </td>
                                                                        <td data-label="Surname">
                                                                            {selectedUserToDeactivate.surname}
                                                                        </td>
                                                                        <td
                                                                            data-label="Position"
                                                                            data-text-alignment="left"
                                                                        >
                                                                            {selectedUserToDeactivate.position_name}
                                                                        </td>
                                                                        <td
                                                                            data-label="Email"
                                                                            data-text-alignment="left"
                                                                        >
                                                                            {selectedUserToDeactivate.email}
                                                                        </td>
                                                                        <td
                                                                            data-label="Contact Info"
                                                                            data-text-alignment="left"
                                                                        >
                                                                            {selectedUserToDeactivate.phone_number}
                                                                        </td>
                                                                        <td data-label="Profile Image">
                                                                            <div className="userImageWrap">
                                                                                {selectedUserToDeactivate.image ? (
                                                                                    <img
                                                                                        src={selectedUserToDeactivate.image}
                                                                                        className="imgFluid"
                                                                                        alt="image description"
                                                                                        onContextMenu={(e) =>
                                                                                            e.preventDefault()
                                                                                        }
                                                                                    // onError={(e) => {
                                                                                    //     handleImageError();
                                                                                    //     e.target.onerror = null;
                                                                                    //     e.target.src =`${Placeholder}`
                                                                                    // }}
                                                                                    />
                                                                                ) : (
                                                                                    <div className="placeholderImage">
                                                                                        ?
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="textCenter txtWhite noteTxt txtText mt5">
                                                        And assigning all active cases to this user
                                                    </div>
                                                    <div className="tableWidget">
                                                        <div className="tableWrap mt2">
                                                            <table className="table tableSecondary txtWhite fontSmall textCenter w100">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Username</th>
                                                                        <th>First name</th>
                                                                        <th>Surname</th>
                                                                        <th>Position</th>
                                                                        <th>Email</th>
                                                                        <th>Contact info</th>
                                                                        <th>Profile image</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td data-label="Username">
                                                                            {tranferUser?.username}
                                                                        </td>
                                                                        <td data-label="First name">
                                                                            {tranferUser?.first_name}
                                                                        </td>
                                                                        <td data-label="Surname">
                                                                            {tranferUser.surname}
                                                                        </td>
                                                                        <td
                                                                            data-label="Position"
                                                                            data-text-alignment="left"
                                                                        >
                                                                            {tranferUser?.position}
                                                                        </td>
                                                                        <td
                                                                            data-label="Email"
                                                                            data-text-alignment="left"
                                                                        >
                                                                            {tranferUser?.email}
                                                                        </td>
                                                                        <td
                                                                            data-label="Contact Info"
                                                                            data-text-alignment="left"
                                                                        >
                                                                            {tranferUser?.phone_number}
                                                                        </td>
                                                                        <td data-label="Profile Image">
                                                                            <div className="userImageWrap">
                                                                                {tranferUser.image ? (
                                                                                    <img
                                                                                        src={tranferUser.image}
                                                                                        className="imgFluid"
                                                                                        alt="image description"
                                                                                        onContextMenu={(e) =>
                                                                                            e.preventDefault()
                                                                                        }
                                                                                    // onError={(e) => {
                                                                                    //     handleImageError();
                                                                                    //     e.target.onerror = null;
                                                                                    //     e.target.src =`${Placeholder}`
                                                                                    // }}
                                                                                    />
                                                                                ) : (
                                                                                    <div className="placeholderImage">
                                                                                        ?
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="btnWrap">
                                                        <button
                                                            type="button"
                                                            className="btn btnWhite btnPrimaryOutline"
                                                            onClick={handleCancel}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btnPrimary"
                                                            onClick={(e) => {
                                                                if (
                                                                    !selectedCases ||
                                                                    selectedCases?.length < 1
                                                                ) {
                                                                    toast.error("Please select atleast 1 case");
                                                                } else {
                                                                    handleSubmit(e);
                                                                }
                                                            }}
                                                        >
                                                            Assign Cases and Deactivate User{" "}
                                                            {submitLoader && (
                                                                <HalfCircleSpinner
                                                                    size={18}
                                                                    style={{
                                                                        display: "inline-block",
                                                                        marginLeft: "7px",
                                                                        position: "relative",
                                                                        top: "2px",
                                                                    }}
                                                                />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    {showSuccess && (
                        <div className="userForms casesTransfer userDeactiveSearch">
                            {/* <article className="widgetWrap"> */}
                            <div className="widgetBody">
                                <div className="textCenter txtWhite noteTxt txtText mb0">
                                    User has been deactivated and active cases transferred
                                    successfully
                                </div>
                            </div>
                            {/* </article> */}
                        </div>
                    )}
                </article>
            </div>
            {showTransferUserSearch === "individually" && !showSuccess && (
                <CasesList
                    allCasesOfUser={allCasesOfUser}
                    setSelectedCases={setSelectedCases}
                    selectedCases={selectedCases}
                />
            )}
        </>
    );
};

export default DeavtiveUserSearch;
