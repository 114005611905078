import React, { useState, useEffect } from "react";
import Pagination from "../../components/pagination";
import { useParams } from "react-router-dom";
import { caseLogLisiting, caseLogShow } from "../../api/services/cases/case-logs";
import Popup from "reactjs-popup";
import { HalfCircleSpinner } from "react-epic-spinners";

const CaseLogsShow = () => {
    let { id } = useParams();
    const [caseLogs, setCaseLogs] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [loading, setLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState('');

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        caseLogLisiting
            .getLogs({ case_id: id, page: pageNo })
            .then((res) => {
                setCaseLogs(res.data);
                setPagination(res.meta);
            })
            .catch((error) => console.error("Error fetching data:", error));
    };

    // Get_Users_Listing
    useEffect(() => {
        caseLogLisiting
            .getLogs({ case_id: id, page: 1 })
            .then((res) => {
                setLoading(false);
                setCaseLogs(res.data);
                setPagination(res.meta);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error fetching data:", error);
            });
    }, []);

    return (
        <>
            {/* Logs_Listing */}
            <div className="tableWidget">
                <article className="widgetWrap">
                    <div className="widgetHeader">
                        <span>Case Log</span>
                    </div>
                    <div className="tableWrap">
                        <table className="table fontSmall textCenter w100">
                            <thead>
                                <tr>
                                    <th>Updated at</th>
                                    <th>Updated by</th>
                                    <th align="left">Note</th>
                                </tr>
                            </thead>
                            <tbody>
                                {caseLogs && caseLogs?.length > 0 ? (
                                    caseLogs.map((item) => (
                                        <tr key={item.id}>
                                            <td data-label="Updated at" className="textCapitalize">
                                                {item.created_at + ' ' + item.created_time || "-"}
                                            </td>
                                            <td data-label="Updated by" className="textCapitalize">
                                                {item.changed_by_user || "-"}
                                            </td>
                                            <td data-label="Note" className="textCapitalize">
                                                {item.note || "-"}
                                            </td>
                                        </tr>
                                    ))
                                ) : loading ? (
                                    <tr>
                                        <td colSpan={9}><HalfCircleSpinner
                                            size={18}
                                            color='black'
                                            style={{
                                                display: "inline-block",
                                                marginLeft: "7px",
                                                position: "relative",
                                                top: "2px",
                                            }}
                                        /></td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan={9}>No record found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="widgetFooter">
                        <div className="textCenter">
                            {pagination && Object.keys(pagination)?.length > 0 && (
                                <Pagination
                                    pagination={pagination}
                                    handlePageClick={handlePageClick}
                                />
                            )}
                        </div>
                    </div>
                </article>
            </div>

            {/* Restore_Position_Popup */}
            <Popup
                className="caseLogImageModal deleteUserModal"
                open={imageUrl !== ""}
                onClose={() => setImageUrl('')}
            >
                <i onClick={() => setImageUrl('')} class="icn-close fa-regular fa-xmark fa-2xl"></i>
                <div className="modal textCenter">
                    <img className="w100 dBlock imgFluid" src={imageUrl} alt="image description" />
                </div>
            </Popup>
        </>
    );
};

export default CaseLogsShow;