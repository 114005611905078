import React from 'react'
import UpcommingCases from './upcommingCases'
import PublishedCases from './publishedCases'
import { useAuth } from '../../api/services/auth';
import { userPermissions } from '../../enums';
import './index.css'
import ConsultantStats from './consultantStats';

function ConsultantDashboard() {
    const { permission } = useAuth();

    return (
        <div className="dbContentWrap hodDasboard consultantDasboard">
            <div className='dbConRow'>
                <div className='dbConCol col100'>
                    <ConsultantStats />
                </div>
                {permission?.some(p => (p.name === userPermissions.CASES_LIST)) && (
                    <>
                        <div className='dbConCol col60'>
                            <div className="tableWidget">
                                <PublishedCases />
                            </div>
                        </div>
                        <div className='dbConCol col40'>
                            <div className="tableWidget">
                                <UpcommingCases />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default ConsultantDashboard