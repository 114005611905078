import React, { useState } from 'react'
import { userRestore } from '../../api/services/user-services/role';
import { toast } from 'react-toastify';
import userListing from '../../api/services/userListing';
import { HalfCircleSpinner } from 'react-epic-spinners';

function RestoreUser({ setShowSuccessModal, showSuccessModal, setShowRestoreUser, showRestoreUser, setUsersList, setPagination }) {

    const [submitLoader, setSubmitLoader] = useState(false)

    const handleShowUserRestoreModal = (e) => {
        e.preventDefault();
        setSubmitLoader(true)
        userRestore.get(showRestoreUser.id)
            .then((res) => {
                if (res.status === 200) {
                    setShowSuccessModal(true)
                    setSubmitLoader(false)
                    toast.success(res.message)
                    setShowRestoreUser(null)
                    userListing.getUsers(1)
                        .then((res) => {
                            setUsersList(res.data);
                            setPagination(res.meta);
                        }
                        ).catch(error => console.error('Error fetching data:', error));

                    setTimeout(() => {
                        setShowRestoreUser(null)
                        setShowSuccessModal(false)
                    }, 5000);
                }
            }
            ).catch(error => {
                setSubmitLoader(false)
                setShowRestoreUser(null)
                setShowSuccessModal(false)
                console.error('Error fetching data:', error)
            });
    }

    return (
        <div className="userForms userDeactiveSearch">
            <article className="widgetWrap">
                {(showRestoreUser && !showSuccessModal) && (
                    <div className="widgetBody">
                        <div className="textCenter txtWhite noteTxt txtText">You are Re-Activating this user</div>
                        <div className="tableWidget">
                            <div className="tableWrap">
                                <table className="table tableSecondary txtWhite fontSmall textCenter w100">
                                    <thead>
                                        <tr>
                                            <th>Username</th>
                                            <th>First name</th>
                                            <th>Surname</th>
                                            <th>Position</th>
                                            <th>Email</th>
                                            <th>Contact info</th>
                                            <th>Profile image</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-label="Username">{showRestoreUser.username}</td>
                                            <td data-label="First name">{showRestoreUser.first_name}</td>
                                            <td data-label="Surname">{showRestoreUser.surname}</td>
                                            <td data-label="Position" data-text-alignment="left">{showRestoreUser.position_name}</td>
                                            <td data-label="Email" data-text-alignment="left">{showRestoreUser.email}</td>
                                            <td data-label="Contact info" data-text-alignment="left">{showRestoreUser.phone_number}</td>
                                            <td data-label="Profile image">
                                                <div className="userImageWrap">
                                                    {showRestoreUser.image ?
                                                        <img
                                                            src={showRestoreUser.image}
                                                            className="imgFluid"
                                                            alt="image description"
                                                            onContextMenu={(e) => e.preventDefault()}
                                                        // onError={(e) => { 
                                                        //     handleImageError();
                                                        //     e.target.onerror = null; 
                                                        //     e.target.src =`${Placeholder}` 
                                                        // }}
                                                        />
                                                        :
                                                        <div className="placeholderImage">?</div>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="btnWrap">
                            <button
                                type="button"
                                className="btn btnWhite btnPrimaryOutline"
                                onClick={(e) => {
                                    setShowRestoreUser(null);
                                    setSubmitLoader(false)
                                }}
                            >Cancel</button>
                            <button
                                type="button"
                                className="btn btnPrimary"
                                onClick={(e) => { handleShowUserRestoreModal(e) }}
                            >Re-Activate User {submitLoader && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: 'relative',
                                        top: '2px',
                                    }}
                                />
                            )}</button>
                        </div>
                    </div>
                )}

                {(showSuccessModal && !showRestoreUser) && <div className="userForms casesTransfer userDeactiveSearch">
                    <article className="widgetWrap">
                        <div className="widgetBody">
                            <div className="textCenter txtWhite noteTxt txtText mb0">User has been Re-Activated successfully</div>
                        </div>
                    </article>
                </div>}
            </article>
        </div>
    )
}

export default RestoreUser