import React, { useEffect } from 'react'

function SingleImageUpdate({ toggleStates, setToggleStates, setImagesToUpload, index, image, setSelectedImage, setDirectUpload, selectedImage, setEditedImageIndex, removeImageName, setRemoveImagename, }) {
    const toggleId = `toggle-${index}`;
    const isChecked = toggleStates[index] || false;

    //set default toggle state
    useEffect(() => {
        if (typeof toggleStates[index] === 'undefined') {
            const updatedToggleStates = [...toggleStates];
            updatedToggleStates[index] = false;
            setToggleStates(updatedToggleStates);
        }
    }, []);

    //handle image type of toggle buttons
    const handleToggleChange = () => {
        const updatedToggleStates = [...toggleStates];
        updatedToggleStates[index] = !updatedToggleStates[index];
        setToggleStates(updatedToggleStates);
    };

    //remove image and its toggle state
    const handleRemoveImage = (index) => {
        const updatedToggleStates = [...toggleStates];
        updatedToggleStates.splice(index, 1);
        setToggleStates(updatedToggleStates);
        const updatedSelectedImage = [...selectedImage];
        updatedSelectedImage.splice(index, 1);
        setSelectedImage(updatedSelectedImage);
        setImagesToUpload(updatedSelectedImage);
        if (image.oldImage) {
            setRemoveImagename([...removeImageName, image.name])
        }
    }

    return (
        <li>
            <div>
                <div className="imgWrap">
                    {image.oldImage ?
                        <img src={image?.imageUrl} className="imgFluid" alt="image description" /> :
                        <img src={(image?.src || URL.createObjectURL(image) || image)} className="imgFluid" alt="image description" />
                    }

                </div>
                <div className="actionsWrap">
                    {!image.oldImage && <button type="button" className="btn btnPrimary" onClick={() => {
                        setDirectUpload(false)
                        setEditedImageIndex(index)
                    }}>Edit</button>}
                    <button type="button" className="btn btnDanger" onClick={() => handleRemoveImage(index)}>Remove</button>
                </div>
                <div className="imageTypeToggle">
                    <input type="checkbox" className="toggle" id={toggleId} checked={isChecked} onChange={handleToggleChange} />
                    <label htmlFor={toggleId}>
                        <span className="on">Post Op</span>
                        <span className="off">Pre-Op</span>
                    </label>
                </div>
            </div>
        </li>
    )
}

export default SingleImageUpdate