import React, { useState } from 'react'
import { useInput } from '../../hooks/useInput';
import { toast } from 'react-toastify';
import { requestSuppourt, requestsSupportListing } from '../../api/services/support-request';
import { HalfCircleSpinner } from 'react-epic-spinners';

function AddRequest(props) {
    const [headingText, bindHeadingText, resetHeadingText] = useInput("");
    const [contentText, bindContentText, resetContentText] = useInput("");
    const [showLoader, setShowLoader] = useState(false);
    const [errors, setErrors] = useState({});

    const checkValidation = () => {
        let errors = {};
        let isValid = true;

        if (!contentText) {
            isValid = false;
            errors['contentText'] = 'Please enter your Username.';
        }

        if (!headingText) {
            isValid = false;
            errors['headingText'] = 'Please enter your Username.';
        }
        setErrors(errors);
        return isValid;
    };

    // Handle_Create
    const handleCreateRequest = (event) => {
        event.preventDefault();
        if (checkValidation()) {
            setShowLoader(true)
            const requestBody = {
                heading: headingText,
                content: contentText,
            };

            requestSuppourt
                .request(requestBody)
                .then((res) => {
                    if (res.status === 200) {
                        setShowLoader(false);
                        toast.success(res.message);
                        props.setShowCreateUser(false);
                        requestsSupportListing.get(1)
                            .then((res) => {
                                props.setRequestsList(res.data);
                                props.setPagination(res.meta);
                            }
                            ).catch(error => {
                                console.error('Error fetching data:', error)
                            });
                    }
                })
                .catch((error) => {
                    setShowLoader(false);
                    toast.error(error.message);
                });
        }
    };


    return (
        <div>   <form className="sendNotificationForm" onSubmit={(e) => handleCreateRequest(e)}>
            <article className="widgetWrap notifWidget sendNotiBox">
                <div className="widgetHeader">
                    <span>Create Support Request</span>
                </div>
                <div className="widgetBody requestSupport">
                    <div className="formRow">
                        <label className="txtLabel">Title</label>
                        <div className="inputWrap">
                            <input
                                type="text"
                                className={`formControl ${errors.headingText && "error"}`}
                                {...bindHeadingText}
                            />
                        </div>
                    </div>
                    <div className="formRow">
                        <label className="txtLabel">Support Request Content</label>
                        <div className="notifBody">
                            <textarea
                                className={`formControl ${errors.contentText && "error"}`}
                                {...bindContentText}
                            ></textarea>
                        </div>
                    </div>
                    <div className="notifActions">
                        <div className="btnWrap">
                            <button
                                type="button"
                                className="btn btnWhite"
                                disabled={showLoader}
                                onClick={() => {
                                    props.setShowCreateUser(false);
                                }}
                            >Cancel</button>
                            <button
                                type="submit"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Create Support Request  {showLoader && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: 'relative',
                                        top: '2px',
                                    }}
                                />
                            )}
                            </button>
                        </div>
                    </div>
                </div>
            </article>
        </form></div>
    )
}

export default AddRequest