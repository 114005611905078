import React, { useState, useEffect } from "react";
import Pagination from "../../components/pagination";
import { useAuth } from "../../api/services/auth";
import { Link, Navigate } from "react-router-dom";
import { caseLogLisiting } from "../../api/services/cases/case-logs";
import Popup from "reactjs-popup";
import CaseLogsSearchFilter from "../../components/case-logs/caseLogSeacrhFilter";
import { HalfCircleSpinner } from "react-epic-spinners";

const CaseLogs = () => {
    const { userExist } = useAuth();
    const [caseLogsList, setCaseLogsList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [loading, setLoading] = useState(true);
    const [imageUrl, setImageUrl] = useState("");
    const [showFilter, setShowFilter] = useState(false);

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Case Logs | Cappagh";
    }, []);

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        caseLogLisiting
            .getLogs({ page: pageNo })
            .then((res) => {
                setCaseLogsList(res.data);
                setPagination(res.meta);
            })
            .catch((error) => console.error("Error fetching data:", error));
    };

    // Get_Users_Listing
    useEffect(() => {
        caseLogLisiting
            .getLogs({ page: 1 })
            .then((res) => {
                setLoading(false);
                setCaseLogsList(res.data);
                setPagination(res.meta);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error fetching data:", error);
            });
    }, []);

    if (!userExist) {
        return <Navigate to="/login" />;
    }

    return (
        <div className="dbContentWrap">
            {/* Seacrch Filter */}
            {showFilter && (
                <CaseLogsSearchFilter
                    setShowFilter={setShowFilter}
                    setCaseLogsList={setCaseLogsList}
                    setPagination={setPagination}
                />
            )}

            {/* Logs_Listing */}
            <div className="tableWidget">
                <article className="widgetWrap">
                    <div className="widgetHeader">
                        <span>Case Log</span>
                        {/* <div className="tabHeadActionWrap">
                            <button
                                onClick={() => {
                                    setShowFilter(true);
                                }}
                                type="button"
                                className={`actionBtn ${showFilter && "active"}`}
                            >
                                Search Filter <i className="fa-sharp fa-solid fa-filters"></i>
                            </button>
                        </div> */}
                    </div>
                    <div className="tableWrap">
                        <table className="table fontSmall textCenter w100">
                            <thead>
                                <tr>
                                    <th>Case Ref</th>
                                    <th>Updated at</th>
                                    <th>Updated by</th>
                                    <th align="left">Note</th>
                                </tr>
                            </thead>
                            <tbody>
                                {caseLogsList && caseLogsList?.length > 0 ? (
                                    caseLogsList.map((item) => (
                                        <tr key={item.id}>
                                            <td data-label="Case Ref" className="textCapitalize">
                                                <Link to={`../case/${item.case_id}`}>
                                                    {item.case_reference || "-"}
                                                </Link>
                                            </td>
                                            <td data-label="Updated at" className="textCapitalize">
                                                {item.created_at + ' ' + item.created_time || "-"}
                                            </td>
                                            <td data-label="Updated by" className="textCapitalize">
                                                {item.changed_by_user || "-"}
                                            </td>
                                            <td data-label="Note" className="textCapitalize">
                                                {item.note || "-"}
                                            </td>
                                        </tr>
                                    ))
                                ) : loading ? (
                                    <tr>
                                        <td colSpan={9}><HalfCircleSpinner
                                            size={18}
                                            color='black'
                                            style={{
                                                display: "inline-block",
                                                marginLeft: "7px",
                                                position: "relative",
                                                top: "2px",
                                            }}
                                        /></td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan={8}>No record found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="widgetFooter">
                        <div className="textCenter">
                            {pagination && Object.keys(pagination)?.length > 0 && (
                                <Pagination
                                    pagination={pagination}
                                    handlePageClick={handlePageClick}
                                />
                            )}
                        </div>
                    </div>
                </article>
            </div>

            {/* Restore_Position_Popup */}
            <Popup
                className="caseLogImageModal deleteUserModal"
                open={imageUrl !== ""}
                onClose={() => setImageUrl("")}
            >
                <i
                    onClick={() => setImageUrl("")}
                    class="icn-close fa-regular fa-xmark fa-2xl"
                ></i>
                <div className="modal textCenter">
                    <img
                        className="w100 dBlock imgFluid"
                        src={imageUrl}
                        alt="image description"
                    />
                </div>
            </Popup>
        </div>
    );
};

export default CaseLogs;
