import { API_CASE_DELETE, API_CONSULTANT_CASE_DELETE, API_DISCUSS_CASES_LIST, API_FLAGGED_CASE_CLOSE_LISTING, API_FLAGGED_CASE_OPEN_LISTING, API_PENDING_REVIEW_BY_ME_LIST } from "../../config";
import httpClient from "../../httpClient";


export const discussCases = {
    get: (pageNo, params) => {
        params = params && typeof params === 'object' ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== '' && value !== null)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const endpoint = API_DISCUSS_CASES_LIST + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

        return httpClient.get(endpoint);
    },
};

export const pendingReviewByME = {
    get: (pageNo, params) => {
        params = params && typeof params === 'object' ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== '' && value !== null)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const endpoint = API_PENDING_REVIEW_BY_ME_LIST + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

        return httpClient.get(endpoint);
    },
};

export const openFlagCaseList = {
    getCases: (pageNo, params) => {
        params = params && typeof params === 'object' ? params : {};
        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== '' && value !== null)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const endpoint = API_FLAGGED_CASE_OPEN_LISTING + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

        return httpClient.get(endpoint);
    },
};

export const closeFlagCaseList = {
    getCases: (pageNo, params) => {
        params = params && typeof params === 'object' ? params : {};
        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== '' && value !== null)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const endpoint = API_FLAGGED_CASE_CLOSE_LISTING + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

        return httpClient.get(endpoint);
    },
};

export const deleteCase = {
    delete: (id) => httpClient.delete(API_CASE_DELETE + '/' + id),
};
export const deleteConsultantCase = {
    delete: (id) => httpClient.delete(API_CONSULTANT_CASE_DELETE + '/' + id),
};