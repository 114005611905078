import httpClient from "../../httpClient";
import { API_POSITION_ALL_LISTING, API_POSITION_CREATE, API_POSITION_DELETE, API_POSITION_LISTING, API_POSITION_UPDATE, API_POSTION_FILTER } from "../../config";

export const positionListingAll = {
  get: () => httpClient.get(API_POSITION_ALL_LISTING),
};

export const postionFilters = {
  get: () => httpClient.get(API_POSTION_FILTER),
};

export const positionDelete = {
  delete: (id) => httpClient.delete(API_POSITION_DELETE + '/' + id)
};

export const positionListing = {
  getPosition: (pageNo, params) => {
    params = params && typeof params === 'object' ? params : {};

    const queryString = Object.entries(params)
      .filter(([key, value]) => value !== '' && value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const endpoint = API_POSITION_LISTING + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

    return httpClient.get(endpoint);
  },
};

export const positionUpdate = {
  updateInfo: (id, data) => httpClient.post(API_POSITION_UPDATE + '/' + id, data),
};

export const positionCreate = {
  create: (data) => httpClient.post(API_POSITION_CREATE, data),
};