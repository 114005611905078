import React, { useState } from "react";
import { useAuth } from "../../api/services/auth";
import { userPermissions } from "../../enums";
import PostComment from "./postComment";
import { deleteComment } from '../../api/services/cases/comments/index';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from 'react-epic-spinners';

function PostOpsComments({
    loginInUser,
    showEditModal,
    editComment,
    setEditComment,
    seUpcommingCase,
    upcommingCase,
    setCommentData,
    setIsLoading,
    openEditModal,
    commentData,
    closeEditModal,
    handleEditComment,
    handleShowUpcomming,
    caseType,
    caseData
}) {
    const { permission } = useAuth();
    const [showLoader, setShowLoader] = useState(false);
    const [selectedCommentId, setSelectedCommentId] = useState(null);

    // Handle_Delete_Click
    const handleCommentDeleteClick = (commentId) => {
        setSelectedCommentId(commentId);
    };

    const handleDeleteComment = (id) => {
        deleteComment.delete(id).then((res) => {
            setShowLoader(true);

            if (res.status === 200) {
                toast.success(res.message);
                setShowLoader(false);
                setSelectedCommentId(null)
            }
        })
    }

    return (
        <>
            <article className="widgetWrap auditReviewWidget">
                {permission?.some(p => (p.name === userPermissions.COMMENTS_CASE_COMMENTS)) && (
                    <>
                        <div className="widgetHeader">
                            <span>Pre Op Comments</span>
                            {(upcommingCase && (upcommingCase?.length > 0)) && (
                                <span className="preOpComOpener" onClick={handleShowUpcomming}>
                                    <i
                                        className={`fa-sharp fa-solid ${caseType ? "fa-minus" : "fa-plus"
                                            }`}
                                    ></i>
                                </span>
                            )}
                        </div>
                        {(upcommingCase && caseType && upcommingCase.length > 0) ? (
                            <ul className="listUnstyled reviewslList listStriped overflowHidden roundedBottom">
                                {upcommingCase?.map((item, index) => {
                                    const dateObjectForTime = new Date(item.comment_time);
                                    const dateObjectForDate = new Date(item.comment_date);
                                    const hours = dateObjectForTime.getHours();
                                    const minutes = dateObjectForTime.getMinutes();
                                    const year = dateObjectForDate.getFullYear();
                                    const month = String(dateObjectForDate.getMonth() + 1).padStart(2, '0');
                                    const day = String(dateObjectForDate.getDate()).padStart(2, '0');
                                    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
                                    const formattedDate = `${year}/${month}/${day}`;
                                    return (
                                        <li key={index}>
                                            <div className="singleReview">
                                                <div className="reviewHeader">
                                                    <div className="reviewTitle">{item.user}</div>
                                                    <div className="reviewTime">
                                                        <span className="date">{item.comment_date}</span>
                                                        <span className="time">{item.comment_time}</span>
                                                    </div>
                                                </div>
                                                <div className="reviewBody">
                                                    <p>{item.comment}</p>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        ) : (
                            <ul className="listUnstyled reviewslList preOpCommentCount listStriped roundedBottom overflowHidden">
                                <li>
                                    <div className="singleReview">
                                        <div className="textCenter">
                                            {upcommingCase && upcommingCase?.length > 0
                                                ? `${upcommingCase?.length} Comments`
                                                : "No Comments"}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        )
                        }
                    </>
                )}
            </article>
            <article className="widgetWrap auditReviewWidget mb0">
                {permission?.some(p => (p.name === userPermissions.COMMENTS_CASE_COMMENTS)) && (
                    <>
                        <div className="widgetHeader">
                            <span>Audit & PeerReview</span>
                        </div>
                        {(commentData && (commentData.length > 0)) ?
                            (<ul className="listUnstyled reviewslList listStriped overflowHidden">
                                {commentData?.map((item, index) => {
                                    const dateObjectForTime = new Date(item?.comment_time);
                                    const dateObjectForDate = new Date(item?.comment_date);
                                    const hours = dateObjectForTime.getHours();
                                    const minutes = dateObjectForTime.getMinutes();
                                    const year = dateObjectForDate.getFullYear();
                                    const month = String(dateObjectForDate.getMonth() + 1).padStart(2, '0');
                                    const day = String(dateObjectForDate.getDate()).padStart(2, '0');
                                    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
                                    const formattedDate = `${year}/${month}/${day}`;
                                    return (
                                        <li key={index}>
                                            <div className="singleReview">
                                                <div className="reviewHeader">
                                                    <div className="reviewTitle">{item.user}</div>
                                                    <div className="reviewTime">
                                                        <span className="date">{item.comment_date || ''}</span>
                                                        <span className="time">{item.comment_time || ''}</span>
                                                    </div>
                                                </div>
                                                <div className="reviewBody">
                                                    <p>{item.comment}</p>
                                                    {(loginInUser.id === item.user_id) &&
                                                        <div className="editBtnWrap caseCommentBtnWrap txtRight">
                                                            {(permission?.some(p => (p.name === 'comments-update')) && !caseData.deleted_at) && (
                                                                <button
                                                                    type='button'
                                                                    className="btn btnPrimary editComentBtn caseCommentBtn"
                                                                    onClick={() => openEditModal(item.id)}
                                                                >Edit My Comment</button>
                                                            )}
                                                            {(permission?.some((p) => p.name === "comments-delete") && !caseData.deleted_at) ? (
                                                                <button
                                                                    onClick={() => {
                                                                        handleCommentDeleteClick(item.id);
                                                                    }}
                                                                    className="btn btnDanger caseCommentBtn"
                                                                >Delete Comment</button>
                                                            ) : null}
                                                            {(showEditModal[item.id] && !caseData.deleted_at) && (
                                                                <div className="reviewFooter">
                                                                    <div className="formWrap">
                                                                        <form action="" className="reviewCommentForm cmListComentForm">
                                                                            <div className="formRow">
                                                                                <textarea
                                                                                    className="formControl dBlock w100"
                                                                                    placeholder="Add comment..."
                                                                                    value={editComment ? editComment.comment : ''}
                                                                                    onChange={(e) => setEditComment({ ...editComment, comment: e.target.value })}
                                                                                />
                                                                            </div>
                                                                            <div className="formBtnRow fontSmall btnWrap">
                                                                                <button type="button" className="btn btnSecondary" onClick={closeEditModal}>Cancel</button>
                                                                                <button type="button" className="btn btnPrimary" onClick={() => {
                                                                                    handleEditComment(item.id)
                                                                                }}>Save Comment</button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>)
                            : <div style={{ padding: '20px 25px 20px', textAlign: 'center' }}>
                                No Comments Found
                            </div>
                        }
                    </>
                )}
                {(permission?.some(p => (p.name === userPermissions.COMMENTS_STORE)) && !caseData.deleted_at) && (
                    <ul className="listUnstyled reviewslList postCommentBox listStriped roundedBottom overflowHidden">
                        <PostComment
                            setCommentData={setCommentData}
                            setIsLoading={setIsLoading}
                            seUpcommingCase={seUpcommingCase}
                        />
                    </ul>
                )}
                <Popup
                    className="deleteUserModal"
                    open={selectedCommentId !== null}
                    onClose={() => {
                        setSelectedCommentId(null)
                    }}
                >
                    <div className="modal textCenter">
                        <p>Do You want delete this comment?</p>
                        <div className="btnWrap">
                            <button
                                onClick={(e) => {
                                    setSelectedCommentId(null)
                                }}
                                type="button"
                                className="btn btnSecondary"
                                disabled={showLoader}
                            >No</button>
                            <button
                                onClick={() => {
                                    handleDeleteComment(selectedCommentId)
                                }}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Yes
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                </Popup>
            </article>
        </>
    );
}

export default PostOpsComments;
