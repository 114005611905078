import React, { useEffect, useState } from "react";
import SingleImageUpdate from "./SingleImageUpdate";
import EditImageUpdateCase from "./EditImageUpdateCase";

function ImageUpdate({
    editedImageIndex,
    setEditedImageIndex,
    removeImageName,
    setRemoveImagename,
    imagesToUpload,
    setImagesToUpload,
    toggleStates,
    setToggleStates,
}) {
    const [showUploadButton, setShowUploadButton] = useState(false);
    const [selectedImage, setSelectedImage] = useState([...imagesToUpload]);
    const [directUpload, setDirectUpload] = useState(false);

    useEffect(() => {
        setSelectedImage([...imagesToUpload]);
    }, [imagesToUpload]);

    // Handle_File_Upload
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileSizeLimit = 5 * 1024 * 1024; // 5 MB
            const allowedFileTypes = ["image/jpeg", "image/png", "image/svg+xml"];

            if (file.size > fileSizeLimit) {
                alert("File size exceeds the limit of 5MB");
                return;
            }

            if (!allowedFileTypes.includes(file.type)) {
                alert("Invalid file type. Please select a JPG, PNG, or SVG file.");
                return;
            }

            const reader = new FileReader();

            reader.onload = () => {
                const img = new window.Image();
                img.src = reader.result;
                img.imageType = false
                setSelectedImage([...selectedImage, img]);
                setImagesToUpload([...selectedImage, img]);
                setEditedImageIndex(selectedImage.length);
                setDirectUpload(true);
            };
            reader.readAsDataURL(file);
            e.target.value = null;
        }
    };

    //cancel button functionality
    const handleShowUploadButton = (status) => {
        if (status === "del") {
            const updatedSelectedImage = [...selectedImage];
            var lastIndex = updatedSelectedImage.length - 1;
            updatedSelectedImage.splice(lastIndex);
            setSelectedImage(updatedSelectedImage);
            setImagesToUpload(updatedSelectedImage);
            setEditedImageIndex(null);
        } else {
            setShowUploadButton(!showUploadButton);
            setEditedImageIndex(null);
        }
    };

    return (
        <div>
            <article className="widgetWrap uploadCaseWidget">
                <div className="widgetHeader">
                    <span>
                        {selectedImage?.length < 1
                            ? "Upload Images"
                            : "Upload another Image"}
                    </span>
                </div>
                <div className="widgetBody">
                    {editedImageIndex == null &&
                        selectedImage &&
                        selectedImage.length > 0 && (
                            <ul className="listUnstyled uploadList">
                                {selectedImage.map((image, index) => (
                                    <SingleImageUpdate
                                        setEditedImageIndex={setEditedImageIndex}
                                        key={index}
                                        index={index}
                                        image={image}
                                        selectedImage={selectedImage}
                                        setSelectedImage={setSelectedImage}
                                        setRemoveImagename={setRemoveImagename}
                                        removeImageName={removeImageName}
                                        directUpload={directUpload}
                                        setDirectUpload={setDirectUpload}
                                        setImagesToUpload={setImagesToUpload}
                                        toggleStates={toggleStates}
                                        setToggleStates={setToggleStates}
                                    />
                                ))}
                            </ul>
                        )}
                    {editedImageIndex !== null && (
                        <EditImageUpdateCase
                            directUpload={directUpload}
                            setDirectUpload={setDirectUpload}
                            editedImageIndex={editedImageIndex}
                            setSelectedImage={setSelectedImage}
                            setImagesToUpload={setImagesToUpload}
                            selectedImage={selectedImage}
                            setEditedImageIndex={setEditedImageIndex}
                            image={selectedImage?.find((item, index) => {
                                return index === editedImageIndex;
                            })}
                        />
                    )}
                    {showUploadButton && editedImageIndex === null && (
                        <div className="uploadBtnWrap">
                            <input
                                onChange={handleFileChange}
                                id="uploadImage"
                                type="file"
                                className="srOnly"
                            />
                            <label htmlFor="uploadImage" className="label btn btnPrimary">
                                Upload Image <i className="icn fa-solid fa-x-ray"></i>
                            </label>
                        </div>
                    )}
                </div>
                <div className="widgetFooter">
                    <div className="textCenter">
                        <div
                            className="cursorPointer"
                            onClick={() =>
                                handleShowUploadButton(
                                    editedImageIndex !== null && directUpload ? "del" : "update"
                                )
                            }
                        >
                            {showUploadButton && editedImageIndex !== null && directUpload
                                ? "Remove Image"
                                : showUploadButton && editedImageIndex !== null && !directUpload
                                    ? "Cancel Changes"
                                    : !showUploadButton && selectedImage?.length > 1
                                        ? "Upload another Image"
                                        : !showUploadButton
                                            ? "Upload Images"
                                            : "Cancel upload"}
                        </div>
                    </div>
                </div>
            </article>
        </div>
    );
}

export default ImageUpdate;
