import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Image, Rect, Text, Group, Circle } from 'react-konva';
import Popup from 'reactjs-popup';
import './index.css'

function EditImageUpdateCase({ setDirectUpload, selectedImage, directUpload, image, setImagesToUpload, editedImageIndex, setSelectedImage, setEditedImageIndex }) {
    const [rectangles, setRectangles] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [startPos, setStartPos] = useState({ x: 0, y: 0 });
    const [endPos, setEndPos] = useState({ x: 0, y: 0 });
    const [hoveredRectIndex, setHoveredRectIndex] = useState(null);
    const [showSaveModal, setShowSaveModal] = useState(false)
    const imageRef = useRef(null);
    const stageRef = useRef(null);

    useEffect(() => {
        if (image) {
            imageRef.current.image(image);
        }
    }, [image]);

    const handleMouseDown = (e) => {
        const stage = stageRef.current;
        const clickedNode = e.target;
        // Check if the click was on the cross icon
        if (clickedNode && clickedNode.id() === 'xIcon') return;

        setIsDrawing(true);
        const { x, y } = stage.getPointerPosition();
        setStartPos({ x, y });
        setEndPos({ x, y });
    };


    const handleMouseMove = () => {
        if (!isDrawing) return;
        const stage = stageRef.current;
        const { x, y } = stage.getPointerPosition();
        setEndPos({ x, y });

    };

    const handleMouseUp = (e) => {
        e.cancelBubble = true;
        if (!isDrawing) return;
        setIsDrawing(false);
        const { width, height } = endPos.x - startPos.x !== 0 && endPos.y - startPos.y !== 0 ? {
            width: endPos.x - startPos.x,
            height: endPos.y - startPos.y
        } : { width: 0, height: 0 };
        // Check if width and height are not zero
        if (width !== 0 && height !== 0) {
            setRectangles(prevRectangles => {
                if (!Array.isArray(prevRectangles)) {
                    prevRectangles = [];
                }
                const finalRectangles = [...prevRectangles];
                finalRectangles.push({ x: startPos.x, y: startPos.y, width, height, showCrossIcon: true });
                return finalRectangles;
            });
        }
        setStartPos({ x: 0, y: 0 });
        setEndPos({ x: 0, y: 0 });
    };

    const handleConfirmation = () => {
        setShowSaveModal(false);
        handleFinalSubmit();
    }
    const handleShowModal = () => {
        // if (rectangles.length > 0) {
        setShowSaveModal(true);
        // } else {
        //     setEditedImageIndex(null);
        // }
    }

    const handleFinalSubmit = () => {
        if (image) {
            setDirectUpload(false)
            const canvas = document.createElement('canvas');
            canvas.width = 600;
            canvas.height = 600;
            const context = canvas.getContext('2d');

            context.drawImage(imageRef.current.image(), 0, 0, 600, 600);

            rectangles.forEach(rect => {
                context.fillStyle = "#353535";
                context.globalAlpha = 1;
                context.fillRect(rect.x, rect.y, rect.width, rect.height);
            });

            const stageDataUrl = canvas.toDataURL();

            // Create a new image with the updated stage
            const updatedImage = new window.Image();
            updatedImage.onload = () => {
                setSelectedImage(prevSelectedImage =>
                    prevSelectedImage.map((img, index) =>
                        index === editedImageIndex ? updatedImage : img
                    )
                );
                setImagesToUpload(prevSelectedImage =>
                    prevSelectedImage.map((img, index) =>
                        index === editedImageIndex ? updatedImage : img
                    )
                );
                setEditedImageIndex(null);
            };
            updatedImage.src = stageDataUrl;
        }
    };


    const removeRectangle = (indexToRemove) => {
        setHoveredRectIndex(null);
        setRectangles(prevRectangles =>
            prevRectangles.filter((_, index) => index !== indexToRemove)
        );
    };

    const removeLastObject = () => {
        setRectangles(prevData => {
            const newData = prevData.slice(0, -1);
            return newData;
        });
    };

    const handleMouseOver = (index) => {
        setHoveredRectIndex(index);
        document.body.style.cursor = 'pointer';
    };

    const handleMouseOut = () => {
        setHoveredRectIndex(null);
        document.body.style.cursor = 'default';
    };

    return (
        <div className='imageEditSec'>
            <div className="navItem">
                <a >
                    <i className="icn fa-solid fa-triangle-exclamation"></i>
                    <span className="toolTip">Select and drag in order to hide PPI</span>
                </a>
            </div>
            <Stage
                width={600}
                height={600}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                ref={stageRef}
            >
                <Layer>
                    {image && (
                        <Image
                            width={600}
                            height={600}
                            ref={imageRef}
                            image={image}
                        />
                    )}
                    {/* Render finalized rectangles */}
                    {rectangles.map((rect, index) => (
                        <Group key={index}>
                            <Rect
                                x={rect.x}
                                y={rect.y}
                                width={rect.width}
                                height={rect.height}
                                fill="#353535"
                                opacity={1}
                            />
                            {rect.showCrossIcon && (
                                <Group
                                    x={rect.x + (rect.width >= 0 ? rect.width - 3 : 0)} // Adjust position for the top right corner
                                    y={rect.y + (rect.height >= 0 ? 3 : rect.height + 3)} // Adjust position for the top margin
                                    onClick={(e) => {
                                        e.cancelBubble = true;
                                        e.evt.preventDefault();
                                        removeRectangle(index);
                                    }}
                                    onMouseOver={() => handleMouseOver(index)}
                                    onMouseOut={handleMouseOut}
                                >
                                    <Circle
                                        radius={hoveredRectIndex === index ? 12 : 10}
                                        fill="red"
                                        opacity={0.7}
                                    />
                                    <Text
                                        text="X"
                                        fill="white"
                                        opacity={0.7}
                                        fontSize={hoveredRectIndex === index ? 15 : 12}
                                        align="center"
                                        verticalAlign="middle"
                                        offsetX={4}
                                        offsetY={5}
                                    />
                                </Group>
                            )}
                        </Group>
                    ))}
                    {/* Render current rectangle being drawn */}
                    {isDrawing && (
                        <Rect
                            x={Math.min(startPos.x, endPos.x)}
                            y={Math.min(startPos.y, endPos.y)}
                            width={Math.abs(endPos.x - startPos.x)}
                            height={Math.abs(endPos.y - startPos.y)}
                            fill="#353535"
                            opacity={1}
                        />
                    )}
                </Layer>
            </Stage>
            <div className='editButtonActions'>
                <button type="button" className="btn btnPrimary label" onClick={() => { handleShowModal() }}>Save Image To Case <i className="icn fa-solid fa-x-ray"></i></button>
                {/* {rectangles.length > 0 && <button type="button" className="btn btnPrimary" onClick={removeLastObject}>UNDO</button>} */}
            </div>
            <Popup
                className="saveEditedImage"
                open={showSaveModal}
                onClose={() => {
                    setShowSaveModal(false)
                    // setDirectUpload(false)
                    // setEditedImageIndex(null);
                }}
            >
                {showSaveModal && (
                    <div className="modal textCenter">
                        <p>Has all Personally Identifiable Information been removed from upload</p>
                        <div className="btnWrap">
                            <button
                                onClick={handleConfirmation}
                                type="button"
                                className="btn btnSecondary"
                            >YES
                            </button>
                            <button
                                onClick={() => {
                                    if (directUpload) {
                                        setShowSaveModal(false)
                                        const updatedSelectedImage = [...selectedImage];
                                        var lastIndex = updatedSelectedImage.length - 1;
                                        updatedSelectedImage.splice(lastIndex);
                                        setSelectedImage(updatedSelectedImage);
                                        setImagesToUpload(updatedSelectedImage);
                                        setEditedImageIndex(null)
                                        setDirectUpload(false)
                                    } else {
                                        setShowSaveModal(false)
                                        setDirectUpload(false)
                                        setEditedImageIndex(null);
                                    }
                                }}
                                type="button"
                                className="btn btnDanger"
                            >NO</button>
                        </div>
                    </div>
                )}
            </Popup>
        </div>
    );
}

export default EditImageUpdateCase;
