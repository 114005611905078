import React, { useEffect } from 'react'
import { useAuth } from '../../api/services/auth';
import { userPermissions } from '../../enums';
import Echo from "laravel-echo";
import { useNotification } from '../../context/NotificationsContext';
import { useNavigate } from 'react-router-dom';
window.Pusher = require("pusher-js");

function FlagCasesRow({ setCasesList, index, data, status, handleBookmarkCaseClick }) {
    const { permission, user } = useAuth();
    const { options } = useNotification();
    const navigate = useNavigate()

    useEffect(() => {
        const echo = new Echo(options);
        echo.private(`${data.reference}.flag.${user.id}`)
            .listen("FlaggedCaseComment", (data) => {
                setCasesList((prevCasesList) =>
                    prevCasesList.map((item) => {
                        if (data.case_id === item.id) {
                            return { ...item, unread_count: 1, total_comments: (item.total_comments + 1) };
                        } else {
                            return item;
                        }
                    })
                );
            })
    }, []);
    return (
        <tr
            key={index}
            className={`${data.bookmark == 1 ? "bookmarked" : ""
                }`}
        >
            <td className="actionIcn" onClick={() => { navigate(`../flagged-case/${data.flagged_case_id}`) }} data-label="Ref">{data?.reference}</td>
            <td data-label="Date">{data?.procedure_date || '-'}</td>
            <td data-label="Flagged By">{data?.flagged_by}</td>
            <td data-label="Consultant">{data?.consultant}</td>
            <td data-label="Procedure">{data?.procedure}</td>
            <td
                data-label="Comorbidities"
                data-text-alignment="left"
            >
                {data.comorbidities
                    ? Object.values(data?.comorbidities).join(", ")
                    : "-"}
            </td>
            <td
                data-label="Complications"
                data-text-alignment="left"
            >
                {data.complications
                    ? Object.values(data?.complications).join(", ")
                    : "-"}
            </td>
            <td data-label="Status">{data?.status}</td>
            <td data-label="Comments">
                {data?.total_comments}
                {data?.unread_count > 0 && (
                    <span className="badgeNew">
                        New<i className="icn fa-solid fa-message"></i>
                    </span>
                )}
            </td>
            <td data-label="Actions">
                <div>
                    <button
                        type="button"
                        onClick={() => { navigate(`../flagged-case/${data.flagged_case_id}`) }}
                        className="actionIcn edit fa-solid fa-eye"
                    ></button>
                    {permission?.some(
                        (p) =>
                            p.name ===
                            userPermissions.FLAGGED_CASES_BOOKMARK
                    ) && (
                            <button
                                type="button"
                                onClick={() =>
                                    handleBookmarkCaseClick(
                                        data?.flagged_case_id,
                                        data?.bookmark,
                                        status
                                    )
                                }
                                className={`actionIcn bookmarkIcn ${data.bookmark === 0
                                    ? "fa-light fa-star"
                                    : "fa-solid fa-star"
                                    }`}
                            ></button>
                        )}
                </div>
            </td>
        </tr>
    )
}

export default FlagCasesRow