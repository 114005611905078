import React, { useEffect, useState } from 'react'
import { useAuth } from '../../api/services/auth'
import { Navigate } from 'react-router-dom';
import { userProfile } from '../../api/services/user-services/role';
import { HalfCircleSpinner } from 'react-epic-spinners';

function UpdateProfile() {
    const { userExist, user } = useAuth()
    const [lodaing, setLoading] = useState(true)
    const [username, setUsername] = useState('');
    const [firstname, setFirstname] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        if (user) {
            userProfile.get(user.id)
                .then((res) => {
                    setLoading(false)
                    if (res.data) {
                        let userData = res.data[0];
                        setUsername(userData.username)
                        setFirstname(userData.first_name)
                        setSurname(userData.surname)
                        setEmail(userData.email)
                        setNumber(userData.phone_number)
                        setSelectedImage(userData.image)
                    }
                }
                ).catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                });
        }
    }, []);

    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <div className='dbContentWrap'>
            {lodaing ? <HalfCircleSpinner
                size={18}
                color='black'
                style={{
                    display: "inline-block",
                    marginLeft: "7px",
                    position: "relative",
                    top: "2px",
                }}
            /> : (
                <form className="userForms updateUserForm">
                    <article className="widgetWrap">
                        <div className="widgetBody">
                            <div className="dbRow">
                                <div className="dbCol dbColLg75">
                                    <div className="dbRow">
                                        <div className="dbCol dbColLg50">
                                            <div className="formRow">
                                                <label className="txtLabel">Username</label>
                                                <div className="inputWrap">
                                                    <input
                                                        type="text"
                                                        readOnly
                                                        className={`formControl`}
                                                        value={username}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dbCol dbColLg50">
                                            <div className="formRow">
                                                <label className="txtLabel">First Name</label>
                                                <div className="inputWrap">
                                                    <input
                                                        type="text"
                                                        readOnly
                                                        className={`formControl`}
                                                        value={firstname}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="dbCol dbColLg50">
                                            <div className="formRow">
                                                <label className="txtLabel">Surname</label>
                                                <div className="inputWrap">
                                                    <input
                                                        type="text"
                                                        readOnly
                                                        className={`formControl`}
                                                        value={surname}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dbCol dbColLg50">
                                            <div className="formRow">
                                                <label className="txtLabel">Contact Info</label>
                                                <div className="inputWrap">
                                                    <input
                                                        type="tell"
                                                        readOnly
                                                        className={`formControl`}
                                                        value={number}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dbCol dbColLg50">
                                            <div className="formRow">
                                                <label className="txtLabel">Email</label>
                                                <div className="inputWrap">
                                                    <input
                                                        type="email"
                                                        readOnly
                                                        className={`formControl`}
                                                        value={email}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dbCol dbColLg25">
                                    <div className="imgWrap selectedImage">
                                        {selectedImage ?
                                            <img src={selectedImage} alt="Uploaded Image" className="uploaded-image" />
                                            :
                                            <div className="placeholderImage">?</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </form>
            )}
        </div>
    )
}

export default UpdateProfile