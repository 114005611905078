import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { HalfCircleSpinner } from "react-epic-spinners";
import { SearchSelect } from "../searchSelect";
import { colourStyles } from "../../data/index";
import deleteUser from "../../api/services/deleteUser";
import userListing from "../../api/services/userListing";
import {
    departmentGroups,
    userDepartmentListHaveCases,
} from "../../api/services/departmnent-services";

const DeavtiveUserModal = ({
    showforDepartment,
    setShowForDepartment,
    setShowTransferCase,
    selectedUserToDeactivate,
    setPagination,
    setUsersList,
    department,
    setShowDeactivateUser,
    setDepartment,
    setSelectedUserId,
    selectedUserId,
    setShowTransferUserSearch,
    group,
    setGroup,
    groupListing,
    setGroupListing
}) => {
    const [departmentList, setDepartmentList] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [departmentListLoading, setDepartmentListLoading] = useState(false);
    const [groupsLoading, setGroupsLoading] = useState(false);

    const handleCancel = () => {
        setShowDeactivateUser(false);
        setSelectedUserId(null);
        setShowForDepartment(false);
        setShowLoader(false);
        setShowTransferUserSearch("");
        setDepartment(null);
        setGroup(null)
        setGroupListing([])
    }


    useEffect(() => {
        if (selectedUserId) {
            setDepartmentListLoading(true);
            userDepartmentListHaveCases
                .get(selectedUserId)
                .then((res) => {
                    setDepartmentListLoading(false);
                    setDepartmentList(
                        res?.data.map((item) => ({
                            value: item.id,
                            label: item.name,
                        }))
                    );

                    if (res?.data?.length === 1) {
                        setGroupsLoading(true);

                        setDepartment(res.data[0].id);
                        departmentGroups
                            .get(res.data[0].id, { having_cases: true, old_user_id: selectedUserToDeactivate.id })
                            .then((res) => {
                                setGroupsLoading(false);
                                if (res.data?.length) {
                                    const transformedGroupData = res.data.map((item) => ({
                                        value: item.id,
                                        label: item.name,
                                    }));
                                    if (res?.data?.length === 1) {
                                        setGroup(res.data[0].id);
                                    }
                                    setGroupListing(transformedGroupData);
                                }
                                setGroupsLoading(false);
                            })
                            .catch((error) => {
                                setGroupsLoading(false);
                                handleCancel()
                                // console.error("Error fetching datass:", error.data.message);
                                toast.error(error.data.message);
                            });
                    }
                })
                .catch((error) => {
                    setGroupsLoading(false);
                    setDepartmentListLoading(false);
                    console.error("Error fetching data:", error);
                });
        }
    }, [selectedUserId, showforDepartment]);

    // Handle_Department_Select
    const handleDepartmentSelect = (event) => {
        setDepartment(event.value);
        setGroup(null);
        setGroupListing([]);
        setGroupsLoading(true);
        departmentGroups
            .get(event.value, { having_cases: true, old_user_id: selectedUserToDeactivate.id })
            .then((res) => {
                setGroupsLoading(false);
                if (res.data?.length) {
                    const transformedGroupData = res.data.map((item) => ({
                        value: item.id,
                        label: item.name,
                    }));
                    setGroupListing(transformedGroupData);
                    if (res?.data?.length === 1) {
                        setGroup(res.data[0].id);
                    }
                }
            })
            .catch((error) => {
                setGroupsLoading(false);
                console.error("Error fetching data:", error);
            });
    };

    // Handle_Department_Select
    const handleGroupsSelect = (event) => {
        setGroup(event.value);
    };

    // Handle_Delete_User
    const handleDeleteUser = () => {
        setShowLoader(true);
        deleteUser
            .delete(selectedUserId)
            .then((res) => {
                setShowLoader(false);
                toast.success(res.message);
                setSelectedUserId(null);
                setShowForDepartment(false);
                setDepartment(null);
                setShowDeactivateUser(false);
                userListing
                    .getUsers(1)
                    .then((res) => {
                        setUsersList([...res.data]);
                        setPagination({ ...res.meta });
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    });
            })
            .catch((error) => {
                setShowLoader(false);
                if (error.data?.data?.has_cases) {
                    setShowForDepartment(true);
                } else {
                    toast.error(error.data.message);
                }
            });
    };


    return (
        <div className="userForms userDeactiveModal">
            <article className="widgetWrap">
                {!showforDepartment && selectedUserId && (
                    <div className="widgetBody">
                        <div className="textCenter txtWhite noteTxt">
                            Are you sure you want to delete{" "}
                            <span className="textCapitalize">
                                "{selectedUserToDeactivate.username}"
                            </span>{" "}
                            ?
                        </div>

                        <div className="btnWrap">
                            <button
                                type="button"
                                className="btn btnPrimary"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btnPrimary"
                                onClick={() => {
                                    handleDeleteUser();
                                }}
                            >
                                Delete{" "}
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: "relative",
                                            top: "2px",
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
                {showforDepartment && (
                    <div className="widgetBody">
                        <div className="textCenter txtWhite noteTxt">
                            To deactivate this user all active cases must be assigned to
                            another user
                        </div>
                        {departmentListLoading ? <div className="textCenter txtWhite noteTxt">
                            Department List Loading
                        </div> : departmentList && departmentList.length > 1 && (
                            <div className="dbRow">
                                <div className="dbCol dbColLg33">
                                    <label className="txtLabel">Select a Department</label>
                                    <div className="selectWrap posRelative">
                                        <SearchSelect
                                            name="department"
                                            id="department"
                                            className="basic-multi-select multiSelectDropdown txtRight"
                                            classNamePrefix="select"
                                            styles={colourStyles}
                                            options={departmentList}
                                            defaultValue={department}
                                            onChange={(event) => {
                                                handleDepartmentSelect(event);
                                            }}
                                            placeholder="Select Department"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {groupsLoading ? <div className="textCenter txtWhite noteTxt">
                            Group List Loading
                        </div> : groupListing && groupListing.length > 1 && (
                            <div className="dbRow">
                                <div className="dbCol dbColLg33">
                                    <label className="txtLabel">Select a Group</label>
                                    <div className="selectWrap posRelative">
                                        <SearchSelect
                                            name="group"
                                            id="group"
                                            className="basic-multi-select multiSelectDropdown txtRight"
                                            classNamePrefix="select"
                                            styles={colourStyles}
                                            options={groupListing}
                                            isDisabled={!department}
                                            defaultValue={group}
                                            onChange={(event) => {
                                                handleGroupsSelect(event);
                                            }}
                                            placeholder="Select Group"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="btnWrap">
                            <button
                                type="button"
                                className="btn btnPrimary"
                                onClick={() => {
                                    if (!department) {
                                        toast.error("Please select a department");
                                        return;
                                    } else if (!group) {
                                        toast.error("Please select a group");
                                        return;
                                    } else {
                                        setShowTransferUserSearch("individually");
                                        setShowDeactivateUser(false);
                                        setShowTransferCase(true);
                                    }
                                }}
                            >
                                Assign cases individually to another user
                            </button>
                            <button
                                type="button"
                                className="btn btnPrimary"
                                onClick={() => {
                                    if (!department) {
                                        toast.error("Please select a department");
                                        return;
                                    } else if (!group) {
                                        toast.error("Please select a group");
                                        return;
                                    } else {
                                        setShowDeactivateUser(false);
                                        setShowTransferUserSearch("all");
                                        setShowTransferCase(true);
                                    }
                                }}
                            >
                                Assign all cases to another user
                            </button>
                        </div>
                        <button
                            type="button"
                            className="btn btnWhite btnPrimaryOutline noRadius w100 dBlock"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </article>
        </div>
    );
};

export default DeavtiveUserModal;
