import React, { useState, useEffect, useRef } from "react";
import getDepartmentsList from "../../api/services/userDepartmentsList";
import { MultiSelect } from "react-multi-select-component";
import { HalfCircleSpinner } from "react-epic-spinners";
import { toast } from "react-toastify";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../api/services/auth";
import ShowPreview from "../../components/upload-case/showPreview";
import getSingleCaseInfo from "../../api/services/singleCase";
import getDepartmentEntitiesList from "../../api/services/departmentsEntitiesList";
import updateCaseInfo from "../../api/services/updateCaseInfo";
import ImageUpdate from "../../components/update-case/imageUpdate";
import DatePicker from "react-date-picker";
import { groupConsultant } from "./../../api/services/departmnent-services/index";
import Popup from "reactjs-popup";
import { requestSuppourt } from "../../api/services/support-request";

const UpdateCase = (props) => {
    const { userExist, user } = useAuth();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        reference: "",
        age: "",
        date: "",
        operativeTime: "",
        pastMedicalHistory: "",
        note: "",
        createdAt: "",
    });
    const [loading, setLoading] = useState(true);
    const [gender, setGender] = useState(null);
    const [procedure, setProcedure] = useState(null);
    const [procedureList, setProcedureList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [currentUser, setCurrentUser] = useState(user);
    const [usersList, setUsersList] = useState([]);
    const [consultant, setConsultant] = useState("");
    const [department, setDepartment] = useState("");
    const [group, setGroup] = useState("");
    const [groupList, setGroupList] = useState([]);
    const [assistant, setAssistant] = useState("");
    const [assistantList, setAssistantList] = useState([]);
    const [status, setStatus] = useState(null);
    const [caseStatus, seCaseStatus] = useState(null);
    const [comorbidityListing, setComorbidityListing] = useState([]);
    const [departmentEntitiesList, setDepartmentEntitiesList] = useState([]);
    const [comorbidities, setComorbidities] = useState([]);
    const [selectedComorbidities, setSelectedComorbidities] = useState([]);
    const [complicationListing, setComplicationListing] = useState([]);
    const [complications, setComplications] = useState([]);
    const [selectedComplications, setSelectedComplications] = useState([]);
    const [errors, setErrors] = useState({});
    const [showLoaderDraft, setShowLoaderDraft] = useState(false);
    const [showLoaderUpdate, setShowLoaderUpdate] = useState(false);
    const [draftButtonDisabled, setDraftButtonDisabled] = useState(false);
    const [imagesToUpload, setImagesToUpload] = useState([]);
    const [caseDataBefore, setCaseDataBefore] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [removeImageName, setRemoveImagename] = useState([]);
    const [editedImageIndex, setEditedImageIndex] = useState(null);
    const [toggleStates, setToggleStates] = useState([]);
    const [bedforeToggleStates, setBedforeToggleStates] = useState([]);
    const [supportRequestModal, setSupportRequstModal] = useState('');
    const [supportRequestContent, setSupportRequestContent] = useState('');

    const navigate = useNavigate();
    const myRef = useRef(null);

    // Fetch_Selected_Group_Consultant_List
    const fetchGroupEntities = (id) => {
        groupConsultant
            .get(id)
            .then((res) => {
                setUsersList(
                    res.data.map((item) => ({
                        value: item.id,
                        label: item.full_name,
                    }))
                );
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    //fetch Case data
    useEffect(() => {
        getSingleCaseInfo
            .caseInfo(id)
            .then((res) => {
                setLoading(false);
                if (res.data && res.data?.length > 0) {
                    setCaseDataBefore(res.data[0]);
                    const token = localStorage.getItem("authToken");
                    const loggedInUser = localStorage.getItem("loggedInUser");

                    if (token) {
                        const parsedUser = JSON.parse(loggedInUser);
                        setCurrentUser(parsedUser);

                        if (parsedUser.id) {
                            getDepartmentsList
                                .get(parsedUser.id)
                                .then((response) => {
                                    const exist = response?.data?.some(
                                        (dep) => dep.id === res.data[0].department_id
                                    );
                                    if (exist) {
                                        setDepartmentList(response?.data);
                                    } else {
                                        setDepartmentList([
                                            ...response.data,
                                            {
                                                id: res.data[0].department_id,
                                                name: res.data[0].department,
                                                deleted: true,
                                            },
                                        ]);
                                        setProcedureList([
                                            {
                                                id: res.data[0].procedure_id,
                                                name: res.data[0].procedure,
                                                deleted: true,
                                            },
                                        ]);
                                        setAssistantList([
                                            {
                                                id: res.data[0].assigned_to_id,
                                                name: res.data[0].assigned_to,
                                                deleted: true,
                                            },
                                        ]);
                                        setUsersList([
                                            {
                                                id: res.data[0].consultant_id,
                                                name: res.data[0].consultant,
                                                deleted: true,
                                            },
                                        ]);
                                        setGroupList([
                                            {
                                                id: res.data[0].group_id,
                                                name: res.data[0].group,
                                                deleted: true,
                                            },
                                        ]);
                                    }
                                    fetchGroupEntities(res.data[0].group_id);
                                    let parts = res.data[0].procedure_date.split("/");
                                    let formattedDate =
                                        parts[2] + "-" + parts[1] + "-" + parts[0];

                                    let partsCreatedAt = res.data[0]?.created_at?.split("/");
                                    let formattedCreatedAtDate =
                                        partsCreatedAt[2] +
                                        "-" +
                                        partsCreatedAt[1] +
                                        "-" +
                                        partsCreatedAt[0];
                                    setGender(res.data[0].gender);
                                    setSelectedComplications(
                                        res.data[0].complications
                                            ? Object.entries(res.data[0].complications).map(
                                                ([key, value]) => ({
                                                    label: value,
                                                    value: parseInt(key),
                                                })
                                            )
                                            : []
                                    );
                                    setSelectedComorbidities(
                                        res.data[0].comorbidities
                                            ? Object.entries(res.data[0].comorbidities).map(
                                                ([key, value]) => ({
                                                    label: value,
                                                    value: parseInt(key),
                                                })
                                            )
                                            : []
                                    );
                                    setStatus(res.data[0].status);
                                    seCaseStatus(res.data[0].case_status);
                                    setToggleStates(
                                        res.data[0].images?.map((item) =>
                                            item.type == 1 ? true : false
                                        )
                                    );
                                    setBedforeToggleStates(
                                        res.data[0].images?.map((item) =>
                                            item.type == 1 ? true : false
                                        )
                                    );
                                    setProcedure(res.data[0].procedure_id);

                                    setGroup(res.data[0].group_id);
                                    if (res.data[0].department_id) {
                                        setDepartment(res.data[0].department_id);
                                        fetchDepartmentEntitiesList(
                                            res.data[0].department_id,
                                            res.data[0],
                                            "show"
                                        );
                                    }
                                    setAssistant(res.data[0].assigned_to_id);
                                    setConsultant(res.data[0].consultant_id);
                                    setImagesToUpload([
                                        ...res.data[0].images?.map((item) => ({
                                            oldImage: true,
                                            imageUrl: item.url,
                                            name: item.name,
                                            imageType: item.type == 1 ? true : false,
                                        })),
                                    ]);
                                    setFormData({
                                        ...formData,
                                        reference: res.data[0].reference,
                                        age: res.data[0].age,
                                        date: res.data[0].procedure_date ? formattedDate : "",
                                        operativeTime: res.data[0].operative_time,
                                        pastMedicalHistory: res.data[0].past_medical_history || '',
                                        note: res.data[0].note || '',
                                        createdAt: res.data[0].created_at
                                            ? formattedCreatedAtDate
                                            : "",
                                    });
                                })
                                .catch((error) => {
                                    console.error("Error fetching data:", error);
                                });
                        }
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error fetching data:", error);
            });
    }, []);

    // Fetch_Selected_Department_Entities_Listing
    const fetchDepartmentEntitiesList = (id, data, status) => {
        getDepartmentEntitiesList
            .get(id)
            .then((res) => {
                setDepartmentEntitiesList(res.data);
                const comorbiditiesData = res.data.comorbidities;
                const complicationssData = res.data.complications;

                if (comorbiditiesData) {
                    setComorbidityListing([...comorbiditiesData]);
                } else if (status !== "show") {
                    setComorbidityListing([]);
                    setSelectedComorbidities([]);
                }
                if (complicationssData) {
                    setComplicationListing([...complicationssData]);
                } else if (status !== "show") {
                    setSelectedComplications([]);
                    setComplicationListing([]);
                }

                if (res.data.groups) {
                    if (data) {
                        let exist = res.data.groups.find((g) => g.id === data.group_id);
                        if (exist) {
                            setGroupList([...res.data.groups]);
                            if (status === "show") {
                                setGroup(data.group_id);
                            }
                        } else {
                            if (status === "show") {
                                setGroupList([
                                    ...res.data.groups,
                                    { id: data.group_id, name: data.group, deleted: true },
                                ]);
                                setGroup(data.group_id);
                            } else {
                                setGroupList([...res.data.groups]);
                            }
                        }
                    }
                } else if (status !== "show") {
                    setGroupList([]);
                }
                if (res.data.procedures) {
                    if (data) {
                        let exist = res.data.procedures.find(
                            (g) => g.id === data.procedure_id
                        );
                        if (exist) {
                            setProcedureList([...res.data.procedures]);
                            if (status === "show") {
                                setProcedure(data.procedure_id);
                            }
                        } else {
                            if (status === "show") {
                                setProcedureList([
                                    ...res.data.procedures,
                                    {
                                        id: data.procedure_id,
                                        name: data.procedure,
                                        deleted: true,
                                    },
                                ]);
                                setProcedure(data.procedure_id);
                            } else {
                                setProcedureList([...res.data.procedures]);
                            }
                        }
                    }
                } else if (status !== "show") {
                    setProcedureList([]);
                }
                if (res.data.consultants) {
                    if (data) {
                        let exist = res.data.consultants.find(
                            (g) => g.id === data.consultant_id
                        );
                        if (exist) {
                            setUsersList([...res.data.consultants]);
                            if (status === "show") {
                                setConsultant(data.consultant_id);
                            }
                        } else {
                            if (status === "show") {
                                setUsersList([
                                    ...res.data.consultants,
                                    {
                                        id: data.consultant_id,
                                        name: data.consultant,
                                        deleted: true,
                                    },
                                ]);
                                setConsultant(data.consultant_id);
                            } else {
                                setUsersList([...res.data.consultants]);
                            }
                        }
                    }
                } else if (status !== "show") {
                    setUsersList([]);
                }
                if (res.data.assistants) {
                    if (data) {
                        let exist = res.data.assistants.find(
                            (g) => g.id === data.assigned_to_id
                        );
                        if (exist) {
                            setAssistantList([...res.data.assistants]);
                            if (status === "show") {
                                setAssistant(data.assigned_to_id);
                            }
                        } else {
                            if (status === "show") {
                                setAssistantList([
                                    ...res.data.assistants,
                                    {
                                        id: data.assigned_to_id,
                                        name: data.assigned_to,
                                        deleted: true,
                                    },
                                ]);
                                setAssistant(data.assigned_to_id);
                            } else {
                                setAssistantList([...res.data.assistants]);
                            }
                        }
                    }
                } else if (status !== "show") {
                    setAssistantList([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const transformedComorbidityData = comorbidityListing.map((item) => ({
        value: item.id,
        label: item.name,
        // ...(item.deleted && { deleted: true })
    }));

    const transformedComplicationData = complicationListing.map((item) => ({
        value: item.id,
        label: item.name,
        // ...(item.deleted && { deleted: true })
    }));

    useEffect(() => {
        const token = localStorage.getItem("authToken");
        const loggedInUser = localStorage.getItem("loggedInUser");

        if (token) {
            setCurrentUser(JSON.parse(loggedInUser));
        }
    }, []);

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Update Case | Cappagh";
    }, []);

    // Error_Scroll_Reference
    const scrollToTopRef = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
    };

    const executeScroll = () => {
        scrollToTopRef(myRef);
    };

    useEffect(() => {
        if (Object.keys(errors)?.length > 0) {
            executeScroll();
            setShowPreview(false);
        }
    }, [errors]);

    // Handle_Gender_Select
    const handleGenderSelect = (event) => {
        setGender(event.target.value);
    };

    // Handle_Procedure_Select
    const handleProcedureSelect = (event) => {
        setProcedureList([...procedureList.filter((dep) => !dep.deleted)]);
        setProcedure(event.target.value);
    };

    // Handle_Consultant_Select
    const handleConsultantSelect = (event) => {
        setUsersList([...usersList.filter((dep) => !dep.deleted)]);
        setConsultant(event.target.value);
    };

    const handleAssistantSelect = (event) => {
        setAssistantList([...assistantList.filter((dep) => !dep.deleted)]);
        setAssistant(event.target.value);
    };

    // Handle_Department_Select
    const handleDepartmentSelect = (event) => {
        setDepartmentList([...departmentList.filter((dep) => !dep.deleted)]);
        setAssistant("");
        setConsultant("");
        setProcedure("");
        setGroup("");
        setSelectedComplications([]);
        setComplicationListing([]);
        setComorbidityListing([]);
        setSelectedComorbidities([]);
        setDepartment(event.target.value);
        if (event.target.value) {
            fetchDepartmentEntitiesList(event.target.value, caseDataBefore, "not");
        }
    };

    // Handle_Group_Select
    const handleGroupSelect = (event) => {
        setUsersList([]);
        setConsultant("");
        setGroupList([...groupList.filter((dep) => !dep.deleted)]);
        setGroup(event.target.value);
        if (event.target.value) {
            fetchGroupEntities(event.target.value);
        }
    };

    // Handle_Tag_Removal_(Comorbidity)
    const handleComorbidityTagRemove = (tagId) => {
        const updatedSelectedComorbidities = selectedComorbidities.filter(
            (tag) => tag.value !== tagId
        );
        setSelectedComorbidities(updatedSelectedComorbidities);
        const updatedComorbidities = comorbidities.filter(
            (tag) => tag.value !== tagId
        );
        setComorbidities(updatedComorbidities);
    };

    // Handle_Tag_Removal_(Complication)
    const handleComplicationTagRemove = (tagId) => {
        const updatedSelectedComplication = selectedComplications.filter(
            (tag) => tag.value !== tagId
        );
        setSelectedComplications(updatedSelectedComplication);
        const updatedComplications = complications.filter(
            (tag) => tag.value !== tagId
        );
        setComplications(updatedComplications);
    };

    // Check_Validation_Function
    const checkValidation = () => {
        let errors = {};
        let isValid = true;
        // if (!formData.reference) {
        //     isValid = false;
        //     errors["caseRef"] = 'Please enter a "unique" Case Ref.';
        // }
        if (!gender) {
            isValid = false;
            errors["gender"] = "Please select a Gender.";
        }
        if (!formData.age) {
            isValid = false;
            errors["age"] = "Please select Age.";
        }
        if (!procedure) {
            isValid = false;
            errors["procedure"] = "Please select a Procedure.";
        }
        if (!consultant) {
            isValid = false;
            errors["consultant"] = "Please select a Consultant.";
        }
        if (!department) {
            isValid = false;
            errors["department"] = "Please select a Department.";
        }
        if (!group) {
            isValid = false;
            errors["group"] = "Please select a Group.";
        }
        if (!assistant) {
            isValid = false;
            errors["assistant"] = "Please select a Assistant.";
        }
        setErrors(errors);
        executeScroll();
        return isValid;
    };

    function imageTypesMatch(array1, array2) {
        // Check if the arrays have the same length
        if (array1.length !== array2.length) {
            return false;
        }

        // Compare each element of the arrays
        for (let i = 0; i < array1.length; i++) {
            if (array1[i] !== array2[i]) {
                return false;
            }
        }

        // If all elements match, return true
        return true;
    }

    // Handle_Create_Case
    const handleUpdateCase = (event, upcomingStatus) => {
        event.preventDefault();
        if (checkValidation()) {
            if (upcomingStatus === "published") {
                setShowLoaderUpdate(true);
            } else {
                setShowLoaderDraft(true);
            }
            const procedureToSent = procedureList.find((p) => p.id === procedure);
            const consultantToSent = usersList.find((p) => p.id === consultant);
            const groupToSent = groupList.find((p) => p.id === group);
            const assignToSent = assistantList.find((p) => p.id === assistant);
            const requestBody = new FormData();
            requestBody.append("reference", formData.reference);
            requestBody.append("gender", gender);
            requestBody.append("age", formData.age);
            requestBody.append(
                "status",
                upcomingStatus === "published" ? "published" : caseDataBefore.actual_status
            );
            caseDataBefore.procedure_id !== procedure &&
                !procedureToSent?.deleted &&
                requestBody.append("procedure_id", procedure);
            caseDataBefore.consultant_id !== consultant &&
                !consultantToSent?.deleted &&
                requestBody.append("consultant_id", consultant);
            caseDataBefore.department_id !== department &&
                requestBody.append("department_id", department);
            !groupToSent?.deleted && requestBody.append("group_id", group);
            formData.date && requestBody.append("procedure_date", formData.date);
            formData.operativeTime && requestBody.append("operative_time", formData.operativeTime);
            requestBody.append("past_medical_history", formData.pastMedicalHistory);
            requestBody.append("note", formData.note ?? "");
            caseDataBefore.assigned_to_id !== assistant &&
                !assignToSent?.deleted &&
                requestBody.append("assigned_to", assistant);

            selectedComorbidities &&
                selectedComorbidities?.length > 0 &&
                selectedComorbidities.forEach((comorbidity, index) => {
                    !comorbidity?.deleted &&
                        requestBody.append(`comorbidity_ids[${index}]`, comorbidity.value);
                });

            selectedComplications &&
                selectedComplications?.length > 0 &&
                selectedComplications.forEach((complication, index) => {
                    !complication?.deleted &&
                        requestBody.append(
                            `complication_ids[${index}]`,
                            complication.value
                        );
                });
            if (
                (imagesToUpload &&
                    imagesToUpload.length > 0 &&
                    !imagesToUpload?.some(
                        (item) =>
                            item.oldImage &&
                            imageTypesMatch(toggleStates, bedforeToggleStates)
                    )) ||
                (removeImageName && removeImageName.length > 0)
            ) {
                imagesToUpload.forEach((item, index) => {
                    if (!item.oldImage) {
                        const imageData = item.src;
                        const byteCharacters = atob(imageData.split(",")[1]);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: "image/png" });
                        const file = new File([blob], `image_${index}.png`, {
                            type: "image/png",
                        });

                        requestBody.append(`images[${index}]`, file);
                    } else {
                        requestBody.append(`images[${index}]`, item.name); // Append the name of the old image
                    }
                });
            }

            removeImageName &&
                removeImageName.length > 0 &&
                removeImageName?.forEach((name, index) => {
                    requestBody.append(`deletable_images[${index}]`, name);
                });
            toggleStates &&
                toggleStates?.length > 0 &&
                toggleStates.forEach((img, index) => {
                    requestBody.append(`image_type[${index}]`, img ? 1 : 0);
                });
            updateCaseInfo
                .updateInfo(id, requestBody)
                .then((res) => {
                    if (res.status === 200) {
                        setShowLoaderDraft(false);
                        setShowLoaderUpdate(false);
                        toast.success(res.message);
                        window.history.back();
                    }
                })
                .catch((error) => {
                    setShowLoaderDraft(false);
                    setShowLoaderUpdate(false);
                    if (error?.data?.errors?.id) {
                        toast.error(
                            "Case With same Refrence is in archive. Contact administrator to restore it"
                        );
                    } else {
                        toast.error(error.data.message);
                    }
                })
                .finally(() => {
                    setShowLoaderDraft(false);
                    setShowLoaderUpdate(false);
                });
        }
    };

    // date_slecetion_date
    const handleDateChange = (newDate) => {
        if (newDate instanceof Date && !isNaN(newDate)) {
            const year = newDate.getFullYear();
            const month = String(newDate.getMonth() + 1).padStart(2, "0");
            const day = String(newDate.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            setFormData((prevData) => ({
                ...prevData,
                date: formattedDate,
            }));
            // setDate(formattedDate);
        } else {
            setFormData((prevData) => ({
                ...prevData,
                date: null,
            }));
            // setDate(null);
        }
    };

    //handle_case_preview
    const handlePreviewCase = () => {
        setShowPreview(!showPreview);
    };

    //set_all_inputs_here_i_state
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    //function_selection_Complication
    const handleSelectedComplication = (options) => {
        setSelectedComplications(options);
    };

    const handleSupportRequest = (e, option) => {
        e.preventDefault();
        setSupportRequstModal(option)
    }

    const checkCreate = () => {
        let errors = {};
        let isValid = true;
        if (!supportRequestContent) {
            isValid = false;
            errors['supportRequestContent'] = 'Please enter a "unique" Case Ref.';
        }

        setErrors(errors)
        return isValid;
    }

    const handleRequest = () => {
        if (checkCreate()) {
            requestSuppourt.request({ heading: `Request additional ${supportRequestModal}`, content: supportRequestContent }).then((res) => {
                toast.success(res.message);
                setSupportRequstModal('')
                setSupportRequestContent('')
            }).catch((error) => {
                toast.error(error.data.message);
            })
        }
    }


    //chcek auth
    if (!userExist) {
        return <Navigate to="/login" />;
    }

    return (
        <div ref={myRef} className="dbContentWrap caseDetail">
            {loading ? (
                <HalfCircleSpinner
                    size={18}
                    color='black'
                    style={{
                        display: "inline-block",
                        marginLeft: "7px",
                        position: "relative",
                        top: "2px",
                    }}
                />
            ) : (
                <>
                    {showPreview && (
                        <ShowPreview
                            caseData={{
                                comorbidities: selectedComorbidities
                                    ? selectedComorbidities?.map((obj) => obj.label)
                                    : [],
                                complications: selectedComplications
                                    ? selectedComplications?.map((obj) => obj.label)
                                    : [],
                                consultant:
                                    usersList?.find((p) => p?.value === consultant)?.label ??
                                    null,
                                date: formData.date,
                                gender: gender,
                                group: groupList?.find((p) => p?.id === group)?.name ?? null,
                                note: formData.note,
                                operative_time: formData.operativeTime,
                                past_medical_history: formData.pastMedicalHistory,
                                procedure:
                                    procedureList?.find((p) => p?.id === procedure)?.name ?? null,
                                status: status,
                                reference: formData.reference,
                                age: formData.age,
                                images: imagesToUpload,
                                created_at: caseDataBefore?.created_at,
                            }}
                        />
                    )}
                    <form className="uploadCase createCase">
                        <div className="dbRow">
                            {!showPreview && (
                                <>
                                    <div className="dbCol dbColLg35">
                                        <article className="widgetWrap detailWidget">
                                            <div className="widgetHeader">
                                                <span>Case Details</span>
                                            </div>
                                            <ul className="listUnstyled widgetDetailList listStriped roundedBottom">
                                                <li>
                                                    <label className="txTitle">
                                                        Case Ref<span className="required">*</span>
                                                    </label>
                                                    <div className="inputWrap">
                                                        <input
                                                            type="text"
                                                            className={`formControl`}
                                                            readOnly
                                                            disabled
                                                            placeholder="Case Ref (Required)"
                                                            name="reference"
                                                            value={formData.reference}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </li>
                                                <li>
                                                    <label className="txTitle">
                                                        Gender<span className="required">*</span>
                                                    </label>
                                                    <div className="selectWrap posRelative">
                                                        <select
                                                            name="gender"
                                                            className={`formControl ${errors.gender && "error"
                                                                }`}
                                                            id="gender"
                                                            value={gender}
                                                            onChange={handleGenderSelect}
                                                        >
                                                            <option selected disabled>
                                                                Select (Required)
                                                            </option>
                                                            <option id="1" value="male">
                                                                Male
                                                            </option>
                                                            <option id="2" value="female">
                                                                Female
                                                            </option>
                                                        </select>
                                                    </div>
                                                </li>
                                                <li>
                                                    <label className="txTitle">
                                                        Age<span className="required">*</span>
                                                    </label>
                                                    <div className="inputWrap">
                                                        <input
                                                            type="text"
                                                            className={`formControl ${errors.age && "error"}`}
                                                            placeholder="Age (Required)"
                                                            name="age"
                                                            value={formData.age}
                                                            onChange={(e) => {
                                                                const inputNumber = e.target.value;
                                                                if (/^[0-9]*$/.test(inputNumber)) {
                                                                    handleInputChange(e);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </li>
                                                {departmentList.length > 1 && (
                                                    <li>
                                                        <label className="txTitle">
                                                            Department<span className="required">*</span>
                                                        </label>
                                                        <div className="selectWrap posRelative">
                                                            <select
                                                                name="department"
                                                                className={`formControl ${errors.department && "error"
                                                                    }`}
                                                                id="department"
                                                                value={department}
                                                                onChange={handleDepartmentSelect}
                                                            >
                                                                {departmentList &&
                                                                    departmentList.length > 0 && (
                                                                        <>
                                                                            <option value="">
                                                                                Select Department
                                                                            </option>
                                                                            {departmentList.map((item) => (
                                                                                <option
                                                                                    key={item.id}
                                                                                    value={item.id}
                                                                                    selected={
                                                                                        departmentList?.length === 1
                                                                                    }
                                                                                >
                                                                                    {item.name}
                                                                                </option>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                            </select>
                                                        </div>
                                                    </li>
                                                )}
                                                <li>
                                                    <label className="txTitle">
                                                        Group<span className="required">*</span>
                                                    </label>
                                                    <div className="selectWrap posRelative">
                                                        <select
                                                            name="group"
                                                            className={`formControl ${errors.group && "error"
                                                                }`}
                                                            id="group"
                                                            value={group}
                                                            onChange={handleGroupSelect}
                                                            disabled={!department}
                                                        >
                                                            <option value="" selected={!group}>
                                                                Select (Required)
                                                            </option>
                                                            {groupList.map((item) => (
                                                                <option key={item.id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </li>
                                                <li>
                                                    <label className="txTitle">
                                                        Consultant<span className="required">*</span>
                                                    </label>
                                                    <div className="selectWrap posRelative">
                                                        <select
                                                            name="consultant"
                                                            className={`formControl ${errors.consultant && "error"
                                                                }`}
                                                            id="consultant"
                                                            value={consultant}
                                                            disabled={
                                                                !department ||
                                                                !group ||
                                                                !usersList ||
                                                                usersList?.length < 1
                                                            }
                                                            onChange={handleConsultantSelect}
                                                        >
                                                            <option value="" selected={!consultant}>
                                                                Select Consultant
                                                            </option>
                                                            {usersList &&
                                                                usersList.map((item) => (
                                                                    <option key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </li>
                                                <li>
                                                    <label className="txTitle">
                                                        Procedure<span className="required">*</span>
                                                    </label>
                                                    <div className="selectWrap posRelative">
                                                        <select
                                                            name="procedure"
                                                            className={`formControl ${errors.procedure && "error"
                                                                }`}
                                                            id="procedure"
                                                            value={procedure}
                                                            onChange={handleProcedureSelect}
                                                            disabled={
                                                                !department ||
                                                                !procedureList ||
                                                                procedureList?.length < 1
                                                            }
                                                        >
                                                            <option value="" selected={!procedure}>
                                                                Select (Required)
                                                            </option>
                                                            {procedureList &&
                                                                procedureList.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </li>
                                                <li>
                                                    <label className="txTitle">
                                                        Assign To<span className="required">*</span>
                                                    </label>
                                                    <div className="selectWrap posRelative">
                                                        <select
                                                            name="assistant"
                                                            className={`formControl ${errors.assistant && "error"
                                                                }`}
                                                            id="assistant"
                                                            value={assistant}
                                                            onChange={handleAssistantSelect}
                                                            disabled={
                                                                !department ||
                                                                !assistantList ||
                                                                assistantList?.length < 1
                                                            }
                                                        >
                                                            <option value="" selected={!assistant}>
                                                                Select Assign to
                                                            </option>
                                                            {assistantList &&
                                                                assistantList.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                </li>
                                                <li>
                                                    <label className="txTitle">Procedure Date</label>
                                                    <div className="inputWrap dateWrap">
                                                        <DatePicker
                                                            className="formControl datePickerInput"
                                                            onChange={handleDateChange}
                                                            value={
                                                                formData.date ? new Date(formData.date) : null
                                                            }
                                                            dayPlaceholder="dd"
                                                            monthPlaceholder="mm"
                                                            yearPlaceholder="yyyy"
                                                            format="y/MM/dd"
                                                            maxDate={new Date(2099, 11, 31)} // Set maximum date to December 31, 2099
                                                            clearIcon={
                                                                formData.date ? (
                                                                    <i className="fa-regular fa-xmark"></i>
                                                                ) : null
                                                            }
                                                        />
                                                    </div>
                                                </li>
                                                <li>
                                                    <label className="txTitle">Operative Time</label>
                                                    <div className="inputWrap timeWrap">
                                                        <input
                                                            type="text"
                                                            className="formControl"
                                                            name="operativeTime"
                                                            value={formData.operativeTime}
                                                            // onChange={handleInputChange}
                                                            onChange={(e) => {
                                                                const inputNumber = e.target.value;
                                                                if (/^[0-9]*$/.test(inputNumber)) {
                                                                    setFormData({ ...formData, operativeTime: inputNumber });
                                                                }
                                                            }}
                                                        />
                                                        Hrs
                                                    </div>
                                                </li>
                                            </ul>
                                        </article>
                                    </div>
                                    <div className="dbCol dbColLg65">
                                        <article className="widgetWrap historyWidget">
                                            <div className="widgetHeader">
                                                <span>Past Medical History</span>
                                            </div>
                                            <div className="widgetBody">
                                                <textarea
                                                    className="formControl"
                                                    name="pastMedicalHistory"
                                                    value={formData.pastMedicalHistory}
                                                    onChange={handleInputChange}
                                                ></textarea>
                                            </div>
                                        </article>
                                        <article className="widgetWrap comoWidget">
                                            <div className="widgetHeader">
                                                <span>Comorbidities</span>
                                                <div className="tabHeadActionWrap">
                                                    <button type="button"
                                                        className={`actionBtn`} onClick={(e) => handleSupportRequest(e, 'Comorbidity')}>Request additional Comorbidity <i className="fa-solid fa-user-plus"></i></button>
                                                </div>
                                            </div>
                                            <div className="widgetBody">
                                                <div className="multiSelectWrap">
                                                    <label>Search Tags</label>
                                                    <MultiSelect
                                                        options={transformedComorbidityData}
                                                        value={selectedComorbidities}
                                                        onChange={setSelectedComorbidities}
                                                        labelledBy="Select"
                                                        className="multi-tag-select w100"
                                                        hasSelectAll={false}
                                                        disabled={!department}
                                                    />
                                                </div>
                                                <ul className="listUnstyled tagList multiTagList">
                                                    {selectedComorbidities &&
                                                        selectedComorbidities?.length > 0 &&
                                                        selectedComorbidities.map((tag) => (
                                                            <li key={tag.value}>
                                                                {tag.label}
                                                                {department && (
                                                                    <span
                                                                        className="tagRemove"
                                                                        onClick={() =>
                                                                            handleComorbidityTagRemove(tag.value)
                                                                        }
                                                                    >
                                                                        ×
                                                                    </span>
                                                                )}
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        </article>
                                        <article className="widgetWrap compWidget">
                                            <div className="widgetHeader">
                                                <span>Complications</span>
                                                <div className="tabHeadActionWrap">
                                                    <button type="button"
                                                        className={`actionBtn`} onClick={(e) => handleSupportRequest(e, 'Complication')}>Request additional Complication <i className="fa-solid fa-user-plus"></i></button>
                                                </div>
                                            </div>
                                            <div className="widgetBody">
                                                <div className="multiSelectWrap">
                                                    <label>Search Tags</label>
                                                    <MultiSelect
                                                        options={transformedComplicationData}
                                                        value={selectedComplications}
                                                        onChange={handleSelectedComplication}
                                                        labelledBy="Select"
                                                        className="multi-tag-select w100"
                                                        hasSelectAll={false}
                                                        disabled={!department}
                                                    />
                                                </div>
                                                <ul className="listUnstyled tagList multiTagList">
                                                    {selectedComplications &&
                                                        selectedComplications?.length > 0 &&
                                                        selectedComplications.map((tag) => (
                                                            <li key={tag.value}>
                                                                {tag.label}
                                                                {department && (
                                                                    <span
                                                                        className="tagRemove"
                                                                        onClick={() =>
                                                                            handleComplicationTagRemove(tag.value)
                                                                        }
                                                                    >
                                                                        ×
                                                                    </span>
                                                                )}
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        </article>
                                        <article className="widgetWrap historyWidget noteWidget">
                                            <div className="widgetHeader">
                                                <span>Notes</span>
                                            </div>
                                            <div className="widgetBody">
                                                <textarea
                                                    className="formControl"
                                                    name="note"
                                                    value={formData.note}
                                                    onChange={handleInputChange}
                                                ></textarea>
                                            </div>
                                        </article>
                                    </div>
                                    <div className="dbCol">
                                        <ImageUpdate
                                            setImagesToUpload={setImagesToUpload}
                                            setEditedImageIndex={setEditedImageIndex}
                                            editedImageIndex={editedImageIndex}
                                            setRemoveImagename={setRemoveImagename}
                                            removeImageName={removeImageName}
                                            imagesToUpload={imagesToUpload}
                                            toggleStates={toggleStates}
                                            setToggleStates={setToggleStates}
                                        />
                                    </div>
                                </>
                            )}
                            <div className="dbCol">
                                <div className="actionsBtnWrap">
                                    <button
                                        onClick={handlePreviewCase}
                                        type="button"
                                        className="btn btnPrimary"
                                    >
                                        {" "}
                                        {showPreview ? "Back To Edit" : "Preview Case"}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btnSecondary"
                                        disabled={
                                            editedImageIndex !== null ||
                                            draftButtonDisabled ||
                                            showLoaderDraft
                                        }
                                        onClick={(event) => handleUpdateCase(event, "same")}
                                    >
                                        Update Case
                                        {showLoaderDraft && (
                                            <HalfCircleSpinner
                                                size={18}
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                            />
                                        )}
                                    </button>
                                    {status === "draft" && (
                                        <button
                                            type="button"
                                            className="btn btnSecondary"
                                            disabled={
                                                editedImageIndex !== null ||
                                                draftButtonDisabled ||
                                                showLoaderUpdate ||
                                                showLoaderDraft
                                            }
                                            onClick={(event) => handleUpdateCase(event, "published")}
                                        >
                                            Publish Case
                                            {showLoaderUpdate && (
                                                <HalfCircleSpinner
                                                    size={18}
                                                    style={{
                                                        display: "inline-block",
                                                        marginLeft: "7px",
                                                        position: "relative",
                                                        top: "2px",
                                                    }}
                                                />
                                            )}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>

                    <Popup
                        className="deleteUserModal supportModal"
                        open={supportRequestModal !== ''}
                        onClose={() => {
                            setSupportRequstModal('')
                        }}
                    >
                        {supportRequestModal !== '' && (
                            <div className="">
                                <form className="">
                                    <article className="widgetWrap notifWidget sendNotiBox">
                                        <div className="widgetHeader">
                                            <span>Request additional {supportRequestModal}</span>
                                        </div>
                                        <div className="widgetFooter">
                                            <div className="notifBody">
                                                <textarea
                                                    className={`formControl ${errors.supportRequestContent && "error"}`}
                                                    name=""
                                                    id=""
                                                    onChange={(e) => setSupportRequestContent(e.target.value)}
                                                ></textarea>
                                            </div>
                                            <div className="notifActions">
                                                <button
                                                    onClick={handleRequest}
                                                    type="button"
                                                    className="btn btnSecondary w100 dBlock"
                                                >Request</button>
                                            </div>
                                        </div>
                                    </article>
                                </form>
                            </div>
                        )}
                    </Popup>
                </>
            )}


        </div>
    );
};

export default UpdateCase;
