import React, { useState } from "react";
import { useInput } from "../../hooks/useInput";
import caseStatus from "../../api/services/caseStatus";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../api/services/auth";
import { HalfCircleSpinner } from "react-epic-spinners";
import { toast } from "react-toastify";
import {
    setCaseStatus,
    caseMarkAsDiscuss,
} from "./../../api/services/case-status/index";
import { userPermissions, userRoles } from "../../enums";
import Popup from "reactjs-popup";
import { deleteConsultantCase } from "../../api/services/cases";

function FlagComment({ caseData }) {

    const navigate = useNavigate();
    let { id } = useParams();
    const currentDateOriginalFormat = new Date();
    const currentFormattedDate =
        currentDateOriginalFormat.toLocaleDateString("en-GB");
    let procedureDateParts = caseData.procedure_date.split("/");
    let currentDateDateParts = currentFormattedDate.split("/");
    var procedureDate = new Date(
        parseInt(procedureDateParts[2]),
        parseInt(procedureDateParts[1]) - 1,
        parseInt(procedureDateParts[0])
    );
    var currentDate = new Date(
        parseInt(currentDateDateParts[2]),
        parseInt(currentDateDateParts[1]) - 1,
        parseInt(currentDateDateParts[0])
    );
    const { user, userRole, permission } = useAuth();
    const [deleteId, setDeleteId] = useState(null);
    const [flagged, setFlagged] = useState(null);
    const [reason, bindReason, resetReason] = useInput("");
    const [postLoading, setPostLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [discussFlag, setDiscussFlag] = useState("");
    const [loading, setLoading] = useState(false);
    const [statuses, setStatuses] = useState({
        isDiscussed: false,
        isApproved: false,
    });
    const location = useLocation();
    const forArchive = (location?.state && location.state.forArchive) ? true : false

    const checkValidation = () => {
        let errors = {};
        let isValid = true;

        if (!flagged) {
            isValid = false;
            errors["name"] = "Please select flag.";
        }
        if (!reason) {
            isValid = false;
            errors["name"] = "Please Enter reason";
        }

        setErrors(errors);
        return isValid;
    };

    const handleCancel = () => {
        setFlagged(null);
        resetReason();
        setErrors({});
    };

    const handleSubmit = (event, status) => {
        event.preventDefault();
        if (checkValidation() && (status === "flag" || status === "reopen")) {
            setPostLoading(true);

            let requestBody = {
                reason: reason,
                case_id: id,
                user_id: user.id,
            };

            if (status === "reopen") {
                requestBody.reopen = true;
            } else {
                requestBody.status = status;
            }

            caseStatus
                .create(requestBody)
                .then((res) => {
                    if (res.status === 200) {
                        setFlagged(null);
                        setPostLoading(false);
                        toast.success(res.message);
                        navigate("/flagged-cases");
                        resetReason();
                    }
                })
                .catch((error) => {
                    setPostLoading(false);
                    toast.error(error.data.message);
                    resetReason();
                });
        }
    };

    const handleDiscussApprove = (event, status) => {
        event.preventDefault();
        setConfirmationModal(false);
        setPostLoading(true);
        const requestBody = {
            case_id: id,
            ...(status.isApproved ? { approve: "approve" } : {}),
            ...(status.isDiscussed ? { discuss: "discuss" } : {}),
        };

        setCaseStatus
            .create(requestBody)
            .then((res) => {
                if (res.status === 200) {
                    setStatuses({
                        isDiscussed: false,
                        isApproved: false,
                    });
                    setPostLoading(false);
                    setFlagged(null);
                    toast.success(res.message);
                    navigate(-1) ?? navigate("../../dashboard");
                }
            })
            .catch((error) => {
                setPostLoading(false);
                toast.error(error.data.message);
            });
    };

    const handleMarkAsDiscussed = (event, status) => {
        event.preventDefault();
        setPostLoading(true);
        setDiscussFlag(status);

        const requestBody = {
            case_id: id,
            discuss_flag: status,
        };

        caseMarkAsDiscuss
            .post(requestBody)
            .then((res) => {
                if (res.status === 200) {
                    setPostLoading(false);
                    toast.success(res.message);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            })
            .catch((error) => {
                setPostLoading(false);
                toast.error(error.data.message);
            });
    };


    // Handle_Delete_CASE
    const handleDeleteCase = () => {
        setLoading(true);
        deleteConsultantCase.delete(deleteId)
            .then((res) => {
                setLoading(false);
                toast.success(res.message);
                window.location.reload();

            }).catch((error) => {
                setLoading(false);
                setDeleteId(null)
                toast.error(error.data.message);
            }
            );
    };

    return (
        <>
            <div className="actionsBtnWrap">
                {permission?.some(
                    (p) => p.name === userPermissions.FLAGGED_CASES_FLAG
                ) &&
                    user.type !== userRoles.ASSISTANT &&
                    caseData.status !== "scheduled" &&
                    caseData.status !== "published" &&
                    !caseData.own_case &&
                    (
                        <>
                            {caseData.check_flag === "not_flagged" ? (
                                <button
                                    onClick={() => {
                                        setFlagged("flag");
                                    }}
                                    className="btn btnDanger btnToolTip"
                                >
                                    Flag
                                    <span className="toolTip">
                                        Flag case for oversight review
                                    </span>
                                </button>
                            ) : (
                                caseData.check_flag === 1 && (
                                    <button
                                        onClick={() => {
                                            setFlagged("reopen");
                                        }}
                                        className="btn btnDanger"
                                    >
                                        Re-Open Flag
                                    </button>
                                )
                            )}
                        </>
                    )}
                {permission?.some(
                    (p) => p.name === userPermissions.CASE_STATUS_SET_STATUS
                ) &&
                    caseData.is_discussed === 0 &&
                    caseData.status !== "scheduled" &&
                    caseData.status !== "published" && (
                        <button
                            onClick={(e) => {
                                if (caseData.is_approved === 0) {
                                    if (
                                        ((user.type === userRoles.CONSULTANT) && (user.id === caseData.consultant_id)) ||
                                        caseData.status === "scheduled" ||
                                        caseData.status === "published" ||
                                        procedureDate >= currentDate
                                    ) {
                                        handleDiscussApprove(e, {
                                            isDiscussed: true,
                                            isApproved: false,
                                        });
                                    } else {
                                        setFlagged("discuss");
                                        setStatuses({ ...statuses, isDiscussed: true });
                                        setConfirmationModal(true);
                                    }
                                } else {
                                    handleDiscussApprove(e, { ...statuses, isDiscussed: true });
                                }
                            }}
                            className="btn btnWarning btnToolTip"
                        >
                            Discuss
                            <span className="toolTip">
                                Add this case to the discussion list
                            </span>
                            {postLoading && statuses.isDiscussed && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: "relative",
                                        top: "2px",
                                    }}
                                />
                            )}
                        </button>
                    )}
                {permission?.some(
                    (p) => p.name === userPermissions.CASE_STATUS_SET_STATUS
                ) &&
                    caseData.status !== "scheduled" &&
                    caseData.status !== "published" &&
                    caseData.is_approved === 0 &&
                    procedureDate < currentDate &&
                    (user.type !== userRoles.CONSULTANT || user.id !== caseData.consultant_id) && (
                        <button
                            onClick={(e) => {
                                if (caseData.is_discussed === 0) {
                                    setFlagged("approve");
                                    setStatuses({ ...statuses, isApproved: true });
                                    setConfirmationModal(true);
                                } else {
                                    handleDiscussApprove(e, { ...statuses, isApproved: true });
                                }
                            }}
                            className="btn btnSuccess btnToolTip"
                        >
                            Approve
                            <span className="toolTip">
                                Routine case, no discussion needed, approve
                            </span>
                            {postLoading && statuses.isApproved && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: "relative",
                                        top: "2px",
                                    }}
                                />
                            )}
                        </button>
                    )}
            </div>
            {flagged !== null && (
                <>
                    <div className="errorMessage textCenter">
                        <p>
                            {flagged === "flag" &&
                                "Flagged cases are not displayed in group feeds, Reason for Flagging case (Required)"}
                        </p>
                    </div>
                    {(flagged === "flag" || flagged === "reopen") && (
                        <div className="formWrap">
                            <form action="" className="reasonForm">
                                <div className="formRow">
                                    <textarea
                                        name=""
                                        id=""
                                        className={`formControl dBlock w100  ${errors.name && "error"
                                            }`}
                                        placeholder="Add reason..."
                                        {...bindReason}
                                    ></textarea>
                                </div>
                                <div className="formBtnRow">
                                    <button
                                        type="button"
                                        className="btn btnPrimaryOutline"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btnPrimaryOutline"
                                        onClick={(e) =>
                                            handleSubmit(e, flagged === "reopen" ? "reopen" : "flag")
                                        }
                                    >
                                        Submit
                                        {postLoading && flagged === "flag" && (
                                            <HalfCircleSpinner
                                                size={18}
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                            />
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </>
            )}
            {permission?.some(
                (p) => p.name === userPermissions.CASES_MARK_DISCUSSED
            ) &&
                // caseData.status === "for-discussion" &&
                caseData.discuss_flag == 2 &&
                (user.type === userRoles.SUPER_USER || user.type === userRoles.HOD) && (
                    <div className="actionsBtnWrap disActionsWrap">
                        <button
                            type="button"
                            className="btn btnSecondary"
                            disabled={postLoading || (discussFlag === "1" && postLoading)}
                            onClick={(event) => handleMarkAsDiscussed(event, "1")}
                        >
                            Discussed
                            {postLoading && discussFlag === "1" && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: "relative",
                                        top: "2px",
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className="btn btnPrimary"
                            disabled={postLoading || (discussFlag === "0" && postLoading)}
                            onClick={(event) => handleMarkAsDiscussed(event, "0")}
                        >
                            Not Discussed
                            {postLoading && discussFlag === "0" && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: "relative",
                                        top: "2px",
                                    }}
                                />
                            )}
                        </button>

                    </div>
                )}
            {permission?.some(p => (p.name === userPermissions.CASES_CONSULTANT_DELETE)) &&
                caseData.status === 'approved' &&
                forArchive &&
                (
                    <div className="actionsBtnWrap disActionsWrap">
                        <button
                            type="button"
                            className="btn btnArchive w100"
                            onClick={() => setDeleteId(caseData.id)}
                        >
                            Archive
                        </button>
                    </div>
                )}
            <Popup
                className="deleteUserModal"
                open={confirmationModal}
                onClose={() => {
                    setConfirmationModal(false);
                }}
            >
                {confirmationModal !== null && (
                    <div className="modal textCenter">
                        <p>
                            Do You want{" "}
                            {flagged === "discuss"
                                ? "approve"
                                : flagged === "approve" && "discuss"}{" "}
                            that case too?
                        </p>
                        <div className="btnWrap">
                            <button
                                onClick={(e) => {
                                    handleDiscussApprove(e, statuses);
                                }}
                                type="button"
                                className="btn btnSecondary"
                            >
                                No
                            </button>
                            <button
                                onClick={(e) => {
                                    handleDiscussApprove(e, {
                                        isDiscussed: true,
                                        isApproved: true,
                                    });
                                }}
                                type="button"
                                className="btn btnPrimary"
                            >
                                Yes{" "}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>

            {/* DELETE_Case_Popup */}
            <Popup
                className="deleteUserModal"
                open={deleteId !== null}
                onClose={() => {
                    setDeleteId(null)
                }}
            >
                {deleteId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you have answered/replied all the coments and you want to close the case.</p>
                        <div className="btnWrap">
                            <button
                                onClick={() => {
                                    setDeleteId(null)
                                }}
                                type="button"
                                className="btn btnSecondary"
                            >No</button>
                            <button
                                onClick={handleDeleteCase}
                                type="button"
                                className="btn btnPrimary"
                                disabled={loading}
                            >Yes
                                {loading && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    );
}

export default FlagComment;
