import React from 'react'
import { useParams } from 'react-router-dom';
import { useInput } from '../../hooks/useInput';
import { toast } from 'react-toastify';
import createComment from './../../api/services/createComment';
import { useAuth } from '../../api/services/auth';
import caseCommentListing from '../../api/services/caseCommentListing';
import { useState } from 'react';
import { HalfCircleSpinner } from 'react-epic-spinners';

function PostComment({ setIsLoading, setCommentData, seUpcommingCase }) {
    let { id } = useParams();
    const { user } = useAuth()
    const [comment, bindComment, resetComment] = useInput("");
    const [postLoading, setPostLoading] = useState(false)
    const [errors, setErrors] = useState({});


    const checkValidation = () => {
        let errors = {};
        let isValid = true;

        if (!comment) {
            isValid = false;
            errors['name'] = 'Please enter comment.';
        }
        if (!user) {
            isValid = false;
            errors['name'] = 'No user exist';
        }
        if (!id) {
            isValid = false;
            errors['name'] = 'No case exist';
        }
        setErrors(errors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (checkValidation()) {
            setPostLoading(true)

            const requestBody = {
                comment: comment,
                case_id: id,
                user_id: user.id
            };

            createComment.create(requestBody).then((res) => {
                if (res.status === 200) {
                    toast.success(res.message);
                    resetComment();
                    setIsLoading(false)
                    setPostLoading(false)
                    // caseCommentListing.getComments(id)
                    //     .then((res) => {
                    //         if (res.data) {
                    //             const upcommingData = res.data.filter(comment => comment.tag === 'pre-op')
                    //             const publishedData = res.data.filter(comment => comment.tag === 'post-op')
                    //             seUpcommingCase(upcommingData)
                    //             setCommentData(publishedData)
                    //             setIsLoading(false)
                    //             setPostLoading(false)
                    //         }
                    //     }
                    //     ).catch(error => {
                    //         setIsLoading(false)
                    //         setPostLoading(false)
                    //         console.error('Error fetching data:', error)
                    //     }
                    // );
                }
            }
            ).catch((error) => {
                setPostLoading(false);
                toast.error(error.data.message);
                resetComment();
            });
        }
    }
    return (
        <li>
            <div className="reviewFooter">
                <div className="formWrap">
                    <form action="" className="reviewCommentForm">
                        <div className="formRow">
                            <textarea
                                name=""
                                id=""
                                className={`formControl dBlock w100  ${errors.name && 'error'}`}
                                placeholder="Add comment..."
                                {...bindComment}
                            ></textarea>
                        </div>
                        <div className="formBtnRow fontSmall">
                            <button type="submit" className="btn btnPrimary dBlock w100" onClick={handleSubmit}>
                                Comment On Case
                                {postLoading && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </li>
    )
}

export default PostComment