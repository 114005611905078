import httpClient from "../httpClient";
import { API_USER_LISTING } from "../config";

const userListing = {
  getUsers: (pageNo, params) => {
    params = params && typeof params === 'object' ? params : {};

    const queryString = Object.entries(params)
      .filter(([key, value]) => value !== '' && value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const endpoint = API_USER_LISTING + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

    return httpClient.get(endpoint);
  },
};

export default userListing;
