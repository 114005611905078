import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './api/services/auth';
import { DepartmentProvider } from './context/departmentContext';
import { AllDepartmentsProvider } from './context/allDepartmentContext';
import { NotificationProvider } from './context/NotificationsContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <NotificationProvider>
    <AllDepartmentsProvider>
      <AuthProvider>
        <DepartmentProvider>
          <App />
        </DepartmentProvider>
      </AuthProvider>
    </AllDepartmentsProvider>
  </NotificationProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
