import React, { useEffect, useState } from 'react'
import { HalfCircleSpinner } from 'react-epic-spinners';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../api/services/auth';
import { useInput } from '../../hooks/useInput';
import commentFlagCase from '../../api/services/commentFlagCase';
import { toast } from 'react-toastify';
import closeflagCase from '../../api/services/closeflagCase';
import caseStatus from '../../api/services/caseStatus';
import { userPermissions } from '../../enums';
import { getShowFlagCase } from '../../api/services/flagcase';
import Echo from "laravel-echo";
import { useNotification } from '../../context/NotificationsContext';
window.Pusher = require("pusher-js");

function SingleFlagCaseView() {
    const { id } = useParams()
    const navigate = useNavigate();
    const { options } = useNotification();
    const { permission, user } = useAuth();
    const [response, bindResponse, resetResponse] = useInput("");
    const [reopenFlagg, setReopenFlagg] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [reason, bindReason, resetReason] = useInput("");
    const [errors, setErrors] = useState({});
    const [caseDetail, setCaseDetail] = useState({})
    const [caseComments, setCaseComments] = useState([])
    const [selectedCaseBookmark, setSelectedCaseBookmark] = useState(null);
    const [selectedCaseId, setSelectedCaseId] = useState(null);
    const [loading, setLoading] = useState(true)
    const caseCommentsRef = React.useRef(caseComments);

    useEffect(() => {
        if (caseComments !== null) {
            caseCommentsRef.current = caseComments;
        }
    }, [caseComments]);

    useEffect(() => {
        getShowFlagCase.caseInfo(id).then((res) => {
            if (res.status === 200 && res.data && res.data?.length > 0) {
                setLoading(false)
                setCaseDetail(res.data?.[0])
                setCaseComments(res.data?.[0]?.comments)
            } else {
                setLoading(false)
            }
        }).catch(error => {
            setLoading(false)
            console.error('Error fetching data:', error)
        })
    }, [])

    useEffect(() => {
        if (caseDetail?.flagged_case_id) {
            const echo = new Echo(options);
            echo.private(`${caseDetail?.reference}.flag.${user.id}`)
                .listen("FlaggedCaseComment", (data) => {
                    if (!caseCommentsRef.current?.some(msg => msg.id === data.id)) {
                        setCaseComments([...caseCommentsRef.current, { ...data, first_role: data.user_type },]);
                    }
                });
        }
    }, [caseDetail]);

    const handleCloseFlag = () => {
        setShowLoader(true);
        closeflagCase.close(caseDetail.flagged_case_id)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.message);
                    setShowLoader(false);
                    window.location.reload();
                }
            }
            ).catch(error => {
                toast.error(error?.data?.message)
                console.error('Error fetching data:', error)
                setShowLoader(false);
            }
            );
    }

    const handleReply = () => {
        const requestBody = {
            flagged_case_id: caseDetail.flagged_case_id,
            comment: response,
        };

        commentFlagCase.create(requestBody)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.message);
                    resetResponse()
                }
            }
            ).catch(error => {
                toast.error(error?.data?.message || 'Unable to reply')
                console.error('Error fetching data:', error)
            }
            );
    }

    const checkValidation = () => {
        let errors = {};
        let isValid = true;

        if (!reason) {
            isValid = false;
            errors['reason'] = 'Please enter a comment.';
        }

        setErrors(errors);
        return isValid;
    };

    const handleReopenCloseFlag = (event) => {
        event.preventDefault();

        if (checkValidation()) {
            setShowLoader(true);

            const requestBody = {
                reason: reason,
                case_id: caseDetail.case_id,
                reopen: true
            };

            caseStatus.create(requestBody).then((res) => {
                if (res.status === 200) {
                    setShowLoader(false);
                    toast.success(res.message);
                    resetReason();
                    window.location.reload();
                }
            }
            ).catch((error) => {
                setShowLoader(false);
                resetReason();
                toast.error(error.data.message);
            });
        }
    }

    // Handle_Bookmark_Click
    const handleBookmarkCaseClick = (caseId, bookmark) => {
        setSelectedCaseBookmark(bookmark);
        setSelectedCaseId(caseId);
        if (bookmark === 0) {
            setSelectedCaseBookmark(1);
        } else {
            setSelectedCaseBookmark(0);
        }
    };

    return (
        <div className="dbContentWrap">
            <div>
                {loading ? <HalfCircleSpinner
                    size={18}
                    color='black'
                    style={{
                        display: "inline-block",
                        marginLeft: "7px",
                        position: "relative",
                        top: "2px",
                    }}
                /> : caseDetail?.reference ? (
                    <div className="tableWidget">
                        <article className="widgetWrap">
                            <div className="widgetHeader">
                                <span>Flagged Case <span className='textCapitalize'>{caseDetail?.reference}</span></span>
                            </div>
                            <div className="tableWrap">
                                <table className="table fontSmall textCenter w100 flagCasesTable">
                                    <thead>
                                        <tr>
                                            <th>Ref</th>
                                            <th>Date</th>
                                            <th>Consultant</th>
                                            <th>Procedure</th>
                                            <th>Comorbidities</th>
                                            <th>Complications</th>
                                            <th>Status</th>
                                            <th>Comments</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className={`${(caseDetail?.bookmark == 1) ? 'bookmarked' : ''}`}>
                                            <td className='cursorPointer' onClick={() => { navigate(`../case/${caseDetail.case_id}`) }} data-label="Ref">{caseDetail?.reference}</td>
                                            <td data-label="Date">{caseDetail?.procedure_date || '-'}</td>
                                            <td data-label="Consultant">{caseDetail?.consultant}</td>
                                            <td data-label="Procedure">{caseDetail?.procedure}</td>
                                            <td data-label="Comorbidities" data-text-alignment="left">{caseDetail.comorbidities ? Object.values(caseDetail?.comorbidities).join(', ') : '-'}</td>
                                            <td data-label="Complications" data-text-alignment="left">{caseDetail.complications ? Object.values(caseDetail?.complications).join(', ') : '-'}</td>
                                            <td data-label="Status">{caseDetail.status?.replace(/-/g, " ").replace(/\b\w/g, c => c.toUpperCase())}</td>
                                            <td data-label="Comments">
                                                {caseComments?.length}
                                                {(caseDetail?.unread_count > 0) &&
                                                    <span className="badgeNew">New<i className="icn fa-solid fa-message"></i></span>
                                                }
                                            </td>
                                            <td data-label="Actions">
                                                <div>
                                                    {permission?.some(p => (p.name === userPermissions.CASES_SHOW)) && (
                                                        <button
                                                            type="button"
                                                            onClick={() => { navigate(`../case/${caseDetail.case_id}`) }}
                                                            className="actionIcn edit fa-solid fa-eye"
                                                        ></button>
                                                    )}
                                                    {/* {permission?.some(p => (p.name === userPermissions.CASES_BOOKMARK)) && (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleBookmarkCaseClick(caseDetail?.case_id, caseDetail?.bookmark)}
                                                        className={`actionIcn bookmarkIcn ${(caseDetail.bookmark === 0) ? 'fa-light fa-star' : 'fa-solid fa-star'}`}
                                                    ></button>
                                                )} */}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="widgetFooter commentsWidget">
                                <ul className="listUnstyled commentsList flagCaseCommentBox">
                                    <li>
                                        <div className="singleComment">
                                            <div className="commentTitle">
                                                Issue Raised
                                                <span className='textCapitalize'>{caseDetail.flagged_by}</span>
                                            </div>
                                            <div className="commentText">
                                                <p>{caseDetail.reason}</p>
                                            </div>
                                        </div>
                                    </li>
                                    {(caseComments && (caseComments?.length > 0)) && caseComments?.map((item, index) => {
                                        const roleName = item.first_role.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()).replace(/\s/g, ' ');;
                                        return <li key={index}>
                                            <div className="singleComment">
                                                <div className="commentTitle textCapitalize">
                                                    {item?.first_name ? `${item.first_name} ${item.surname} ` : `${item.user}`}
                                                    <span className='textCapitalize'>{roleName} - Reply</span>
                                                </div>
                                                <div className="commentText">
                                                    <p>{item.comment}</p>
                                                </div>
                                            </div>
                                        </li>
                                    })}
                                    {(caseDetail.close_flag !== 1 && permission?.some(p => (p.name === userPermissions.FLAGGED_CASES_COMMENT))) && (
                                        <li className='commentBox'>
                                            <div className="singleComment">
                                                <div className="commentTitle">Response</div>
                                                <div className="commentText">
                                                    <textarea
                                                        className="formControl"
                                                        placeholder='Response to user...'
                                                        {...bindResponse}
                                                    >
                                                    </textarea>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                                {(caseDetail.close_flag !== 1) ? (
                                    <>
                                        {permission?.some(p => (p.name === userPermissions.FLAGGED_CASES_COMMENT)) && (
                                            <div className="replyBtn">
                                                <button className={`btn btnWhite dBlock w100`} onClick={handleReply}>Reply</button>
                                            </div>
                                        )}
                                        {permission?.some(p => (p.name === userPermissions.FLAGGED_CASES_CLOSE)) && (
                                            <div className="replyBtn">
                                                <button
                                                    type='button'
                                                    className="btn btnPrimary dBlock w100"
                                                    onClick={handleCloseFlag}
                                                    disabled={showLoader}
                                                >Close Flagged Case
                                                    {showLoader && (
                                                        <HalfCircleSpinner
                                                            size={18}
                                                            style={{
                                                                display: "inline-block",
                                                                marginLeft: "7px",
                                                                position: 'relative',
                                                                top: '2px',
                                                            }}
                                                        />
                                                    )}
                                                </button>
                                            </div>
                                        )}
                                    </>
                                ) :
                                    <>
                                        {permission?.some(p => (p.name === userPermissions.FLAGGED_CASES_CLOSE)) && (
                                            <>
                                                {reopenFlagg ?
                                                    <form className='reopenFlagComentBox' onSubmit={(event) => handleReopenCloseFlag(event)}>
                                                        <div className='flagCaseCommentBox'>
                                                            <div className="singleComment">
                                                                <div className="commentTitle">Comment</div>
                                                                <div className="commentText">
                                                                    <textarea
                                                                        className={`formControl ${errors.reason && 'error'}`}
                                                                        placeholder='Add a comment...'
                                                                        {...bindReason}
                                                                    >
                                                                    </textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {permission?.some(p => (p.name === userPermissions.FLAGGED_CASES_COMMENT)) && (
                                                            <div className="replyBtn replyBtns">
                                                                <button className="btn btnPrimary" onClick={() => setReopenFlagg(false)}>Cancel</button>
                                                                <button
                                                                    type='submit'
                                                                    className="btn btnWhite"
                                                                    disabled={showLoader}
                                                                >Re-Open
                                                                    {showLoader && (
                                                                        <HalfCircleSpinner
                                                                            size={18}
                                                                            style={{
                                                                                display: "inline-block",
                                                                                marginLeft: "7px",
                                                                                position: 'relative',
                                                                                top: '2px',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </form>
                                                    :
                                                    <div className="replyBtn">
                                                        <button
                                                            type='button'
                                                            className="btn btnPrimary dBlock w100"
                                                            onClick={() => setReopenFlagg(true)}
                                                        >Reopen Closed Flagg
                                                        </button>
                                                    </div>
                                                }
                                            </>
                                        )}
                                    </>
                                }
                            </div>
                        </article>
                    </div>
                ) : 'No data found'}
            </div>

        </div>
    )
}

export default SingleFlagCaseView