import React from 'react'

function SearchUserList({ userList, setShowTransferCase, setTransferUser }) {
    return (
        <div className="tableWidget">
            <div className="tableWrap mt2">
                <table className="table tableSecondary txtWhite fontSmall textCenter w100">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>First name</th>
                            <th>Surname</th>
                            <th>Position</th>
                            <th>Email</th>
                            <th>Contact info</th>
                            <th>Profile image</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userList && userList.map((item, index) => (
                            <tr key={index}>
                                <td data-label="Username">{item.username}</td>
                                <td data-label="First name">{item.first_name}</td>
                                <td data-label="Surname">{item.surname}</td>
                                <td data-label="Position" data-text-alignment="left">{item.position}</td>
                                <td data-label="Email" data-text-alignment="left">{item.email}</td>
                                <td data-label="Contact info" data-text-alignment="left">{item.phone_number}</td>
                                <td data-label="Profile image">
                                    <div className="userImageWrap">
                                        {item.image ?
                                            <img
                                                src={item.image}
                                                className="imgFluid"
                                                alt="image description"
                                                onContextMenu={(e) => e.preventDefault()}
                                            // onError={(e) => { 
                                            //     handleImageError();
                                            //     e.target.onerror = null; 
                                            //     e.target.src =`${Placeholder}` 
                                            // }}
                                            />
                                            :
                                            <div className="placeholderImage">?</div>
                                        }
                                    </div>
                                </td>
                                <td data-label="Action">
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setShowTransferCase(false)
                                                setTransferUser(item)
                                            }}
                                            className="btn btnWhite btnPrimaryOutline noRadius btnSmall"
                                        >Assign Cases</button>
                                    </div>
                                </td>
                            </tr>
                        )
                        )}
                    </tbody>
                </table>
            </div >
        </div >
    )
}

export default SearchUserList