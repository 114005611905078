
import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { notificationListing, createNotification } from "../../api/services/notifications-services";
import { useAuth } from "../../api/services/auth";
import { useInput } from '../../hooks/useInput';
import { MultiSelect } from "react-multi-select-component";
import { userRoles } from "../../enums";
import getDepartmentsList from '../../api/services/userDepartmentsList'

const CreateNotifications = (props) => {
    const [notificationMessage, bindNotificationText, resetNotificationText] = useInput("");
    const { userRole } = useAuth()
    const [showSendNotification, setShowSendNotification] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [errors, setErrors] = useState({});
    const [department, setDepartment] = useState([]);
    const [departmentList, setDepartmentList] = useState([])

    //fetch_department_data
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        const loggedInUser = localStorage.getItem('loggedInUser');
        const parsedUser = JSON.parse(loggedInUser);

        if (parsedUser.id) {
            getDepartmentsList.get(parsedUser.id)
                .then((res) => {
                    setDepartmentList(res?.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    })))
                    if (res?.data?.length === 1) {
                        setDepartment(res?.data[0])
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [])

    // Check_Validation_Function
    const checkValidation = () => {
        let errors = {};
        let isValid = true;
        if (departmentList?.length > 1 && (!department || department.length < 1)) {
            isValid = false;
            errors['department'] = 'Please select atleast 1 department to send a notification.';
            toast.error('Please select atleast 1 department to send a notification.')
        }
        if (!notificationMessage) {
            isValid = false;
            errors['notificationMessage'] = 'Please enter your Username.';
        }
        setErrors(errors);
        return isValid;
    };

    // Handle_Delete_Click
    const handleSendNotificationClick = () => {
        setShowSendNotification(true);
    };

    // Handle_Create_Notification
    const handleSendNotification = (event) => {
        event.preventDefault();

        if (checkValidation()) {
            setShowLoader(true);
            let requestBody = {}
            if (departmentList?.length > 1) {
                requestBody = {
                    content: notificationMessage,
                    department_ids: department?.map(dept => dept.value)
                };
            } else {
                requestBody = {
                    content: notificationMessage,
                };
            }
            createNotification.create((requestBody))
                .then((res) => {
                    if (res.status === 200) {
                        setShowLoader(false);
                        toast.success(res.message);
                        resetNotificationText('')
                        setDepartment([])
                        notificationListing.get()
                            .then((res) => {
                                props.setNotificationsList(res?.data);
                            }
                            ).catch(error => console.error('Error fetching data:', error));
                    }
                }
                ).catch((error) => {
                    setShowLoader(false);
                    toast.error(error.data.message);
                }
                );
        }
    }

    // Handle_Department_Select
    const handleDepartmentSelect = (options) => {
        setDepartment(options)
    }

    const customValueRenderer = (selected, _options) => {
        return selected.length
            ? 'View Selected Department...'
            : "Choose Department";
    };

    return (
        <>
            <form className="sendNotificationForm" onSubmit={(event) => handleSendNotification(event)}>
                <article className="widgetWrap notifWidget sendNotiBox">
                    <div className="widgetHeader">
                        <span>Create Notification</span>
                    </div>
                    <div className="widgetFooter">
                        <div className="notifBody">
                            <textarea
                                className={`formControl ${errors.notificationMessage && 'error'}`}
                                {...bindNotificationText}
                            ></textarea>
                        </div>
                        <div className="notifActions">
                            {showSendNotification ?
                                <>
                                    {departmentList.length > 1 && (
                                        <div className="multiSelectWrap">
                                            <MultiSelect
                                                options={departmentList}
                                                value={department}
                                                onChange={handleDepartmentSelect}
                                                labelledBy="Choose Department"
                                                className="multi-tag-select w100"
                                                hasSelectAll={userRole === userRoles.SUPER_USER || false}
                                                valueRenderer={customValueRenderer}
                                                hideSelectedOptions={true}
                                            />
                                        </div>
                                    )}
                                    <div className="btnWrap">
                                        <button
                                            type="button"
                                            className="btn btnWhite"
                                            onClick={() => {
                                                setShowSendNotification(false)
                                                resetNotificationText('')
                                                setDepartment([])
                                            }}
                                        >Cancel</button>
                                        <button
                                            type="submit"
                                            className="btn btnPrimary"
                                            disabled={showLoader}
                                        >Send Now
                                            {showLoader && (
                                                <HalfCircleSpinner
                                                    size={18}
                                                    style={{
                                                        display: "inline-block",
                                                        marginLeft: "7px",
                                                        position: 'relative',
                                                        top: '2px',
                                                    }}
                                                />
                                            )}
                                        </button>
                                    </div>
                                </>
                                :
                                <button
                                    onClick={handleSendNotificationClick}
                                    type="button"
                                    className="btn btnSecondary w100 dBlock"
                                >Send Notification</button>
                            }
                        </div>
                    </div>
                </article>
            </form>
        </>
    );
}

export default CreateNotifications;