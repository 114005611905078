
import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from 'react-epic-spinners';
import Pagination from "../../components/pagination";
import comorbidityListing from "../../api/services/comorbidityListing";
import deleteComorbidity from "../../api/services/deleteComorbidity";
import CreateComorbidities from "../../components/comorbidity/createComorbidity";
import UpdateComorbidityInfo from "../../components/comorbidity/updateComorbidityInfo";
import { useAuth } from "../../api/services/auth";
import { Navigate } from "react-router-dom";
import { userPermissions } from "../../enums";

const Comorbidities = (props) => {
    const { userExist, permission } = useAuth();
    const [comorbidityList, setComorbidityList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [createComorbidity, setShowCreateComorbidity] = useState(false);
    const [showUpdateComorbidity, setShowUpdateComorbidity] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedComorbidity, setSelectedComorbidity] = useState({});
    const [selectedComorbidityId, setSelectedComorbidityId] = useState(null);
    const [loading, setLoading] = useState(true);


    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Comorbidity Management | Cappagh";
    }, []);

    // Get_Comorbidities_Listing
    useEffect(() => {
        comorbidityListing.getComorbidities(1)
            .then((res) => {
                setComorbidityList(res.data);
                setPagination(res.meta);
                setLoading(false)

            }
            ).catch(error => {
                setLoading(false)
                console.error('Error fetching data:', error)
            }
            );
    }, [selectedComorbidityId]);


    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        comorbidityListing.getComorbidities(pageNo)
            .then((res) => {
                handleScrollToTop()
                setComorbidityList(res.data);
                setPagination(res.meta);
            }
            ).catch(error => console.error('Error fetching data:', error));
    };

    // Handle_Show_Update_Comorbidity
    const handleShowUpdateComorbidity = () => {
        setShowUpdateComorbidity(!showUpdateComorbidity)
    }

    // Handle_Delete_Comorbidity_Click
    const handleDeleteClick = (userId) => {
        setSelectedComorbidityId(userId);
    };

    // Handle_Delete_Comorbidity
    const handleDeleteComorbidity = () => {
        setShowLoader(true);

        deleteComorbidity.delete(selectedComorbidityId)
            .then((res) => {
                setShowLoader(false);
                toast.success(res.message);

                comorbidityListing.getComorbidities(1)
                    .then((res) => {
                        setComorbidityList(res.data);
                        setPagination(res.meta);
                    }
                    ).catch(error => console.error('Error fetching data:', error));
            }
            ).catch((error) => {
                setShowLoader(false);
                toast.error(error.data.message);
            }
            );
        setSelectedComorbidityId(null);
    };

    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <>
            <div className="dbContentWrap">
                {/* Create_Comorbidity */}
                {createComorbidity &&
                    <CreateComorbidities
                        createComorbidity={createComorbidity}
                        setShowCreateComorbidity={setShowCreateComorbidity}
                        setComorbidityList={setComorbidityList}
                        setPagination={setPagination}
                    />
                }

                {/* Update_Comorbidity_Info */}
                {showUpdateComorbidity &&
                    <UpdateComorbidityInfo
                        setShowUpdateComorbidity={setShowUpdateComorbidity}
                        selectedComorbidity={selectedComorbidity}
                        handleShowUpdateComorbidity={handleShowUpdateComorbidity}
                        setComorbidityList={setComorbidityList}
                        setPagination={setPagination}
                    />
                }

                {/* Comorbidities_Listing */}
                <div className="tableWidget">
                    <article className="widgetWrap">
                        <div className="widgetHeader">
                            <span>Comorbidity Management</span>
                            <div className="tabHeadActionWrap">
                                {permission?.some(p => (p.name === userPermissions.COMORBIDITIES_CREATE)) && (
                                    <button onClick={() => {
                                        handleScrollToTop();
                                        setShowCreateComorbidity(true);
                                        setShowUpdateComorbidity(false);
                                    }} type="button" className={`actionBtn ${createComorbidity && 'active'}`}>Add Comorbidity <i className="fa-solid fa-user-plus"></i></button>
                                )}
                            </div>
                        </div>
                        <div className="tableWrap">
                            <table className="table fontSmall textCenter w100">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(comorbidityList && (comorbidityList?.length > 0)) ? comorbidityList.map(item => (
                                        <tr key={item.id}>
                                            <td data-label="Name">{item.name}</td>
                                            <td data-label="Status">{item.status == '1' ? "Active" : "Inactive"}</td>
                                            <td data-label="Actions">
                                                <div>
                                                    {permission?.some(p => (p.name === userPermissions.COMORBIDITIES_UPDATE)) && (
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleScrollToTop();
                                                                setShowUpdateComorbidity(true);
                                                                setShowCreateComorbidity(false);
                                                                setSelectedComorbidity({ ...item });
                                                            }}
                                                            className="actionIcn edit fa-solid fa-pencil"
                                                        ></button>
                                                    )}
                                                    {permission?.some(p => (p.name === userPermissions.COMPLICATIONS_DELETE)) && (
                                                        <button
                                                            onClick={() => {
                                                                handleDeleteClick(item.id)
                                                            }}
                                                            type="button"
                                                            className="actionIcn delete fa-solid fa-trash"
                                                        ></button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )) : loading ?
                                        <tr>
                                            <td colSpan={9}><HalfCircleSpinner
                                                size={18}
                                                color='black'
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                            /></td>
                                        </tr> :
                                        <tr>
                                            <td colSpan={3}>No record found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="widgetFooter">
                            <div className="textCenter">
                                {pagination && (Object.keys(pagination)?.length > 0) && (
                                    <Pagination pagination={pagination} handlePageClick={handlePageClick} />
                                )}
                            </div>
                        </div>
                    </article>
                </div>
            </div>
            {/* Delete_User_Popup */}
            <Popup className="deleteUserModal" open={selectedComorbidityId !== null} onClose={() => setSelectedComorbidityId(null)}>
                {selectedComorbidityId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to delete this comorbidity.</p>
                        <div className="btnWrap">
                            <button onClick={() => setSelectedComorbidityId(null)} type="button" className="btn btnSecondary">Cancel</button>
                            <button
                                onClick={handleDeleteComorbidity}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Delete
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    );
}

export default Comorbidities;