
import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from 'react-epic-spinners';
import Pagination from "../../components/pagination";
import groupListing from "../../api/services/groupListing";
import CreateGroup from "../../components/group/createGroup";
import UpdateGroupInfo from "../../components/group/updateGroupInfo";
import deleteGroup from "../../api/services/deleteGroup";
import { useAuth } from "../../api/services/auth";
import { Navigate } from "react-router-dom";
import { userPermissions } from "../../enums";

const Groups = (props) => {
    const { userExist, permission } = useAuth()
    const [groupList, setGroupList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [showUpdateGroup, setShowUpdateGroup] = useState(false);
    const [createGroup, setShowCreateGroup] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedGroupId, setSelectedGroupId] = useState(null);

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Group Management | Cappagh";
    }, []);

    // Handle_Scroll_Ref_To_Top
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        groupListing.getGroups(pageNo)
            .then((res) => {
                handleScrollToTop()
                setGroupList(res.data);
                setPagination(res.meta);
            }
            ).catch(error => console.error('Error fetching data:', error));
    };

    // Handle_Show_Update_Group
    const handleShowUpdateGroup = () => {
        setShowUpdateGroup(!showUpdateGroup)
    }

    // Handle_Delete_Click
    const handleDeleteClick = (groupId) => {
        setSelectedGroupId(groupId);
    };

    // Handle_Delete_Group
    const handleDeleteGroup = () => {
        setShowLoader(true);

        deleteGroup.delete(selectedGroupId)
            .then((res) => {
                setShowLoader(false);
                toast.success(res.message);

                groupListing.getGroups(1)
                    .then((res) => {
                        setGroupList(res.data);
                        setPagination(res.meta);
                    }
                    ).catch(error => console.error('Error fetching data:', error));

            }).catch((error) => {
                setShowLoader(false);
                toast.error(error.data.message);
            }
            );
        setSelectedGroupId(null);
    };

    // Get_Users_Listing
    useEffect(() => {
        groupListing.getGroups(1)
            .then((res) => {
                setLoading(false)
                setGroupList(res.data);
                setPagination(res.meta);
            }
            ).catch(error => {
                setLoading(false)
                console.error('Error fetching data:', error)
            }
            );
    }, [selectedGroupId]);

    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <>
            <div className="dbContentWrap">
                {/* Create_User_Form */}
                {!showUpdateGroup && createGroup &&
                    <CreateGroup
                        createGroup={createGroup}
                        setShowCreateGroup={setShowCreateGroup}
                        setGroupList={setGroupList}
                        setPagination={setPagination}
                    />
                }

                {/* Update_UserInfo_Form */}
                {!createGroup && showUpdateGroup &&
                    <UpdateGroupInfo
                        setShowUpdateGroup={setShowUpdateGroup}
                        selectedGroup={selectedGroup}
                        handleShowUpdateGroup={handleShowUpdateGroup}
                        setGroupList={setGroupList}
                        setPagination={setPagination}
                    />
                }
                {/* Users_Listing */}
                <div className="tableWidget">
                    <article className="widgetWrap">
                        <div className="widgetHeader">
                            <span>Group Management</span>
                            <div className="tabHeadActionWrap">
                                {permission?.some(p => (p.name === userPermissions.GROUPS_CREATE)) && (
                                    <button onClick={() => {
                                        handleScrollToTop();
                                        setShowCreateGroup(true);
                                        setShowUpdateGroup(false);
                                    }} type="button" className={`actionBtn ${createGroup && 'active'}`}>Add Group <i className="fa-solid fa-user-plus"></i></button>
                                )}
                            </div>
                        </div>
                        <div className="tableWrap">
                            <table className="table fontSmall textCenter w100">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(groupList && (groupList?.length > 0)) ? groupList.map(item => (
                                        <tr key={item.id}>
                                            <td data-label="Name" className="textCapitalize">{item.name}</td>
                                            <td data-label="Status">{item.status == '1' ? "Active" : "Inactive"}</td>

                                            <td data-label="Actions">
                                                <div>
                                                    {permission?.some(p => (p.name === userPermissions.GROUPS_UPDATE)) && (
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleScrollToTop();
                                                                setShowUpdateGroup(true);
                                                                setShowCreateGroup(false);
                                                                setSelectedGroup({ ...item });
                                                            }}
                                                            className="actionIcn edit fa-solid fa-pencil"
                                                        ></button>
                                                    )}
                                                    {permission?.some(p => (p.name === userPermissions.GROUPS_DELETE)) && (
                                                        <button
                                                            onClick={() => handleDeleteClick(item.id)}
                                                            type="button"
                                                            className="actionIcn delete fa-solid fa-trash"
                                                        ></button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )) : loading ?
                                        <tr>
                                            <td colSpan={9}><HalfCircleSpinner
                                                size={18}
                                                color='black'
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                            /></td>
                                        </tr> :
                                        <tr>
                                            <td colSpan={8}>No record found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="widgetFooter">
                            <div className="textCenter">
                                {pagination && (Object.keys(pagination)?.length > 0) && (
                                    <Pagination pagination={pagination} handlePageClick={handlePageClick} />
                                )}
                            </div>
                        </div>
                    </article>
                </div>
            </div>

            {/* Delete_User_Popup */}
            <Popup className="deleteUserModal" open={selectedGroupId !== null} onClose={() => setSelectedGroupId(null)}>
                {selectedGroupId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to delete this Group.</p>
                        <div className="btnWrap">
                            <button onClick={() => setSelectedGroupId(null)} type="button" className="btn btnSecondary">Cancel</button>
                            <button
                                onClick={handleDeleteGroup}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Delete
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    );
}

export default Groups;