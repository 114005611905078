import { API_CASE_LOGS_LISTING, API_CASE_LOGS_SHOW } from "../../../config";
import httpClient from "../../../httpClient";

// export const caseLogLisiting = {
//     getLogs: (pageNo) => httpClient.get(API_CASE_LOGS_LISTING + `?page=${pageNo ?? '1'}`),
// };

export const caseLogShow = {
    getLogs: (id, pageNo) => httpClient.get(API_CASE_LOGS_SHOW + '/' + id + `?page=${pageNo ?? '1'}`),
};


export const caseLogLisiting = {
    getLogs: (params) => {
        params = params && typeof params === 'object' ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== '' && value !== null)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const endpoint = API_CASE_LOGS_LISTING + `${queryString ? `?${queryString}` : ''}`;

        return httpClient.get(endpoint);
    },
};
