import React from "react";
import { useAuth } from "../../../api/services/auth";
import { userRoles } from "../../../enums";
import AssistantNav from './assistantNav';
import ConsultantNav from './consultantNav';
import HodNav from './hodNav';
import AdminNav from "./adminNav";

const Sidebar = (props) => {
    const { permission, user } = useAuth();

    return (
        <nav className="sideNavbar">
            <div className="navbarCollapse" id="navbarCollapse">
                <ul className="listUnstyled navbarNav">
                    {
                        user.type === userRoles.ASSISTANT ?
                            <AssistantNav permission={permission} /> :
                        user.type === userRoles.CONSULTANT ?
                            <ConsultantNav /> :
                        user.type === userRoles.HOD ?
                            <HodNav /> : 
                        user.type === userRoles.SUPER_USER &&
                            <AdminNav />
                    }
                </ul>
            </div>
        </nav>
    );
}

export default Sidebar;