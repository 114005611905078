import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from 'react-epic-spinners';
import Pagination from "../../components/pagination";
import { departmentListing, deleteDepartment } from "../../api/services/departmnent-services";
import CreateDepartment from "../../components/department-components/createDepartment";
import UpdateDepartment from "../../components/department-components/updateDepartment";
import { useAuth } from "../../api/services/auth";
import { Navigate } from "react-router-dom";
import { userPermissions } from "../../enums";

const Departments = (props) => {
    const { userExist, permission } = useAuth();
    const [departmentList, setDepartmentList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [createDepartment, setShowCreateDepartment] = useState(false);
    const [showUpdateDepartment, setShowUpdateDepartment] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState({});
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
    const [loading, setLoading] = useState(true);

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Department Management | Cappagh";
    }, []);

    // Get_Departments_Listing
    useEffect(() => {
        departmentListing.list(1)
            .then((res) => {
                setDepartmentList(res.data);
                setPagination(res.meta);
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                console.error('Error fetching data:', error)
            }
            );
    }, [selectedDepartmentId]);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        departmentListing.list(pageNo)
            .then((res) => {
                handleScrollToTop()
                setDepartmentList(res.data);
                setPagination(res.meta);
            }
            ).catch(error => console.error('Error fetching data:', error));
    };

    // Handle_Show_Update_Department
    const handleShowUpdateDepartment = () => {
        setShowUpdateDepartment(!showUpdateDepartment)
    }

    // Handle_Delete_Department_Click
    const handleDeleteClick = (userId) => {
        setSelectedDepartmentId(userId);
    };

    // Handle_Delete_Department
    const handleDeleteDepartment = () => {
        setShowLoader(true);

        deleteDepartment.delete(selectedDepartmentId)
            .then((res) => {
                setShowLoader(false);
                toast.success(res.message);

                departmentListing.list(1)
                    .then((res) => {
                        setDepartmentList(res.data);
                        setPagination(res.meta);
                    }
                    ).catch(error => console.error('Error fetching data:', error));
            }).catch((error) => {
                setShowLoader(false);
                toast.error(error.data.message);
            }
            );
        setSelectedDepartmentId(null);
    };

    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <>
            <div className="dbContentWrap">
                {/* Create_Department */}
                {createDepartment &&
                    <CreateDepartment
                        createDepartment={createDepartment}
                        setShowCreateDepartment={setShowCreateDepartment}
                        setDepartmentList={setDepartmentList}
                        setPagination={setPagination}
                    />
                }

                {/* Update_Department_Info */}
                {showUpdateDepartment &&
                    <UpdateDepartment
                        setShowUpdateDepartment={setShowUpdateDepartment}
                        selectedDepartment={selectedDepartment}
                        handleShowUpdateDepartment={handleShowUpdateDepartment}
                        setDepartmentList={setDepartmentList}
                        setPagination={setPagination}
                    />
                }

                {/* Departments_Listing */}
                <div className="tableWidget">
                    <article className="widgetWrap">
                        <div className="widgetHeader">
                            <span>Department Management</span>
                            <div className="tabHeadActionWrap">
                                {permission?.some(p => (p.name === userPermissions.DEPARTMENTS_CREATE)) && (
                                    <button onClick={() => {
                                        handleScrollToTop();
                                        setShowCreateDepartment(true);
                                        setShowUpdateDepartment(false);
                                    }} type="button" className={`actionBtn ${createDepartment && 'active'}`}>Add Department <i className="fa-solid fa-user-plus"></i></button>
                                )}
                            </div>
                        </div>
                        <div className="tableWrap">
                            <table className="table fontSmall textCenter w100">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(departmentList && (departmentList?.length > 0)) ? departmentList.map(item => (
                                        <tr key={item.id}>
                                            <td data-label="Name" className="textCapitalize">{item.name}</td>
                                            <td data-label="Status">{item.status == '1' ? "Active" : "Inactive"}</td>
                                            <td data-label="Actions">
                                                <div>
                                                    {permission?.some(p => (p.name === userPermissions.DEPARTMENTS_UPDATE)) && (
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleScrollToTop();
                                                                setShowUpdateDepartment(true);
                                                                setShowCreateDepartment(false);
                                                                setSelectedDepartment({ ...item });
                                                            }}
                                                            className="actionIcn edit fa-solid fa-pencil"
                                                        ></button>
                                                    )}
                                                    {permission?.some(p => (p.name === userPermissions.DEPARTMENTS_DELETE)) && (
                                                        <button
                                                            onClick={() => handleDeleteClick(item.id)}
                                                            type="button"
                                                            className="actionIcn delete fa-solid fa-trash"
                                                        ></button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )) : loading ?
                                        <tr>
                                            <td colSpan={3}><HalfCircleSpinner
                                                size={18}
                                                color='black'
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                            /></td>
                                        </tr> :
                                        <tr>
                                            <td colSpan={3}>No record found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="widgetFooter">
                            <div className="textCenter">
                                {pagination && (Object.keys(pagination)?.length > 0) && (
                                    <Pagination pagination={pagination} handlePageClick={handlePageClick} />
                                )}
                            </div>
                        </div>
                    </article>
                </div>
            </div>

            {/* Delete_Department_Popup */}
            <Popup className="deleteUserModal" open={selectedDepartmentId !== null} onClose={() => setSelectedDepartmentId(null)}>
                {selectedDepartmentId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to delete this department.</p>
                        <div className="btnWrap">
                            <button onClick={() => setSelectedDepartmentId(null)} type="button" className="btn btnSecondary">Cancel</button>
                            <button
                                onClick={handleDeleteDepartment}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Delete
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    );
}

export default Departments;