import { API_PASSWORD_REQUEST, API_PASSWORD_SET, API_REQUEST_PASSWORD_USER_LISTING } from "../../config";
import httpClient from "../../httpClient";

export const requestNewPassword = {
    request: (data) => {
        return httpClient.post(`${API_PASSWORD_REQUEST}`, data);
    },
};

export const setNewPassword = {
    set: (data) => {
        return httpClient.post(`${API_PASSWORD_SET}`, data);
    },
};

export const requestPasswordUserListing = {
    getUsers: (pageNo, params) => {
        params = params && typeof params === 'object' ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== '' && value !== null)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const endpoint = API_REQUEST_PASSWORD_USER_LISTING + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

        return httpClient.get(endpoint);
    },
};