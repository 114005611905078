import { API_COMMENT_LISTING } from "../config";
import httpClient from "../httpClient";

const caseCommentListing = {
    getComments: (id) => {
        return httpClient.get(API_COMMENT_LISTING + '/' + id);
    },
};

export default caseCommentListing;
