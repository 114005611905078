import {
    API_CASE_RECREATE,
    API_COMORBIDITY_RECREATE,
    API_COMPLICATION_RECREATE,
    API_POSITION_RECREATE,
    API_USER_RECREATE,
} from "../../../config";
import httpClient from "../../../httpClient";

export const recreateComorbidity = {
    recreate: (id) => httpClient.get(API_COMORBIDITY_RECREATE + "/" + id),
};

export const recreateComplication = {
    recreate: (id) => httpClient.get(API_COMPLICATION_RECREATE + "/" + id),
};

export const recreatePosition = {
    recreate: (id) => httpClient.get(API_POSITION_RECREATE + "/" + id),
};

export const recreateUser = {
    recreate: (id) => httpClient.get(API_USER_RECREATE + "/" + id),
};

export const recreateCase = {
    recreate: (id) => httpClient.get(API_CASE_RECREATE + "/" + id),
};
