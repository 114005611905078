import React, { useEffect } from 'react'
import { useAuth } from '../../api/services/auth'
import { Navigate } from 'react-router-dom'
import Directory from '../../components/case-directory/Directory'

function CaseDirectory() {
    const { userExist } = useAuth();

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Case Directory | Cappagh";
    }, []);

    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <>
            <Directory/>
        </>
    )
}

export default CaseDirectory