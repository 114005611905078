import React, { useState } from 'react';
import { useInput } from '../hooks/useInput';
import { HalfCircleSpinner } from 'react-epic-spinners';
import { toast } from 'react-toastify';
import { requestNewPassword } from '../api/services/password';

const ForgotPasswordForm = (props) => {
    const [email, bindEmail] = useInput("");
    const [errors, setErrors] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [show, setShow] = useState(false);

    const validateForgot = () => {
        let errors = {};
        let isValid = true;
        if (!email) {
            isValid = false;
            errors["email"] = "Please enter your Email.";
            toast.error('Please enter your email to reset password.');
        }
        setErrors(errors);
        return isValid;
    };

    const forgotPasswordHandler = (event) => {
        event.preventDefault();
        let errors = {}
        if (validateForgot()) {
            setShowLoader(true);
            requestNewPassword.request({ email }).then(res => {
                setShowLoader(false);
                toast.success(res.message);
                props.setShowForgotPasswordForm(false)
            }).catch(err => {
                setShowLoader(false);
                toast.error(err.data.message);
            })
        }
    };

    return (
        <form className={`loginForm ${showLoader && 'formDisable'}`} onSubmit={(event) => forgotPasswordHandler(event)}>
            <div className="formRow">
                <div className="inputWrap">
                    <input
                        type="email"
                        className={`formControl ${errors.email && 'error'}`}
                        placeholder="Email"
                        {...bindEmail}
                    />
                </div>
            </div>
            <div className="formBtnRow textCenter fontSmall">
                <button
                    type="submit"
                    className="btn btnPrimary dBlock w100"
                    disabled={showLoader}
                    onClick={() => setShow()}
                >Reset Password
                    {showLoader && (
                        <HalfCircleSpinner
                            size={18}
                            style={{
                                display: "inline-block",
                                marginLeft: "7px",
                                position: 'relative',
                                top: '2px',
                            }}
                        />
                    )}
                </button>
                <button
                    type='button'
                    className='btnLink'
                    onClick={() => {
                        props.setShowForgotPasswordForm(false)
                    }}
                >Back to Login</button>
            </div>
        </form>
    )
}

export default ForgotPasswordForm;