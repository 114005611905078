import React, { useState, useEffect } from "react";
import { useInput } from "../../hooks/useInput";
import { toast } from "react-toastify";
import { HalfCircleSpinner } from "react-epic-spinners";
import { SearchSelect } from "../searchSelect";
import { colourStyles } from "../../data/index";
import { useAllDepartments } from "../../context/allDepartmentContext";
import positionListing from "../../api/services/positionListing";
import { positionUpdate } from "../../api/services/positions";
import Popup from "reactjs-popup";
import { recreatePosition } from "../../api/services/departmnent-services/recreate";

const UpdatePositionInfo = (props) => {
    const { departmentListing } = useAllDepartments();
    const [name, bindName] = useInput(props.selectedPosition.name);
    const [status, setStatus] = useState(props.selectedPosition.status);
    const [errors, setErrors] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [department, setDepartment] = useState(
        props.selectedPosition.departments &&
            Object.keys(props.selectedPosition.departments)?.length > 0
            ? Object.entries(props.selectedPosition.departments).map(
                ([key, value]) => ({ label: value, value: parseInt(key) })
            )
            : []
    );
    const [loader, setLoader] = useState(false);
    const [flag, setFlag] = useState("");

    useEffect(() => {
        setStatus(props.selectedPosition.status);
        setDepartment(
            props.selectedPosition.departments &&
                Object.keys(props.selectedPosition.departments)?.length > 0
                ? Object.entries(props.selectedPosition.departments).map(
                    ([key, value]) => ({ label: value, value: parseInt(key) })
                )
                : []
        );
    }, [props.selectedPosition]);

    // Check_Validation_Function
    const checkValidation = () => {
        let errors = {};
        let isValid = true;

        if (!name) {
            isValid = false;
            errors["name"] = "Please enter your name.";
        }
        // if (!department || (department?.length < 1)) {
        //     isValid = false;
        //     errors['department'] = 'Please select a Department.';
        // }
        if (status === null || status === undefined || status === "") {
            isValid = false;
            errors["status"] = "Please select status.";
        }
        setErrors(errors);
        return isValid;
    };

    const handleUpdate = (event) => {
        event.preventDefault();

        if (checkValidation()) {
            setShowLoader(true);

            const requestBody = {
                name: name,
                status: status,
                department_ids_update: department.map((dept) => dept.value),
            };

            positionUpdate
                .updateInfo(props.selectedPosition.id, requestBody)
                .then((res) => {
                    if (res.status === 200) {
                        setShowLoader(false);
                        toast.success(res.message);
                        props.handleShowUpdateUser();
                        // window.location.reload();

                        positionListing
                            .getPositions(1)
                            .then((res) => {
                                props.setPositionsList([...res.data]);
                                props.setPagination(res.meta);
                            })
                            .catch((error) =>
                                toast.error("Error While Fetching The Procedure List")
                            );
                    }
                })
                .catch((error) => {
                    setShowLoader(false);
                    if (error?.data?.errors?.id) {
                        setFlag(error.data.message);
                    } else {
                        toast.error(error.data.errors.name[0]);
                    }
                });
        }
    };

    // Handle_Status_Select
    const handleStatusSelect = (event) => {
        setStatus(event.target.value);
    };

    // Handle_Department_Select
    const handleDepartmentSelect = (event) => {
        setDepartment(event);
    };

    // Restore_Position
    const restorPositionEntity = () => {
        setLoader(true);
        recreatePosition
            .recreate(flag)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.message);
                    setLoader(false);
                    positionListing
                        .getPositions(1)
                        .then((res) => {
                            props.handleShowUpdateUser();
                            props.setPositionsList([...res.data]);
                            props.setPagination(res.meta);
                        })
                        .catch((error) =>
                            toast.error("Error While Fetching The Position List")
                        );
                }
            })
            .catch((error) => {
                setLoader(false);
                toast.error(error.data.message);
            });
    };

    return (
        <>
            <form
                className="userForms updateUserForm"
                onSubmit={(event) => handleUpdate(event)}
            >
                <article className="widgetWrap">
                    <div className="widgetBody">
                        <div className="dbRow">
                            <div className="dbCol dbColLg75">
                                <div className="dbRow">
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Name</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.name && "error"}`}
                                                    {...bindName}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Status</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="status"
                                                    className={`formControl ${errors.status && "error"}`}
                                                    id="status"
                                                    value={status}
                                                    onChange={handleStatusSelect}
                                                >
                                                    <option value="" defaultValue>
                                                        Select Status
                                                    </option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="dbCol dbColLg50">
                                    <div className="formRow">
                                        <label className="txtLabel">Department</label>
                                        <div className="selectWrap posRelative">
                                            <SearchSelect
                                                name="department"
                                                className={`basic-multi-select multiSelectDropdown txtRight ${errors.department && 'error'}`}
                                                id="department"
                                                classNamePrefix="select"
                                                styles={colourStyles}
                                                options={departmentListing}
                                                value={department}
                                                onChange={(event) => {
                                                    handleDepartmentSelect(event)
                                                }}
                                                isMulti
                                                placeholder="Select Department"
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="btnWrap">
                            <button
                                onClick={() => {
                                    props.handleShowUpdateUser();
                                }}
                                type="button"
                                className="btn btnWhite"
                            >
                                Cancel
                            </button>
                            <button type="submit" className="btn btnPrimary">
                                Update{" "}
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: "relative",
                                            top: "2px",
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                </article>
            </form>

            {/* Restore_Position_Popup */}
            <Popup
                className="restoreDepartmentModal deleteUserModal"
                open={flag !== ""}
                onClose={() => setFlag("")}
            >
                <div className="modal textCenter">
                    <p>Position exists with the same name. Do you want to restore it?</p>
                    <div className="btnWrap">
                        <button
                            onClick={() => setFlag("")}
                            type="button"
                            className="btn btnSecondary"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={restorPositionEntity}
                            type="button"
                            className="btn btnPrimary"
                            disabled={loader}
                        >
                            Restore
                            {loader && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: "relative",
                                        top: "2px",
                                    }}
                                />
                            )}
                        </button>
                    </div>
                </div>
            </Popup>
        </>
    );
};

export default UpdatePositionInfo;
