import React, { useEffect, useState } from "react";
import getSingleCaseInfo from "../../api/services/singleCase";
import { Navigate, useParams } from "react-router-dom";
import CaseComentsListing from "../../components/case-comments/caseComentsListing";
import { useAuth } from "../../api/services/auth";
import CaseAction from "../../components/single-case-view/caseAction";
import { userPermissions } from "../../enums";
import ImagesSlider from "../../components/single-case-view/imagesSlider";
import getDepartmentEntitiesList from "../../api/services/departmentsEntitiesList";
import CaseLogsShow from "../../components/single-case-view/caseLogShow";

function SingleCaseView() {
    let { id } = useParams();
    const { userExist, permission } = useAuth();
    const [loading, setIsLoading] = useState(true);
    const [caseData, setcaseData] = useState(null);
    const [comorbidities, setComorbidities] = useState(null);
    const [complications, setComplications] = useState(null);
    const [departmentEntityList, setDepartmentEntityList] = useState([]);

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Case View | Cappagh";
    }, []);

    useEffect(() => {
        getSingleCaseInfo
            .caseInfo(id)
            .then((res) => {
                if (res.data && res.data?.length > 0) {
                    setcaseData(res.data[0]);
                    getDepartmentEntitiesList
                        .get(res.data[0].department_id)
                        .then((response) => {
                            if (response.data) {
                                setDepartmentEntityList(response.data);
                            }
                        })
                        .catch((error) => {
                            console.error("Error fetching data:", error);
                        });

                    if (res.data[0].comorbidities) {
                        setComorbidities(
                            Object.values(res.data[0].comorbidities).join(",")?.split(",")
                        );
                    }
                    if (res.data[0].complications) {
                        setComplications(
                            Object.values(res.data[0].complications).join(",")?.split(",")
                        );
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error("Error fetching data:", error);
            });
    }, []);

    if (!userExist) {
        return <Navigate to="/login" />;
    }

    return (
        <div>
            <div className="dbContentWrap caseDetail">
                {loading ? (
                    <div className="loader">Loading..</div>
                ) : !caseData ? (
                    <div>Case not found</div>
                ) : (
                    <div className="dbRow">
                        <div className="dbCol dbCol65">
                            <div className="dbRow">
                                <div className="dbCol dbColLg35">
                                    <article className="widgetWrap detailWidget">
                                        <div className="widgetHeader">
                                            <span>Case Details</span>
                                        </div>
                                        <ul className="listUnstyled widgetDetailList listStriped roundedBottom overflowHidden">
                                            <li>
                                                <span className="txTitle">Case Ref</span>
                                                <span className="txDetail textCapitalize">
                                                    {caseData.reference || "-"}
                                                </span>
                                            </li>
                                            <li>
                                                <span className="txTitle">Gender</span>
                                                <span className="txDetail textCapitalize">
                                                    {caseData?.gender || "-"}
                                                </span>
                                            </li>
                                            <li>
                                                <span className="txTitle">Age</span>
                                                <span className="txDetail">{caseData?.age || "-"}</span>
                                            </li>
                                            <li>
                                                <span className="txTitle">Procedure</span>
                                                <span className="txDetail textCapitalize">
                                                    {caseData.procedure || "-"}
                                                </span>
                                            </li>
                                            <li>
                                                <span className="txTitle">Consultant</span>
                                                <span className="txDetail">
                                                    {caseData?.consultant || "-"}
                                                </span>
                                            </li>
                                            <li>
                                                <span className="txTitle">Department</span>
                                                <span className="txDetail textCapitalize">
                                                    {caseData?.department || "-"}
                                                </span>
                                            </li>
                                            <li>
                                                <span className="txTitle">Group</span>
                                                <span className="txDetail textCapitalize">
                                                    {caseData?.group || "-"}
                                                </span>
                                            </li>
                                            <li>
                                                <span className="txTitle">Status</span>
                                                <span className="txDetail textCapitalize">
                                                    {caseData?.status?.replace(/-/g, " ") || "-"}
                                                </span>
                                            </li>
                                            <li>
                                                <span className="txTitle">Procedure date</span>
                                                <span className="txDetail">
                                                    {caseData?.procedure_date || "-"}
                                                </span>
                                            </li>
                                            <li>
                                                <span className="txTitle">Case Uploaded date</span>
                                                <span className="txDetail">
                                                    {caseData?.created_at || "-"}
                                                </span>
                                            </li>
                                            <li>
                                                <span className="txTitle">Operative Time</span>
                                                <span className="txDetail">
                                                    {caseData?.operative_time
                                                        ? `${caseData?.operative_time} Hrs`
                                                        : "-"}
                                                </span>
                                            </li>
                                            <li>
                                                <span className="txTitle">Uploaded by</span>
                                                <span className="txDetail">
                                                    {caseData?.assigned_to || "-"}
                                                </span>
                                            </li>
                                        </ul>
                                    </article>
                                </div>
                                <div className="dbCol dbColLg65">
                                    <article className="widgetWrap historyWidget">
                                        <div className="widgetHeader">
                                            <span>Past Medical History</span>
                                        </div>
                                        <div className="widgetBody">
                                            <p>{caseData?.past_medical_history || "-"}</p>
                                        </div>
                                    </article>
                                    <article className="widgetWrap comoWidget">
                                        <div className="widgetHeader">
                                            <span>Comorbidities</span>
                                        </div>

                                        <div className="widgetBody">
                                            <ul className="listUnstyled tagList">
                                                {caseData.comorbidities &&
                                                    Object.keys(caseData.comorbidities)?.length > 0 ? (
                                                    <>
                                                        {Object.values(caseData.comorbidities).map(
                                                            (item, index) => {
                                                                return <li key={index}>{item}</li>;
                                                            }
                                                        )}
                                                    </>
                                                ) : (
                                                    "-"
                                                )}
                                            </ul>
                                        </div>
                                    </article>
                                    <article className="widgetWrap compWidget">
                                        <div className="widgetHeader">
                                            <span>Complications</span>
                                        </div>
                                        <div className="widgetBody">
                                            <ul className="listUnstyled tagList">
                                                {caseData.complications &&
                                                    Object.keys(caseData.complications)?.length > 0 ? (
                                                    <>
                                                        {Object.values(caseData.complications).map(
                                                            (item, index) => {
                                                                return <li key={index}>{item}</li>;
                                                            }
                                                        )}
                                                    </>
                                                ) : (
                                                    "-"
                                                )}
                                            </ul>
                                        </div>
                                    </article>
                                    {caseData?.note && (
                                        <article className="widgetWrap historyWidget">
                                            <div className="widgetHeader">
                                                <span>Notes</span>
                                            </div>
                                            <div className="widgetBody">
                                                <p>{caseData?.note || "-"}</p>
                                            </div>
                                        </article>
                                    )}
                                </div>
                                {caseData.images?.length > 0 && (
                                    <ImagesSlider caseData={caseData} />
                                )}
                            </div>
                            {permission?.some(
                                (p) => p.name === userPermissions.CASES_AUDITS_LIST
                            ) && <CaseLogsShow />}
                            {permission?.some(
                                (p) => p.name === userPermissions.CASES_UPDATE
                            ) &&
                                caseData.status !== "approved" &&
                                !caseData.deleted_at && (
                                    <CaseAction
                                        caseId={id}
                                        caseData={caseData}
                                        departmentEntityList={departmentEntityList}
                                    />
                                )}
                        </div>
                        {caseData.status !== "draft" && (
                            <CaseComentsListing caseData={caseData} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default SingleCaseView;
