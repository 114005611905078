import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import './index.css'

function CustomNextArrow(props) {
    const { onClick, setCurrentImageIndex, images } = props;
    return (
        <div
            className="slickArrow slickNext fa-solid fa-chevron-right"
            onClick={() => {
                onClick();
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
            }}
        />
    );
}

function CustomPrevArrow(props) {
    const { onClick, setCurrentImageIndex, images } = props;
    return (
        <div
            className="slickArrow slickPrev fa-solid fa-chevron-left"
            onClick={() => {
                onClick();
                setCurrentImageIndex((prevIndex) => (prevIndex + (images.length - 1)) % images.length);
            }}
        />
    );
}

function CustomPaging(props) {
    const images = props.caseData.images.map(item => item.url);
    const imagesType = props.caseData.images.map(item => item.type);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);

    useEffect(() => {
        setNav1(sliderRef1.current);
        setNav2(sliderRef2.current);
    }, []);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: (images?.length === 2) ? 2 : (images?.length == 3) ? 3 : (images?.length > 3) && 4,
        slidesToScroll: 1,
        swipeToSlide: true,
        focusOnSelect: true,
        asNavFor: nav2,
        nextArrow: <CustomNextArrow setCurrentImageIndex={setCurrentImageIndex} images={images} />,
        prevArrow: <CustomPrevArrow setCurrentImageIndex={setCurrentImageIndex} images={images} />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: (images?.length === 2) ? 2 : (images?.length > 2) && 3,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: (images?.length > 1) && 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    };

    const thumbSettings = {
        ...settings,
        asNavFor: nav1,
        fade: true,
        arrows: false,
        slidesToShow: 1
    };

    return (
        <div className="dbCol">
            <article className="widgetWrap sliderWidget">
                <div className="widgetHeader">
                    <span>Case Images</span>
                </div>
                <div className="widgetBody noPadding">
                    <div className="thumbSlider posRelative">
                        <Slider
                            ref={sliderRef1}
                            {...thumbSettings}
                        >
                            <div className="imgWrap">
                                <img className="imgFluid" src={images[currentImageIndex]} alt="image description" />
                                <span className={`typeBadge ${(imagesType[currentImageIndex] == 0) ? "Pre-Op" : "Post-Op"}`} >{(imagesType[currentImageIndex] == 0 ? "Pre-op" : " Post Op")}</span>
                            </div>
                        </Slider>
                    </div>
                    <div className="gallerySlider posRelative">
                        {props.caseData.images.length > 1 && (
                            <Slider
                                ref={sliderRef2}
                                {...settings}
                            >
                                {props.caseData.images.map((img, index) => {
                                    return (
                                        <div key={index} className="imgWrap posRelative">
                                            <img onClick={() => (
                                                setCurrentImageIndex(index)
                                            )} className="imgFluid" src={img.url} alt="image description" />
                                            <span className={`typeBadge ${(img.type == 0) ? "Pre-Op" : "Post-Op"}`}>{(img.type == 0) ? "Pre-Op" : " Post Op"}</span>

                                        </div>
                                    )
                                })}
                            </Slider>
                        )}
                    </div>
                </div>
            </article>
        </div>
    );
}

export default CustomPaging;