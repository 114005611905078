import { API_SUPPORT_REQUEST_LIST, API_SUPPORT_REQUEST_STORE } from "../../config";
import httpClient from "../../httpClient";

export const requestSuppourt = {
    request: (data) => {
        return httpClient.post(`${API_SUPPORT_REQUEST_STORE}`, data);
    },
};

export const requestsSupportListing = {
    get: (pageNo, params) => {
        params = params && typeof params === 'object' ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== '' && value !== null)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const endpoint = API_SUPPORT_REQUEST_LIST + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

        return httpClient.get(endpoint);
    },
};