import React from 'react'
import ImagesPreview from '../update-case/imagesPreview'

function ShowPreview({ caseData }) {
    return (
        <div className="dbRow">
            <div className="dbCol dbCol65">
                <div className="dbRow">
                    <div className="dbCol dbColLg35">
                        <article className="widgetWrap detailWidget">
                            <div className="widgetHeader">
                                <span>Case Details</span>
                            </div>
                            <ul className="listUnstyled widgetDetailList listStriped roundedBottom overflowHidden">
                                <li>
                                    <span className="txTitle">Case Ref</span>
                                    <span className="txDetail textCapitalize">{caseData?.reference || '-'}</span>
                                </li>
                                <li>
                                    <span className="txTitle">Gender</span>
                                    <span className="txDetail textCapitalize">{caseData?.gender || '-'}</span>
                                </li>
                                <li>
                                    <span className="txTitle">Age</span>
                                    <span className="txDetail">{caseData?.age || '-'}</span>
                                </li>
                                <li>
                                    <span className="txTitle">Procedure</span>
                                    <span className="txDetail textCapitalize">{caseData?.procedure || "-"}</span>
                                </li>
                                <li>
                                    <span className="txTitle">Consultant</span>
                                    <span className="txDetail">{caseData?.consultant || '-'}</span>
                                </li>
                                <li>
                                    <span className="txTitle">Group</span>
                                    <span className="txDetail textCapitalize">{caseData?.group || '-'}</span>
                                </li>
                                <li>
                                    <span className="txTitle">Status</span>
                                    <span className="txDetail textCapitalize">{caseData?.status?.replace(/-/g, ' ') || '-'}</span>
                                </li>
                                <li>
                                    <span className="txTitle">Procedure date</span>
                                    <span className="txDetail">{caseData?.date || '-'}</span>
                                </li>
                                <li>
                                    <span className="txTitle">Case Uploaded date</span>
                                    <span className="txDetail">{caseData?.created_at || '-'}</span>
                                </li>
                                <li>
                                    <span className="txTitle">Operative Time</span>
                                    <span className="txDetail">{caseData?.operative_time ? `${caseData?.operative_time} Hrs` : '-'}</span>
                                </li>
                            </ul>
                        </article>
                    </div>
                    <div className="dbCol dbColLg65">
                        <article className="widgetWrap historyWidget">
                            <div className="widgetHeader">
                                <span>Past Medical History</span>
                            </div>
                            <div className="widgetBody">
                                <p>{caseData?.past_medical_history || '-'}</p>
                            </div>
                        </article>
                        <article className="widgetWrap comoWidget">
                            <div className="widgetHeader">
                                <span>Comorbidities</span>
                            </div>

                            <div className="widgetBody">
                                <ul className="listUnstyled tagList">
                                    {caseData.comorbidities && (caseData.comorbidities?.length > 0) && caseData.comorbidities?.[0] ? caseData.comorbidities?.map((item) => {
                                        return (<li>{item}</li>)
                                    }) : '-'}

                                </ul>
                            </div>
                        </article>
                        <article className="widgetWrap compWidget">
                            <div className="widgetHeader">
                                <span>Complications</span>
                            </div>
                            <div className="widgetBody">
                                <ul className="listUnstyled tagList">
                                    {caseData.complications && (caseData.complications?.length > 0) && caseData.complications?.[0] ? caseData.complications?.map((item) => {
                                        return (<li>{item}</li>)
                                    }) : '-'}

                                </ul>
                            </div>
                        </article>
                        <article className="widgetWrap historyWidget">
                            <div className="widgetHeader">
                                <span>Notes</span>
                            </div>
                            <div className="widgetBody">
                                <p>{caseData?.note || '-'}</p>
                            </div>
                        </article>
                    </div>
                    {(caseData.images && (caseData.images.length > 0)) && <ImagesPreview caseData={caseData} />}
                </div>
            </div>
        </div>
    )
}

export default ShowPreview