import React, { useState, useEffect } from "react";
import { useAuth } from "../../api/services/auth";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Pagination from "../../components/pagination";
import { userPermissions } from "../../enums";
import userListing from "../../api/services/userListing";
import UpdatePassword from "../../components/password-update/editPassword";
import { requestPasswordUserListing } from "../../api/services/password";
import { HalfCircleSpinner } from "react-epic-spinners";

function PasswordSet() {
    const { userExist, permission } = useAuth();
    const [usersList, setUsersList] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(true);
    // const [showFilters, setShowFilters] = useState(false);

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Passwords Update | Cappagh";
    }, []);

    const fetchData = (pageNo) => {
        requestPasswordUserListing.getUsers(pageNo)
            .then((res) => {
                setUsersList(res.data);
                setPagination(res.meta);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error fetching data:", error);
            });
    }

    // Get_USER_DATA
    useEffect(() => {
        fetchData(1)
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        setSelectedUser(null)
        fetchData(pageNo)
    };

    if (!userExist) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <div className="dbContentWrap">
                {selectedUser && (
                    <UpdatePassword
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                        fetchData={fetchData}
                    />
                )}
                {/* Users_Listing */}
                <div className="tableWidget">
                    <article className="widgetWrap">
                        <div className="widgetHeader">
                            <span>Password Management</span>
                            {/* <div className="tabHeadActionWrap">
                                <button onClick={() => {
                                }} type="button" className={`actionBtn ${showFilters && 'active'}`}>Search Filter <i className="fa-sharp fa-solid fa-filters"></i></button>
                            </div> */}
                        </div>
                        <div className="tableWrap">
                            <table className="table fontSmall textCenter w100">
                                <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Name</th>
                                        <th>Position</th>
                                        <th>Department</th>
                                        <th>Email</th>
                                        <th>Contact Info</th>
                                        <th>Profile Image</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {usersList && usersList?.length > 0 ? (
                                        usersList.map((user) => (
                                            <tr key={user.id}>
                                                <td data-label="Username">
                                                    {user.username ? user.username : "-"}
                                                </td>
                                                <td data-label="Name" className="txtCaptilize">
                                                    {user.salutation}.{" "}
                                                    {user.first_name ? user.first_name : ""}{" "}
                                                    {user.surname ? user.surname : ""}
                                                </td>
                                                <td data-label="Position" className="txtCaptilize">
                                                    {user.position_name ? user.position_name : "-"}
                                                </td>
                                                <td data-label="Department" className="txtCaptilize">
                                                    {user.department_name ? user.department_name : "-"}
                                                </td>
                                                <td data-label="Email">
                                                    {user.email ? user.email : "-"}
                                                </td>
                                                <td data-label="Contact Info">
                                                    {user.phone_number ? user.phone_number : "-"}
                                                </td>
                                                <td data-label="Profile Image">
                                                    <div className="userImageWrap">
                                                        {user.image ?
                                                            <img
                                                                src={user.image}
                                                                className="imgFluid"
                                                                alt="image description"
                                                                onContextMenu={(e) => e.preventDefault()}
                                                            />
                                                            :
                                                            <div className="placeholderImage">?</div>
                                                        }
                                                    </div>
                                                </td>
                                                <td data-label="Actions">
                                                    <div>
                                                        {permission?.some(p => (p.name === userPermissions.RESET_PASSWORDS_UPDATE)) && (
                                                            <i
                                                                onClick={() => {
                                                                    handleScrollToTop();
                                                                    setSelectedUser(user);
                                                                }}
                                                                className="actionIcn edit fa-solid fa-pencil"
                                                            ></i>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : loading ? (
                                        <tr>
                                            <td colSpan={9}><HalfCircleSpinner
                                                size={18}
                                                color='black'
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                            /></td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td colSpan={9}>No record found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="widgetFooter">
                            <div className="textCenter">
                                {pagination && Object.keys(pagination)?.length > 0 && (
                                    <Pagination
                                        pagination={pagination}
                                        handlePageClick={handlePageClick}
                                    />
                                )}
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </>
    );
}

export default PasswordSet;
