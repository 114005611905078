import httpClient from "../../httpClient";
import { API_NOTIFICATION_LIST, API_NOTIFICATION_CREATE, API_NOTIFICATION_DELETE } from "../../config";

export const notificationListing = {
    get: () => httpClient.get(API_NOTIFICATION_LIST),
};

export const createNotification = {
    create: (data) => httpClient.post(API_NOTIFICATION_CREATE, data),
};

export const deleteNotification = {
    delete: (id) => httpClient.delete(API_NOTIFICATION_DELETE + '/' + id),
};