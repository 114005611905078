import React, { createContext, useState, useContext, useEffect } from 'react';
import { userPermissions } from '../enums';
import { departmentListingAll } from '../api/services/departmnent-services';
const AllDepartmentsContext = createContext({});

export const AllDepartmentsProvider = ({ children }) => {
    const loggedInUserPermission = localStorage.getItem('loggedInUserPermission');
    const permission = loggedInUserPermission ? JSON.parse(loggedInUserPermission) : null;
    const [departmentListing, setDepartmentListing] = useState([]);
    const token = localStorage.getItem('authToken');


    useEffect(() => {
        if (permission && permission?.some(p => (p.name === userPermissions.DEPARTMENTS_LIST))) {
            if (token) {
                departmentListingAll.list().then((res) => {
                    if (res.data && res.data && res.data.length > 0) {
                        setDepartmentListing(res.data.map(item => ({
                            value: item.id,
                            label: item.name,
                        })))
                    }
                }).catch(error => console.error('Error fetching data:', error));
            }
        }
    }, []);

    return (
        <AllDepartmentsContext.Provider
            value={{
                departmentListing,
            }}
        >
            {children}
        </AllDepartmentsContext.Provider>
    );
};

export const useAllDepartments = () => useContext(AllDepartmentsContext);
