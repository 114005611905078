import React, { useState, useEffect } from "react";
import { useInput } from "../../hooks/useInput";
import { toast } from "react-toastify";
import { HalfCircleSpinner } from "react-epic-spinners";

const CaseLogsSearchFilter = ({
    setPagination,
    setCaseLogsList,
    setShowFilter,
}) => {
    const [name, bindName, resetName] = useInput("");
    const [showLoader, setShowLoader] = useState(false);

    const handleSearch = () => { };

    return (
        <>
            <form
                className="userForms updateUserForm"
                onSubmit={(event) => handleSearch(event)}
            >
                <article className="widgetWrap">
                    <div className="widgetBody">
                        <div className="dbRow">
                            <div className="dbCol dbColLg75">
                                <div className="dbRow">
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Case Ref</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl`}
                                                    {...bindName}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btnWrap">
                            <button
                                onClick={() => {
                                    setShowFilter(false);
                                }}
                                type="button"
                                className="btn btnWhite"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >
                                Search
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: "relative",
                                            top: "2px",
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                </article>
            </form>
        </>
    );
};

export default CaseLogsSearchFilter;
