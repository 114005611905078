import React, { useState, useEffect } from "react";
import { useAuth } from "../../api/services/auth";
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from 'react-epic-spinners';
import Pagination from "../../components/pagination";
import complicationListing from "../../api/services/complicationListing";
import deleteComplication from "../../api/services/deleteComplication";
import UpdateComplicationInfo from "../../components/complication/updateComplicationInfo";
import CreateComplication from "../../components/complication/createComplication";
import { Navigate } from "react-router-dom";
import { userPermissions } from "../../enums";

const Complication = (props) => {
    const { userExist, permission } = useAuth()
    const [complicationList, setComplicationList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [createUser, setShowCreateUser] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedComplication, setSelectedComplication] = useState({});
    const [selectedComplicationId, setSelectedComplicationId] = useState(null);
    const [loading, setLoading] = useState(true);

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Complication Management | Cappagh";
    }, []);


    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        complicationListing.getComplication(pageNo)
            .then((res) => {
                handleScrollToTop()
                setComplicationList(res.data);
                setPagination(res.meta);
            }
            ).catch(error => console.error('Error fetching data:', error));
    };

    // Handle_Show_Update_User
    const handleShowUpdateUser = () => {
        setShowUpdateUser(!showUpdateUser)
    }

    const handleDeleteClick = (userId) => {
        setSelectedComplicationId(userId);
    };

    // Handle_Delete_User
    const handleDeleteUser = () => {
        setShowLoader(true);

        deleteComplication.delete(selectedComplicationId)
            .then((res) => {
                setShowLoader(false);
                toast.success(res.message);

                complicationListing.getComplication(1)
                    .then((res) => {
                        setComplicationList(res.data);
                        setPagination(res.meta);
                    }
                    ).catch(error => console.error('Error fetching data:', error));
            }
            ).catch((error) => {
                setShowLoader(false);
                toast.error(error.data.message);
            }
            );
        setSelectedComplicationId(null);
    };

    // Get_Complications_Listing
    useEffect(() => {
        complicationListing.getComplication(1)
            .then((res) => {
                setLoading(false)
                setComplicationList(res.data);
                setPagination(res.meta);
            }
            ).catch(error => {
                setLoading(false)
                console.error('Error fetching data:', error)
            });
    }, []);

    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <>
            <div className="dbContentWrap">
                {/* Create_User_Form */}
                {!showFilters && !showUpdateUser && createUser &&
                    <CreateComplication
                        createUser={createUser}
                        setShowCreateUser={setShowCreateUser}
                        setComplicationList={setComplicationList}
                        setPagination={setPagination}
                    />
                }

                {/* Update_UserInfo_Form */}
                {!showFilters && !createUser && showUpdateUser &&
                    <UpdateComplicationInfo
                        setShowUpdateUser={setShowUpdateUser}
                        selectedComplication={selectedComplication}
                        handleShowUpdateUser={handleShowUpdateUser}
                        setComplicationList={setComplicationList}
                        setPagination={setPagination}
                    />
                }

                {/* Users_Listing */}
                <div className="tableWidget">
                    <article className="widgetWrap">
                        <div className="widgetHeader">
                            <span>Complication Management</span>
                            <div className="tabHeadActionWrap">
                                {permission?.some(p => (p.name === userPermissions.COMPLICATIONS_CREATE)) && (
                                    <button onClick={() => {
                                        handleScrollToTop();
                                        setShowCreateUser(true);
                                        setShowFilters(false);
                                        setShowUpdateUser(false);
                                    }} type="button" className={`actionBtn ${createUser && 'active'}`}>Add Complication <i className="fa-solid fa-user-plus"></i></button>
                                )}
                            </div>
                        </div>
                        <div className="tableWrap">
                            <table className="table fontSmall textCenter w100">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(complicationList && (complicationList?.length > 0)) ? complicationList.map(item => (
                                        <tr key={item.id}>
                                            <td data-label="Name">{item.name}</td>
                                            <td data-label="Status">{item.status == '1' ? "Active" : "Inactive"}</td>

                                            <td data-label="Actions">
                                                <div>
                                                    {permission?.some(p => (p.name === userPermissions.COMPLICATIONS_UPDATE)) && (

                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleScrollToTop();
                                                                setShowUpdateUser(true)
                                                                setShowFilters(false);
                                                                setShowCreateUser(false);
                                                                setSelectedComplication({ ...item })
                                                            }}
                                                            className="actionIcn edit fa-solid fa-pencil"
                                                        ></button>
                                                    )}
                                                    {permission?.some(p => (p.name === userPermissions.COMPLICATIONS_DELETE)) && (
                                                        <button
                                                            onClick={() => {
                                                                handleDeleteClick(item.id)
                                                            }
                                                            }
                                                            type="button"
                                                            className="actionIcn delete fa-solid fa-trash"
                                                        ></button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )) : loading ?
                                        <tr>
                                            <td colSpan={9}><HalfCircleSpinner
                                                size={18}
                                                color='black'
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                            /></td>
                                        </tr> :
                                        <tr>
                                            <td colSpan={8}>No record found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="widgetFooter">
                            <div className="textCenter">
                                {pagination && (Object.keys(pagination)?.length > 0) && (
                                    <Pagination pagination={pagination} handlePageClick={handlePageClick} />
                                )}
                            </div>
                        </div>
                    </article>
                </div>
            </div>
            {/* Delete_Complication_Popup */}
            <Popup className="deleteUserModal" open={selectedComplicationId !== null} onClose={() => setSelectedComplicationId(null)}>
                {selectedComplicationId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to delete this complication.</p>
                        <div className="btnWrap">
                            <button onClick={() => setSelectedComplicationId(null)} type="button" className="btn btnSecondary">Cancel</button>
                            <button
                                onClick={handleDeleteUser}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Delete
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    );
}

export default Complication;