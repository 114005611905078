import React, { useEffect, useState } from "react";
import { useInput } from "../../hooks/useInput";
import { toast } from "react-toastify";
import { HalfCircleSpinner } from "react-epic-spinners";
import { setNewPassword } from "../../api/services/password";

const UpdatePassword = ({ setSelectedUser, selectedUser, fetchData }) => {
    const [password, bindPassword, resetPassword] = useInput("");
    const [confirmPassword, bindConfirmPassword, resetConfirmPassword] = useInput("");
    const [errors, setErrors] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    useEffect(() => {
        if (selectedUser) {
            resetPassword('')
            resetConfirmPassword('')
        }
    }, [selectedUser])

    // Check_Validation_Function
    const checkValidation = () => {
        let errors = {};
        let isValid = true;

        if (!password) {
            isValid = false;
            errors["password"] = "Please enter password.";
        }
        if (!confirmPassword) {
            isValid = false;
            errors["confirmPassword"] = "Please enter Confirm Password.";
        }
        if (password !== confirmPassword) {
            isValid = false;
            errors["confirmPassword"] =
                "Password and Confirm Password should be same.";
            toast.error("Password and Confirm Password should be same.");
        } else if (password && password?.length < 8) {
            isValid = false;
            toast.error("Pasword length should be atleast 8 chracters");
        }
        setErrors(errors);
        return isValid;
    };

    //handle update password
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (checkValidation()) {
            setShowLoader(true);
            setNewPassword.set({ password, confirm_password: confirmPassword, reset_passowrd_id: selectedUser.id }).then((res) => {
                fetchData(1)
                setSelectedUser(null)
                resetPassword()
                resetConfirmPassword()
                setShowLoader(false);
                toast.success(res.message);
            }).catch(err => {
                setShowLoader(false);
                toast.error(err.data.message);
            })
        }
    };

    // Handle_Show_Password
    const handleShowPass = () => {
        setShowPass(!showPass);
    }
    // Handle_Show_Confirm_Password
    const handleShowConfirmPass = () => {
        setShowConfirmPass(!showConfirmPass);
    }


    return (
        <>
            <form className="loginForm userForms updateUserForm" onSubmit={handleSubmit}>
                <article className="widgetWrap">
                    <div className="widgetBody">
                        <div className="textCenter txtWhite noteTxt txtText">
                            Updating Password for{" "}
                            <span className="txtCaptilize">
                                {selectedUser.salutation}.{" "}
                                {selectedUser.first_name ? selectedUser.first_name : ""}{" "}
                                {selectedUser.surname ? selectedUser.surname : ""}
                            </span>
                        </div>
                        <div className="dbRow">
                            <div className="dbCol">
                                <div className="dbRow">
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">New Password</label>
                                            <div className={`inputWrap posRelative passWrap ${showPass && 'view'}`}>
                                                <input
                                                    type={showPass ? 'text' : 'password'}
                                                    className={`formControl ${errors.password && "error"}`}
                                                    id="passwordInput"
                                                    {...bindPassword}
                                                />
                                                <button
                                                    type='button'
                                                    className={`icnView fa-solid posAbsolute ${showPass ? 'fa-eye-slash' : 'fa-eye'}`}
                                                    onClick={() => {
                                                        handleShowPass()
                                                    }}
                                                ></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Confirm New Password</label>
                                            <div className={`inputWrap posRelative passWrap ${showConfirmPass && 'view'}`}>
                                                <input
                                                    id="passwordInput"
                                                    type={showConfirmPass ? 'text' : 'password'}
                                                    className={`formControl ${errors.confirmPassword && "error"
                                                        }`}
                                                    {...bindConfirmPassword}
                                                />
                                                <button
                                                    type='button'
                                                    className={`icnView fa-solid posAbsolute ${showConfirmPass ? 'fa-eye-slash' : 'fa-eye'}`}
                                                    onClick={() => {
                                                        handleShowConfirmPass()
                                                    }}
                                                ></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btnWrap">
                            <button
                                onClick={() => {
                                    setSelectedUser(null);
                                }}
                                type="button"
                                className="btn btnWhite"
                            >
                                Cancel
                            </button>
                            <button type="submit" className="btn btnPrimary mb0">
                                Update Password
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: "relative",
                                            top: "2px",
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                </article>
            </form>
        </>
    );
};

export default UpdatePassword;
