import React from 'react'
import { useNavigate } from 'react-router-dom'

function CaseAction({ caseId }) {
    const navigate = useNavigate();

    const handleBacktoList = () => {
        navigate(`/update-case/${caseId}`);
    }

    return (
        <div className="actionsBtnWrap">
            <button onClick={handleBacktoList} type="button" className="btn btnPrimary" style={{maxWidth: '258px'}}>Edit this Case</button>
        </div >
    )
}

export default CaseAction