import React, { useState } from "react";
import { useAuth } from "../../api/services/auth";
import { userPermissions } from "../../enums";
import PostComment from "./postComment";
import { deleteComment } from '../../api/services/cases/comments/index';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from 'react-epic-spinners';

function PreOpsComments({
    caseData,
    upcommingCase,
    loginInUser,
    showEditModal,
    editComment,
    setEditComment,
    seUpcommingCase,
    setCommentData,
    setIsLoading,
    openUpcomingCommentEditModal,
    closeEditModal,
    handleEditComment
}) {
    const { permission } = useAuth();
    const [showLoader, setShowLoader] = useState(false);
    const [selectedCommentId, setSelectedCommentId] = useState(null);


    // Handle_Delete_Click
    const handleCommentDeleteClick = (commentId) => {
        setSelectedCommentId(commentId);
    };

    const handleDeleteComment = (id) => {
        deleteComment.delete(id).then((res) => {
            setShowLoader(true);

            if (res.status === 200) {
                toast.success(res.message);
                setShowLoader(false);
                setSelectedCommentId(null)
            }
        })
    }

    return (
        <div>
            <article className="widgetWrap auditReviewWidget mb3">
                {permission?.some(
                    (p) => p.name === userPermissions.COMMENTS_CASE_COMMENTS
                ) && (
                        <>
                            <div className="widgetHeader">
                                <span>Pre Op Comments</span>
                            </div>
                            {(upcommingCase && upcommingCase.length > 0) ? (
                                <ul className="listUnstyled reviewslList listStriped overflowHidden">
                                    {upcommingCase?.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <div className="singleReview">
                                                    <div className="reviewHeader">
                                                        <div className="reviewTitle">{item.user}</div>
                                                        <div className="reviewTime">
                                                            <span className="date">
                                                                {item.comment_date?.replace(/-/g, "/")}
                                                            </span>
                                                            <span className="time">
                                                                {item.comment_time?.split(" ")[0]}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="reviewBody">
                                                        <p>{item.comment}</p>
                                                        {loginInUser.id === item.user_id &&
                                                            (caseData.status === "scheduled" || caseData.status === "published") && (
                                                                <div className="editBtnWrap caseCommentBtnWrap txtRight">
                                                                    {(!showEditModal[item.id] &&
                                                                        permission?.some(
                                                                            (p) => p.name === "comments-update"
                                                                        ) && !caseData.deleted_at) ? (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btnPrimary editComentBtn caseCommentBtn"
                                                                            onClick={() =>
                                                                                openUpcomingCommentEditModal(item.id)
                                                                            }
                                                                        >
                                                                            Edit My Comment
                                                                        </button>
                                                                    ) : null}
                                                                    {showEditModal[item.id] && (
                                                                        <div className="reviewFooter">
                                                                            <div className="formWrap">
                                                                                <form
                                                                                    action=""
                                                                                    className="reviewCommentForm cmListComentForm"
                                                                                >
                                                                                    <div className="formRow">
                                                                                        <textarea
                                                                                            className="formControl dBlock w100"
                                                                                            placeholder="Add comment..."
                                                                                            value={
                                                                                                editComment
                                                                                                    ? editComment.comment
                                                                                                    : ""
                                                                                            }
                                                                                            onChange={(e) =>
                                                                                                setEditComment({
                                                                                                    ...editComment,
                                                                                                    comment: e.target.value,
                                                                                                })
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="formBtnRow fontSmall">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btnSecondary"
                                                                                            onClick={closeEditModal}
                                                                                        >
                                                                                            Cancel
                                                                                        </button>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btnPrimary"
                                                                                            onClick={() => {
                                                                                                handleEditComment(item.id);
                                                                                            }}
                                                                                        >
                                                                                            Save Comment
                                                                                        </button>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {(permission?.some((p) => p.name === "comments-delete") && !caseData.deleted_at) ? (
                                                                        <button
                                                                            onClick={() => {
                                                                                handleCommentDeleteClick(item.id);
                                                                            }}
                                                                            className="btn btnDanger caseCommentBtn"
                                                                        >Delete Comment</button>
                                                                    ) : null}
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>

                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                <ul className="listUnstyled reviewslList preOpCommentCount listStriped roundedBottom overflowHidden">
                                    <li>
                                        <div className="singleReview">
                                            <div className="textCenter">
                                                No Comments
                                            </div >
                                        </div >
                                    </li >
                                </ul >
                            )
                            }
                        </>
                    )}
                {
                    (((caseData.status == "published") || (caseData.status == "scheduled")) &&
                        permission?.some(
                            (p) => p.name === userPermissions.COMMENTS_STORE
                        ) && !caseData.deleted_at) && (
                        <ul className="listUnstyled reviewslList postCommentBox listStriped roundedBottom overflowHidden">
                            <PostComment
                                setCommentData={setCommentData}
                                seUpcommingCase={seUpcommingCase}
                                setIsLoading={setIsLoading}
                            />
                        </ul>
                    )
                }
            </article >
            <Popup
                className="deleteUserModal"
                open={selectedCommentId !== null}
                onClose={() => {
                    setSelectedCommentId(null)
                }}
            >
                <div className="modal textCenter">
                    <p>Do You want delete this comment?</p>
                    <div className="btnWrap">
                        <button
                            onClick={(e) => {
                                setSelectedCommentId(null)
                            }}
                            type="button"
                            className="btn btnSecondary"
                            disabled={showLoader}
                        >No</button>
                        <button
                            onClick={() => {
                                handleDeleteComment(selectedCommentId)
                            }}
                            type="button"
                            className="btn btnPrimary"
                            disabled={showLoader}
                        >Yes
                            {showLoader && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: 'relative',
                                        top: '2px',
                                    }}
                                />
                            )}
                        </button>
                    </div>
                </div>
            </Popup>
        </div >
    );
}

export default PreOpsComments;
