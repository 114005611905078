import React from 'react'

function CasesList({ allCasesOfUser, setSelectedCases, selectedCases }) {

    const handleCaseSelect = (e) => {
        const checked = e.target.value;
        const isChecked = e.target.checked;
        if (isChecked) {
            setSelectedCases([...selectedCases, checked]);
        } else {
            setSelectedCases(selectedCases.filter(id => id !== checked));
        }
    };
    return (
        <div className="tableWidget" style={{ marginTop: '40px' }}>
            <article className="widgetWrap">
                <div className="widgetHeader">
                    <span>Transfere Cases</span>
                </div>
                <div className="tableWrap">
                    <table className="table fontSmall textCenter w100">
                        <thead>
                            <tr>
                                <th>Ref</th>
                                <th>Date</th>
                                <th>Procedure</th>
                                <th>Comorbidities</th>
                                <th>Complications</th>
                                <th>Procedure Status</th>
                                <th>Select</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(allCasesOfUser && (allCasesOfUser?.length > 0)) && allCasesOfUser.map(item => (
                                <tr key={item.id}>
                                    <td data-label="Ref">{item.reference ? item.reference : "-"}</td>
                                    <td data-label="Date">{item.procedure_date ? item.procedure_date : '-'}</td>
                                    <td data-label="Procedure">{item.procedure ? item.procedure : '-'}</td>
                                    <td data-label="Comorbidities" data-text-alignment="left" className="txtCaptilize">{item.comorbidities ? Object.values(item?.comorbidities).join(', ') : '-'}</td>
                                    <td data-label="Complications" data-text-alignment="left" className="txtCaptilize">
                                        {item.complications ? Object.values(item?.complications).join(', ') : '-'}
                                    </td>
                                    <td data-label="Status" className='textCapitalize'>{item?.status}</td>
                                    <td data-label="Actions">
                                        <input type="checkbox" name={item.id} value={item.id} onChange={handleCaseSelect} style={{ cursor: 'pointer' }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="widgetFooter">
                    <div className="textCenter" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                    </div>
                </div>
            </article>
        </div>
    )
}

export default CasesList