import React, { useState, useEffect } from "react";
import Logo from "../../../assets/images/logo-white.svg";
import './header.css';
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../api/services/auth";
import { userPermissions } from "../../../enums";
import Echo from 'laravel-echo';
import { useNotification } from "../../../context/NotificationsContext";
window.Pusher = require('pusher-js');

const Header = (props) => {
    const [notificationsList, setNotificationsList] = useState([])
    const { notificationsCount, setNotificationsCount, options } = useNotification();
    const { permission, user } = useAuth();
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();
    const token = localStorage.getItem('authToken');

    // Mobile menu toggle handler
    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
        document.body.classList.toggle('menuOpen', !menuOpen);
    };

    const userMessagesRef = React.useRef();

    useEffect(() => {
        if (notificationsList !== null) {
            userMessagesRef.current = notificationsList;
        }
    }, [notificationsList]);



    useEffect(() => {
        const echo = new Echo(options);

        echo?.private(`user.${user.id}`)
            .listen('NotificationEvent', data => {
                if ((!userMessagesRef.current?.some(msg => msg.id === data.id) && (location.pathname !== '/notifications'))) {
                    setNotificationsList([{ created_at: data.time, content: data.message, id: data.id }, ...userMessagesRef.current]);
                }
            });

    }, [location]);

    useEffect(() => {
        if (location.pathname === '/notifications') {
            localStorage.setItem('notificationCount', 0);
            setNotificationsCount(0);
            setNotificationsList([]);
        } else if ((location.pathname !== '/notifications' && (notificationsList.length > 0))) {
            localStorage.setItem('notificationCount', notificationsList.length);
            setNotificationsCount(notificationsList.length);
        }
    }, [notificationsList.length])

    return (
        <nav className="navbarTop">
            <div className="navbarLogo">
                <Link to='/dashboard' className="dBlock"><img src={Logo} className="imgFluid" alt="NOH" /></Link>
            </div>
            <div className="navbarNavIcns dFlex alignCenter">
                <div className="navItem txt">
                    <span className="navLink txtCaptilize">Welcome {user.first_name} {user.surname}</span>
                </div>
                {permission?.some(p => (p.name === userPermissions.PROFILE_UPDATE)) && (
                    <div className="navItem">
                        <Link to='/profile' className={`navLink ${location.pathname === '/profile' ? 'active' : ''}`}><i className="icn fa-solid fa-gear"></i>
                            <span className="toolTip">Profile</span></Link>
                    </div>
                )}
                {permission?.some(p => (p.name === userPermissions.CASES_LIST)) && (
                    <div className="navItem">
                        <Link to='/cases-directory' className={`navLink ${location.pathname === '/cases-directory' ? 'active' : ''}`}><i className="icn fa-sharp fa-solid fa-list-tree"></i><span className="toolTip">Case Directory</span></Link>
                    </div>
                )}
                {/* <div className="navItem">
                    <a href="#" className="navLink">
                        <i className="icn fa-solid fa-message"></i>
                        <span className="badgeCount">12</span>
                    </a>
                </div> */}
                {permission?.some(p => (p.name === userPermissions.NOTIFICATIONS_LIST)) && (
                    <div className="navItem">
                        <Link to='/notifications' className={`navLink ${location.pathname === '/notifications' ? 'active' : ''}`}>
                            <i onClick={() => {
                                setNotificationsList([])
                                localStorage.setItem('notificationCount', 0)
                            }
                            } className="icn fa-solid fa-bullhorn"></i>
                            <span className="toolTip">Notifications</span>
                            {(location.pathname !== '/notifications' && (notificationsCount > 0)) && (
                                <span className="badgeCount">{notificationsCount}</span>
                            )}
                        </Link>
                    </div>
                )}
                <div className="navItem menu">
                    <span className="menuOpener" onClick={handleMenuToggle}>
                        <i className="fa-solid fa-bars"></i>
                    </span>
                </div>
            </div>
        </nav>
    )
}

export default Header;