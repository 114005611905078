import { userLogout } from "./user-services/role";

export const logout = () => {
    userLogout.get().then(res => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("loggedInUserPermission");
        localStorage.removeItem("loggedInUser");
        localStorage.removeItem("userRole");
        window.location.replace("/");
    }).catch(err => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("loggedInUserPermission");
        localStorage.removeItem("loggedInUser");
        localStorage.removeItem("userRole");
        window.location.replace("/");
    })
}

export const logoutLocal = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("loggedInUserPermission");
    localStorage.removeItem("loggedInUser");
    localStorage.removeItem("userRole");
    window.location.replace("/");
}
