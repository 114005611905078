import React, { useState, useEffect } from "react";
import userListing from "../../api/services/userListing";
import deleteUser from "../../api/services/deleteUser";
import positionList from "../../api/services/positionListing";
import departmentList from "../../api/services/departmentListing";
import { toast } from "react-toastify";
import CreateUserForm from "../../components/user-management/createUser";
import UpdateUser from "../../components/user-management/updateUserInfo";
import { HalfCircleSpinner } from "react-epic-spinners";
import Pagination from "../../components/pagination";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../api/services/auth";
import { userPermissions } from "../../enums";
import DeactiveModal from "../../components/user-management/deactiveUserModal";
import DeactiveSearch from "../../components/user-management/deactiveUserSearch";
import RestoreUser from "../../components/user-management/restoreUserModal";
import { postionFilters } from "../../api/services/positions";
import { departmentFilter } from "../../api/services/departmnent-services";

const UserManagement = (props) => {
    const { userExist, permission } = useAuth();
    const [usersList, setUsersList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [filteredSearch, setFilteredSearch] = useState(false);
    const [createUser, setShowCreateUser] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [selectedUserToDeactivate, setSelectedUserToDeactivate] =
        useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [positionListing, setPositionListing] = useState([]);
    const [departmentListing, setDepartmentListing] = useState([]);
    const [imageError, setImageError] = useState(false);
    const [showDeactivateUser, setShowDeactivateUser] = useState(false);
    const [department, setDepartment] = useState(null);
    const [showTransferUserSearch, setShowTransferUserSearch] = useState("");
    const [showTransferCase, setShowTransferCase] = useState(true);
    const [loading, setLoading] = useState(true);
    const [showforDepartment, setShowForDepartment] = useState(false);
    const [showRestoreUser, setShowRestoreUser] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [groupListing, setGroupListing] = useState([]);
    const [group, setGroup] = useState(null);
    const [formData, setFormData] = useState({
        username: "",
        first_name: "",
        surname: "",
        department_id: "",
        position_id: "",
        email: "",
        status: "",
    });
    let oneRequest = true;

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "User Management | Cappagh";
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Smooth scrolling behavior
        });
    };

    // OnError_Image_Handler
    const handleImageError = () => {
        setImageError(true);
    };

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        if (filteredSearch) {
            userListing
                .getUsers(pageNo, formData)
                .then((res) => {
                    setLoading(false);
                    handleScrollToTop();
                    setUsersList([...res.data]);
                    setPagination({ ...res.meta });
                    if (res?.meta?.last_page > 1) {
                        setFilteredSearch(true);
                    } else {
                        setFilteredSearch(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.error("Error fetching data:", error);
                });
        } else {
            userListing
                .getUsers(pageNo)
                .then((res) => {
                    handleScrollToTop();
                    setUsersList(res.data);
                    setPagination(res.meta);
                })
                .catch((error) => console.error("Error fetching data:", error));
        }
    };

    // Handle_Show_Filters
    const handleShowFilter = () => {
        setShowFilters(!showFilters);
        setShowTransferUserSearch(false);
        setShowTransferUserSearch("");
        setShowTransferCase(true);
        setShowUpdateUser(false);
        setSelectedUserId(null);
        setShowDeactivateUser(false);
        setShowCreateUser(false);
        setFormData({
            username: "",
            first_name: "",
            surname: "",
            department: "",
            position_id: "",
            email: "",
            status: "",
        });
        userListing
            .getUsers()
            .then((res) => {
                setUsersList(res.data);
                setPagination(res.meta);
            })
            .catch((error) => console.error("Error fetching data:", error));
    };

    // Handle_Show_Update_User
    const handleShowUpdateUser = () => {
        setShowUpdateUser(!showUpdateUser);
    };

    // Handle_Delete_User_Click
    const handleDeleteClick = (userId) => {
        setSelectedUserId(userId);
        setShowDeactivateUser(true);
        setShowTransferUserSearch(false);
        setShowTransferUserSearch("");
        setShowTransferCase(true);
        setShowUpdateUser(false);
        setShowFilters(false);
        setShowCreateUser(false);
    };

    // Handle_Search_Change
    const handleSearchChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle_Search_API_Call
    const handleSearch = () => {
        setLoading(true);
        userListing
            .getUsers(1, formData)
            .then((res) => {
                setLoading(false);
                setUsersList([...res.data]);
                setPagination({ ...res.meta });
                if (res?.meta?.last_page > 1) {
                    setFilteredSearch(true);
                } else {
                    setFilteredSearch(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error fetching data:", error);
            });
    };

    const handleShowUserRestoreModal = (user) => {
        setShowRestoreUser(user);
    };

    // Get_Users_Listing
    useEffect(() => {
        if (oneRequest) {
            oneRequest = false;
            userListing
                .getUsers(1)
                .then((res) => {
                    setUsersList(res.data);
                    setPagination(res.meta);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error("Error fetching data:", error);
                });

            // Fetch_Position_Listing
            postionFilters
                .get()
                .then((res) => {
                    setPositionListing(res.data);
                })
                .catch((error) => console.error("Error fetching data:", error));

            // Fetch_Department_Listing
            departmentFilter
                .get()
                .then((res) => {
                    setDepartmentListing(res.data);
                })
                .catch((error) => console.error("Error fetching data:", error));
        }
    }, []);

    if (!userExist) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            <div className="dbContentWrap">
                {/* Deactive_Search_Form */}
                {department && showTransferUserSearch && (
                    <DeactiveSearch
                        selectedUserToDeactivate={selectedUserToDeactivate}
                        setShowTransferUserSearch={setShowTransferUserSearch}
                        showTransferUserSearch={showTransferUserSearch}
                        department={department}
                        setShowDeactivateUser={setShowDeactivateUser}
                        setShowTransferCase={setShowTransferCase}
                        showTransferCase={showTransferCase}
                        selectedUserId={selectedUserId}
                        setUsersLists={setUsersList}
                        setPagination={setPagination}
                        setShowForDepartment={setShowForDepartment}
                        showforDepartment={showforDepartment}
                        setDepartment={setDepartment}
                        group={group}
                        setGroup={setGroup}
                        groupListing={groupListing}
                        setGroupListing={setGroupListing}
                    />
                )}
                {/* Deactive_Modal */}
                {showDeactivateUser && (
                    <DeactiveModal
                        department={department}
                        setShowTransferCase={setShowTransferCase}
                        selectedUserToDeactivate={selectedUserToDeactivate}
                        setDepartment={setDepartment}
                        selectedUserId={selectedUserId}
                        setSelectedUserId={setSelectedUserId}
                        setShowTransferUserSearch={setShowTransferUserSearch}
                        setShowDeactivateUser={setShowDeactivateUser}
                        setUsersList={setUsersList}
                        setPagination={setPagination}
                        setShowForDepartment={setShowForDepartment}
                        showforDepartment={showforDepartment}
                        group={group}
                        setGroup={setGroup}
                        groupListing={groupListing}
                        setGroupListing={setGroupListing}
                    />
                )}
                {/* Reactive_Modal */}
                {(showRestoreUser || showSuccessModal) && (
                    <RestoreUser
                        showSuccessModal={showSuccessModal}
                        setShowSuccessModal={setShowSuccessModal}
                        setPagination={setPagination}
                        setUsersList={setUsersList}
                        showRestoreUser={showRestoreUser}
                        setShowRestoreUser={setShowRestoreUser}
                    />
                )}

                {/* Create_User_Form */}
                {!showFilters && !showUpdateUser && createUser && (
                    <CreateUserForm
                        createUser={createUser}
                        setShowCreateUser={setShowCreateUser}
                    />
                )}
                {/* Search_User_Form */}
                {!showUpdateUser && !createUser && showFilters && (
                    <form className="userForms seachForm">
                        <article className="widgetWrap">
                            <div className="widgetBody">
                                <div className="dbRow">
                                    <div className="dbCol dbColLg33">
                                        <div className="formRow">
                                            <label className="txtLabel">Username</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    name="username"
                                                    value={formData.username}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg33">
                                        <div className="formRow">
                                            <label className="txtLabel">First Name</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    name="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg33">
                                        <div className="formRow">
                                            <label className="txtLabel">Surname</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    name="surname"
                                                    value={formData.surname}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg33">
                                        <div className="formRow">
                                            <label className="txtLabel">Email</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="email"
                                                    className="formControl"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleSearchChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg33">
                                        <div className="formRow">
                                            <label className="txtLabel">Department</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="department_id"
                                                    className="formControl"
                                                    id="department_id"
                                                    value={formData.department_id}
                                                    onChange={handleSearchChange}
                                                >
                                                    <option value="" defaultValue>
                                                        Select Department
                                                    </option>
                                                    {departmentListing &&
                                                        departmentListing?.length > 0 &&
                                                        departmentListing.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg33">
                                        <div className="formRow">
                                            <label className="txtLabel">Position</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="position_id"
                                                    className="formControl"
                                                    id="position"
                                                    value={formData.position_id}
                                                    onChange={handleSearchChange}
                                                >
                                                    <option value="" defaultValue>
                                                        Select Position
                                                    </option>
                                                    {positionListing &&
                                                        positionListing?.length > 0 &&
                                                        positionListing.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg33">
                                        <div className="formRow">
                                            <label className="txtLabel">Status</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="status"
                                                    className={`formControl`}
                                                    id="status"
                                                    value={formData.status}
                                                    onChange={handleSearchChange}
                                                >
                                                    <option value="" defaultValue>
                                                        Select Status
                                                    </option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="btnWrap">
                                    <button
                                        onClick={() => {
                                            handleShowFilter();
                                        }}
                                        type="button"
                                        className="btn btnWhite"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleSearch}
                                        className="btn btnPrimary"
                                    >
                                        Search{" "}
                                        {loading && (
                                            <HalfCircleSpinner
                                                size={18}
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                            />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </article>
                    </form>
                )}

                {/* Update_UserInfo_Form */}
                {!showFilters && !createUser && showUpdateUser && (
                    <UpdateUser
                        selectedUser={selectedUser}
                        handleShowUpdateUser={handleShowUpdateUser}
                        setUsersList={setUsersList}
                        setPagination={setPagination}
                    />
                )}

                {/* Users_Listing */}
                {showTransferUserSearch !== "individually" && (
                    <div className="tableWidget">
                        <article className="widgetWrap">
                            <div className="widgetHeader">
                                <span>User Management</span>
                                <div className="tabHeadActionWrap">
                                    {permission?.some(
                                        (p) => p.name === userPermissions.USERS_CREATE
                                    ) && (
                                            <button
                                                onClick={() => {
                                                    handleScrollToTop();
                                                    setShowCreateUser(true);
                                                    setShowFilters(false);
                                                    setShowUpdateUser(false);
                                                    setShowDeactivateUser(false);
                                                    setShowTransferUserSearch("");
                                                    setShowDeactivateUser(false);
                                                    setShowTransferCase(false);
                                                    setShowForDepartment(false);
                                                    setDepartment(null);
                                                    setGroup(null);
                                                    setGroupListing([]);
                                                }}
                                                type="button"
                                                className={`actionBtn ${createUser && "active"}`}
                                            >
                                                Add User <i className="fa-solid fa-user-plus"></i>
                                            </button>
                                        )}

                                    <button
                                        onClick={() => {
                                            handleScrollToTop();
                                            setShowFilters(true);
                                            setShowCreateUser(false);
                                            setShowUpdateUser(false);
                                            setShowDeactivateUser(false);
                                            setShowTransferUserSearch("");
                                            setShowDeactivateUser(false);
                                            setShowTransferCase(false);
                                            setShowForDepartment(false);
                                            setDepartment(null);
                                            setGroup(null);
                                            setGroupListing([]);
                                        }}
                                        type="button"
                                        className={`actionBtn ${showFilters && "active"}`}
                                    >
                                        Search Filter{" "}
                                        <i className="fa-sharp fa-solid fa-filters"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="tableWrap">
                                <table className="table fontSmall textCenter w100">
                                    <thead>
                                        <tr>
                                            <th>Username</th>
                                            <th>Name</th>
                                            <th>Position</th>
                                            <th>Department</th>
                                            <th>Email</th>
                                            <th>Contact Info</th>
                                            <th>Status</th>
                                            <th>Profile Image</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {usersList && usersList?.length > 0 ? (
                                            usersList.map((user) => (
                                                <tr key={user.id}>
                                                    <td data-label="Username">
                                                        {user.username ? user.username : "-"}
                                                    </td>
                                                    <td data-label="Name" className="txtCaptilize">
                                                        {user.salutation}.{" "}
                                                        {user.first_name ? user.first_name : ""}{" "}
                                                        {user.surname ? user.surname : ""}
                                                    </td>
                                                    <td data-label="Position" className="txtCaptilize">
                                                        {user.position_name ? user.position_name : "-"}
                                                    </td>
                                                    <td data-label="Department" className="txtCaptilize">
                                                        {user.department_name ? user.department_name : "-"}
                                                    </td>
                                                    <td data-label="Email">
                                                        {user.email ? user.email : "-"}
                                                    </td>
                                                    <td data-label="Contact Info">
                                                        {user.phone_number ? user.phone_number : "-"}
                                                    </td>
                                                    <td data-label="Status">
                                                        {user.status === 1
                                                            ? "Active"
                                                            : user.status === 0
                                                                ? "Inactive"
                                                                : "-"}
                                                    </td>
                                                    <td data-label="Profile Image">
                                                        <div className="userImageWrap">
                                                            {user.image ? (
                                                                <img
                                                                    src={user.image}
                                                                    className="imgFluid"
                                                                    alt="image description"
                                                                    onContextMenu={(e) => e.preventDefault()}
                                                                />
                                                            ) : (
                                                                <div className="placeholderImage">?</div>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td data-label="Actions">
                                                        <div>
                                                            {permission?.some(
                                                                (p) =>
                                                                    p.name === userPermissions.USERS_UPDATE &&
                                                                    !user.deleted_at
                                                            ) && (
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            setShowUpdateUser(true);
                                                                            setShowFilters(false);
                                                                            setShowCreateUser(false);
                                                                            setSelectedUser({ ...user });
                                                                            handleScrollToTop();
                                                                            setShowDeactivateUser(false);
                                                                            setShowTransferUserSearch("");
                                                                            setShowDeactivateUser(false);
                                                                            setShowTransferCase(false);
                                                                            setShowForDepartment(false);
                                                                            setDepartment(null);
                                                                            setGroup(null);
                                                                            setGroupListing([]);
                                                                        }}
                                                                        className="actionIcn edit fa-solid fa-pencil"
                                                                    ></button>
                                                                )}
                                                            {permission?.some(
                                                                (p) => p.name === userPermissions.USERS_DELETE
                                                            ) &&
                                                                !user.deleted_at && (
                                                                    <button
                                                                        onClick={() => {
                                                                            setSelectedUserToDeactivate({ ...user });
                                                                            handleDeleteClick(user.id);
                                                                            handleScrollToTop();
                                                                            setShowUpdateUser(false);
                                                                            setShowFilters(false);
                                                                            setShowCreateUser(false);
                                                                        }}
                                                                        type="button"
                                                                        className="actionIcn delete fa-solid fa-trash"
                                                                    ></button>
                                                                )}
                                                            {user.deleted_at && (
                                                                <i
                                                                    onClick={() => {
                                                                        handleScrollToTop();
                                                                        handleShowUserRestoreModal(user);
                                                                        setShowUpdateUser(false);
                                                                        setShowFilters(false);
                                                                        setShowDeactivateUser(false);
                                                                        setShowCreateUser(false);
                                                                        setShowTransferUserSearch("");
                                                                        setShowDeactivateUser(false);
                                                                        setShowTransferCase(false);
                                                                        setShowForDepartment(false);
                                                                        setDepartment(null);
                                                                        setGroup(null);
                                                                        setGroupListing([]);
                                                                    }}
                                                                    className="actionIcn inactive fa-regular fa-user-slash"
                                                                ></i>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : loading ? (
                                            <tr>
                                                <td colSpan={9}><HalfCircleSpinner
                                                    size={18}
                                                    color='black'
                                                    style={{
                                                        display: "inline-block",
                                                        marginLeft: "7px",
                                                        position: "relative",
                                                        top: "2px",
                                                    }}
                                                /></td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td colSpan={9}>No record found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="widgetFooter">
                                <div className="textCenter">
                                    {pagination && Object.keys(pagination)?.length > 0 && (
                                        <Pagination
                                            pagination={pagination}
                                            handlePageClick={handlePageClick}
                                        />
                                    )}
                                </div>
                            </div>
                        </article>
                    </div>
                )}
            </div>
        </>
    );
};

export default UserManagement;
