
import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import { HalfCircleSpinner } from 'react-epic-spinners';
import Pagination from "../../components/pagination";
import { useAuth } from "../../api/services/auth";
import { Navigate } from "react-router-dom";
import { userPermissions } from "../../enums";
import UpdatePositionInfo from "../../components/position/updatePositionInfo";
import CreatePosition from "../../components/position/createPosition";
import positionListing from "../../api/services/positionListing";
import { positionDelete } from "../../api/services/positions";

const Position = (props) => {
    const { userExist, permission } = useAuth()
    const [positionsList, setPositionsList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [createPosition, setShowCreatePosition] = useState(false);
    const [token, setToken] = useState(localStorage.getItem("authToken"));
    const [showLoader, setShowLoader] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState({});
    const [selectedPositionId, setSelectedPositionId] = useState(null);
    const [loading, setLoading] = useState(true);


    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Position Management | Cappagh";
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };
    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        positionListing.getPositions(pageNo)
            .then((res) => {
                handleScrollToTop();
                setPositionsList(res.data);
                setPagination(res.meta);
            }
            ).catch(error => console.error('Error fetching data:', error));
    };


    // Handle_Show_Update_position
    const handleShowUpdateUser = () => {
        setShowUpdateUser(!showUpdateUser)
    }

    // Handle_Delete_position
    const handleDeleteClick = (userId) => {
        setSelectedPositionId(userId);
    };

    // Handle_Delete_position
    const handleDeletePosition = () => {
        setShowLoader(true);

        positionDelete.delete(selectedPositionId)
            .then((res) => {

                setShowLoader(false);
                toast.success(res.message);
                positionListing.getPositions(1)
                    .then((res) => {
                        setPositionsList(res.data);
                        setPagination(res.meta);
                    }
                    ).catch(error => console.error('Error fetching data:', error));

            }
            ).catch((error) => {
                setShowLoader(false);
                toast.error(error.data.message);
            });
        setSelectedPositionId(null);
    };

    // Get_positions_Listing
    useEffect(() => {
        positionListing.getPositions(1)
            .then((res) => {
                setLoading(false)
                setPositionsList(res.data);
                setPagination(res.meta);
            }
            ).catch(error => {
                setLoading(false)
                console.error('Error fetching data:', error)
            });

    }, []);



    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <>
            <div className="dbContentWrap">
                {/* Create_User_Form */}
                {!showFilters && !showUpdateUser && createPosition &&
                    <CreatePosition
                        createPosition={createPosition}
                        setShowCreatePosition={setShowCreatePosition}
                        setPositionsList={setPositionsList}
                        setPagination={setPagination}
                    />
                }

                {/* Update_positionInfo_Form */}
                {!showFilters && !createPosition && showUpdateUser &&
                    <UpdatePositionInfo
                        setShowUpdateUser={setShowUpdateUser}
                        selectedPosition={selectedPosition}
                        handleShowUpdateUser={handleShowUpdateUser}
                        setPositionsList={setPositionsList}
                        setPagination={setPagination}
                    />
                }
                {/* position_Listing */}
                <div className="tableWidget">
                    <article className="widgetWrap">
                        <div className="widgetHeader">
                            <span>Position Management</span>
                            <div className="tabHeadActionWrap">
                                {permission?.some(p => (p.name === userPermissions.POSITIONS_CREATE)) && (
                                    <button onClick={() => {
                                        handleScrollToTop();
                                        setShowCreatePosition(true);
                                        setShowFilters(false);
                                        setShowUpdateUser(false);
                                    }} type="button" className={`actionBtn ${createPosition && 'active'}`}>Add Position <i className="fa-solid fa-user-plus"></i></button>
                                )}
                            </div>
                        </div>
                        <div className="tableWrap">
                            <table className="table fontSmall textCenter w100">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(positionsList && (positionsList?.length > 0)) ? positionsList.map(item => (
                                        <tr key={item.id}>
                                            <td data-label="Name">{item.name}</td>
                                            <td data-label="Status">{item.status == '1' ? "Active" : "Inactive"}</td>

                                            <td data-label="Actions">
                                                <div>
                                                    {permission?.some(p => (p.name === userPermissions.POSITIONS_UPDATE)) && (
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleScrollToTop();
                                                                setShowUpdateUser(true)
                                                                setShowFilters(false);
                                                                setShowCreatePosition(false);
                                                                setSelectedPosition({ ...item })
                                                            }}
                                                            className="actionIcn edit fa-solid fa-pencil"
                                                        ></button>
                                                    )}
                                                    {permission?.some(p => (p.name === userPermissions.POSITIONS_DELETE)) && (

                                                        <button
                                                            onClick={() => handleDeleteClick(item.id)}
                                                            type="button"
                                                            className="actionIcn delete fa-solid fa-trash"
                                                        ></button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )) : loading ?
                                        <tr>
                                            <td colSpan={9}><HalfCircleSpinner
                                                size={18}
                                                color='black'
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                            /></td>
                                        </tr> :
                                        <tr>
                                            <td colSpan={8}>No record found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="widgetFooter">
                            <div className="textCenter">
                                {pagination && (Object.keys(pagination)?.length > 0) && (
                                    <Pagination pagination={pagination} handlePageClick={handlePageClick} />
                                )}
                            </div>
                        </div>
                    </article>
                </div>
            </div>
            {/* Delete_position_Popup */}
            <Popup className="deleteUserModal" open={selectedPositionId !== null} onClose={() => setSelectedPositionId(null)}>
                {selectedPositionId !== null && (
                    <div className="modal textCenter">
                        <p>Are you sure you want to delete this Position.</p>
                        <div className="btnWrap">
                            <button onClick={() => setSelectedPositionId(null)} type="button" className="btn btnSecondary">Cancel</button>
                            <button
                                onClick={handleDeletePosition}
                                type="button"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >Delete
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: 'relative',
                                            top: '2px',
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </Popup>
        </>
    );
}

export default Position;