import httpClient from "../httpClient";
import { API_PROCEDURE_LISTING } from "../config";

const procedureListing = {
    getProcedures: (pageNo, params) => {
        params = params && typeof params === 'object' ? params : {};

        const queryString = Object.entries(params)
            .filter(([key, value]) => value !== '' && value !== null)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const endpoint = API_PROCEDURE_LISTING + `?page=${pageNo ?? '1'}${queryString ? `&${queryString}` : ''}`;

        return httpClient.get(endpoint);
    },
};

export default procedureListing;
