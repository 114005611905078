import React, { createContext, useState, useContext, useEffect } from 'react';
import loginService from './loginService';
import { toast } from 'react-toastify';
import { userProfile } from './user-services/role';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const token = localStorage.getItem('authToken');
	const userRole = localStorage.getItem('userRole');
	const loggedInUserPermission = localStorage.getItem('loggedInUserPermission');
	const loggedInUser = localStorage.getItem('loggedInUser');
	const [user, setUser] = useState(loggedInUser ? JSON.parse(loggedInUser) : null);
	const [permission, setPermission] = useState(loggedInUserPermission ? JSON.parse(loggedInUserPermission) : null);
	const [LoginError, setLoginError] = useState();
	const [showLoader, setShowLoader] = useState(false);
	const [userExist, setUserExist] = useState(token ? true : false);
	const [isSidebarDropDownOpen, setIsSidebarDropDownOpen] = useState(false);

	const handleShow = () => {
		setIsSidebarDropDownOpen(!isSidebarDropDownOpen)
	}

	useEffect(() => {
		async function loadUserFromLocalStorage() {
			if (token) {
				setShowLoader(true);
				setUserExist(true)
				setUser(JSON.parse(loggedInUser));
				setPermission(JSON.parse(loggedInUserPermission));
			}
		}
		loadUserFromLocalStorage();

		if (user) {
			userProfile.get(user.id)
				.then((res) => {
					setUser(res.data[0]);
					localStorage.setItem('loggedInUser', JSON.stringify(res.data[0]));
				}
				).catch(error => {
					console.error('Error fetching data:', error)
				});
		}

	}, []);

	const login = async (username, password) => {
		setLoginError('');
		setShowLoader(true);

		const requestBody = {
			username: username,
			password: password,
		};

		loginService.memberLoginInfo(requestBody)
			.then((response) => {
				if (response.status === 200) {
					localStorage.setItem('authToken', response.data.token);
					setUser(response.data.user);
					localStorage.setItem('loggedInUser', JSON.stringify(response.data.user));
					localStorage.setItem('loggedInUserPermission', JSON.stringify(response.data.permissions));
					localStorage.setItem('userRole', response.data.role);
					window.location.pathname = "/dashboard";
				} else {
					setShowLoader(false);
					toast.error(response.message);
				}
			}
			).catch((error) => {
				setShowLoader(false);
				toast.error(error.data.message ?? 'Network Error');
			});
	};
	return (
		<AuthContext.Provider
			value={{
				isAuthenticated: !!user,
				user,
				userRole,
				permission,
				login,
				LoginError,
				showLoader,
				setShowLoader,
				userExist,
				handleShow,
				isSidebarDropDownOpen
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);
