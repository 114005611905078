import React, { useState, useEffect } from "react";
import { useAuth } from "../../api/services/auth";
import { Navigate } from "react-router-dom";
import NotificationListing from '../../components/notification-components/notification-listing'
import CreateNotification from '../../components/notification-components/create-notification'
import { userPermissions } from "../../enums";
import { useNotification } from "../../context/NotificationsContext";

const Notifications = () => {
    const { setNotificationsCount } = useNotification();
    const { userExist, permission, userRole } = useAuth();
    const [notificationsList, setNotificationsList] = useState([]);

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Notifications | Cappagh";
    }, []);

    // Set notificationCount to 0 when the page is loaded.
    useEffect(() => {
        localStorage.setItem('notificationCount', 0);
        setNotificationsCount(0);
    }, []);

    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <div className="dbContentWrap">
            {/* Create_Notifications */}
            {permission?.some(p => (p.name === userPermissions.NOTIFICATIONS_CREATE)) && (
                <CreateNotification
                    setNotificationsList={setNotificationsList}
                    notificationsList={notificationsList}
                />
            )}

            {/* Notifications_Listing */}
            <NotificationListing
                notificationsList={notificationsList}
                setNotificationsList={setNotificationsList}
            />
        </div>
    );
}

export default Notifications;