import React, { createContext, useState, useContext, useEffect } from 'react';
import getDepartmentsList from '../api/services/userDepartmentsList';
import { userPermissions } from '../enums';
const DepartmentContext = createContext({});

export const DepartmentProvider = ({ children }) => {
    const token = localStorage.getItem('authToken');
    const loggedInUserPermission = localStorage.getItem('loggedInUserPermission');
    const loggedInUser = localStorage.getItem('loggedInUser');
    const permission = loggedInUserPermission ? JSON.parse(loggedInUserPermission) : null;
    const user = loggedInUser ? JSON.parse(loggedInUser) : null;
    const [departmentListing, setDepartmentListing] = useState([]);

    useEffect(() => {
        if (token && permission && user && permission?.some(p => (p.name === userPermissions.ENTITY_DEPARTMENT_USERS_DEPARTMENTS))) {
            getDepartmentsList.get(user.id).then((res) => {
                if (res.data && res.data && res.data.length > 0) {
                    setDepartmentListing(res.data.map(item => ({
                        value: item.id,
                        label: item.name,
                    })))
                }
            }).catch(error => console.error('Error fetching data:', error));
        }
    }, []);

    return (
        <DepartmentContext.Provider
            value={{
                departmentListing,
            }}
        >
            {children}
        </DepartmentContext.Provider>
    );
};

export const useDepartment = () => useContext(DepartmentContext);
