import React, { useState, useEffect } from "react";
import { useInput } from "../../hooks/useInput";
import { toast } from "react-toastify";
import { HalfCircleSpinner } from "react-epic-spinners";
import createComorbidity from "../../api/services/createComorbidity";
import comorbidityListing from "../../api/services/comorbiditieListing";
import { SearchSelect } from "../searchSelect";
import { colourStyles } from "../../data/index";
import { useAllDepartments } from "../../context/allDepartmentContext";
import Popup from "reactjs-popup";
import { recreateComorbidity } from "../../api/services/departmnent-services/recreate";

const CreateComorbidities = (props) => {
    const { departmentListing } = useAllDepartments();
    const [name, bindName, resetName] = useInput("");
    const [department, setDepartment] = useState([]);
    const [status, setStatus] = useState("1");
    const [errors, setErrors] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [flag, setFlag] = useState("");

    // Handle_Check_Validation
    const checkValidation = () => {
        let errors = {};
        let isValid = true;

        if (!name) {
            isValid = false;
            errors["name"] = "Please enter your name.";
        }
        if (!department || department?.length < 1) {
            isValid = false;
            errors["department"] = "Please select a Department.";
        }
        if (!status) {
            isValid = false;
            errors["status"] = "Please select status.";
        }
        setErrors(errors);
        return isValid;
    };

    // Handle_Create_Comorbidity
    const handleCreateComorbidity = (event) => {
        event.preventDefault();

        if (checkValidation()) {
            setShowLoader(true);

            const requestBody = {
                name: name,
                status: status,
                department_ids: department.map((dept) => dept.value),
            };

            createComorbidity
                .create(requestBody)
                .then((res) => {
                    if (res.status === 200) {
                        toast.success(res.message);
                        setShowLoader(false);

                        comorbidityListing
                            .getComorbidities(1)
                            .then((res) => {
                                props.setShowCreateComorbidity(false);
                                props.setComorbidityList([...res.data]);
                                props.setPagination(res.meta);
                            })
                            .catch((error) =>
                                toast.error("Error While Fetching The Comorbidity List")
                            );
                    }
                })
                .catch((error) => {
                    setShowLoader(false);
                    if (error?.data?.errors?.id) {
                        setFlag(error.data.message);
                    } else {
                        toast.error(error.data.errors.name[0]);
                    }
                });
        }
    };

    // Restore_Comorbidity
    const restoreComorbidityEntity = () => {
        setLoader(true);
        recreateComorbidity
            .recreate(flag)
            .then((res) => {
                if (res.status === 200) {
                    toast.success(res.message);
                    setLoader(false);
                    comorbidityListing
                        .getComorbidities(1)
                        .then((res) => {
                            props.setShowCreateComorbidity(false);
                            props.setComorbidityList([...res.data]);
                            props.setPagination(res.meta);
                        })
                        .catch((error) =>
                            toast.error("Error While Fetching The Comorbidity List")
                        );
                }
            })
            .catch((error) => {
                setLoader(false);
                toast.error(error.data.message);
            });
    };

    // Handle_Status_Select
    const handleStatusSelect = (event) => {
        setStatus(event.target.value);
    };

    // Handle_Department_Select
    const handleDepartmentSelect = (event) => {
        setDepartment(event);
    };

    return (
        <>
            <form
                className="userForms updateUserForm"
                onSubmit={(event) => handleCreateComorbidity(event)}
            >
                <article className="widgetWrap">
                    <div className="widgetBody">
                        <div className="dbRow">
                            <div className="dbCol dbColLg75">
                                <div className="dbRow">
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Name</label>
                                            <div className="inputWrap">
                                                <input
                                                    type="text"
                                                    className={`formControl ${errors.name && "error"}`}
                                                    {...bindName}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Status</label>
                                            <div className="selectWrap posRelative">
                                                <select
                                                    name="status"
                                                    className={`formControl ${errors.status && "error"}`}
                                                    id="status"
                                                    defaultValue={status}
                                                    onChange={handleStatusSelect}
                                                >
                                                    <option value="" defaultValue>
                                                        Select Status
                                                    </option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dbCol dbColLg50">
                                        <div className="formRow">
                                            <label className="txtLabel">Department</label>
                                            <div className="selectWrap posRelative">
                                                <SearchSelect
                                                    name="department"
                                                    id="department"
                                                    className={`basic-multi-select multiSelectDropdown txtRight ${errors.department && "error"
                                                        }`}
                                                    classNamePrefix="select"
                                                    styles={colourStyles}
                                                    options={departmentListing}
                                                    defaultValue={department}
                                                    onChange={(event) => {
                                                        handleDepartmentSelect(event);
                                                    }}
                                                    isMulti
                                                    placeholder="Select Department"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btnWrap">
                            <button
                                onClick={() => {
                                    props.setShowCreateComorbidity(false);
                                }}
                                type="button"
                                className="btn btnWhite"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn btnPrimary"
                                disabled={showLoader}
                            >
                                Create Comorbidity
                                {showLoader && (
                                    <HalfCircleSpinner
                                        size={18}
                                        style={{
                                            display: "inline-block",
                                            marginLeft: "7px",
                                            position: "relative",
                                            top: "2px",
                                        }}
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                </article>
            </form>

            {/* Restore_Comorbidity_Popup */}
            <Popup
                className="restoreDepartmentModal deleteUserModal"
                open={flag !== ""}
                onClose={() => setFlag("")}
            >
                <div className="modal textCenter">
                    <p>
                        Comorbidity exists with the same name. Do you want to restore it?
                    </p>
                    <div className="btnWrap">
                        <button
                            onClick={() => setFlag("")}
                            type="button"
                            className="btn btnSecondary"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={restoreComorbidityEntity}
                            type="button"
                            className="btn btnPrimary"
                            disabled={loader}
                        >
                            Restore
                            {loader && (
                                <HalfCircleSpinner
                                    size={18}
                                    style={{
                                        display: "inline-block",
                                        marginLeft: "7px",
                                        position: "relative",
                                        top: "2px",
                                    }}
                                />
                            )}
                        </button>
                    </div>
                </div>
            </Popup>
        </>
    );
};

export default CreateComorbidities;
