
import React, { useState, useEffect } from "react";
import { HalfCircleSpinner } from 'react-epic-spinners';
import Pagination from "../../components/pagination";
import { useAuth } from "../../api/services/auth";
import { Navigate } from "react-router-dom";
import { userPermissions } from "../../enums";
import { requestsSupportListing } from "../../api/services/support-request";
import AddRequest from "../../components/request-support/addRequest";

const SupportRequest = (props) => {
    const { userExist, permission } = useAuth()
    const [requestsList, setRequestsList] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [createUser, setShowCreateUser] = useState(false);
    const [token, setToken] = useState(localStorage.getItem("authToken"));
    const [loading, setLoading] = useState(true);
    let oneRequest = true

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Support Request | Cappagh";
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling behavior
        });
    };

    // Hadle_Page_Click
    const handlePageClick = (pageNo) => {
        requestsSupportListing.get(pageNo)
            .then((res) => {
                handleScrollToTop()
                setRequestsList(res.data);
                setPagination(res.meta);
            }
            ).catch(error => console.error('Error fetching data:', error));
    };

    // Handle_Show_Update_User
    const handleShowUpdateUser = () => {
        setShowUpdateUser(!showUpdateUser)
    }

    // Get_Users_Listing
    useEffect(() => {
        if (oneRequest) {
            oneRequest = false;
            requestsSupportListing.get(1)
                .then((res) => {
                    setLoading(false)
                    setRequestsList(res.data);
                    setPagination(res.meta);
                }
                ).catch(error => {
                    setLoading(false)
                    console.error('Error fetching data:', error)
                });

        }
    }, []);


    if (!userExist) {
        return <Navigate to="/login" />
    }

    return (
        <>
            <div className="dbContentWrap">

                {/* Create_User_Form */}
                {!showFilters && !showUpdateUser && createUser &&
                    <AddRequest
                        createUser={createUser}
                        setShowCreateUser={setShowCreateUser}
                        token={token}
                        setRequestsList={setRequestsList}
                        setPagination={setPagination}
                    />
                }

                {/* Users_Listing */}
                <div className="tableWidget">
                    <article className="widgetWrap">
                        <div className="widgetHeader">
                            <span>Support Request</span>
                            <div className="tabHeadActionWrap">
                                {permission?.some(p => (p.name === userPermissions.CONTACT_REQUEST_STORE)) && (
                                    <button onClick={() => {
                                        setShowCreateUser(true);
                                        setShowFilters(false);
                                        setShowUpdateUser(false);
                                        handleScrollToTop();
                                    }} type="button" className={`actionBtn ${createUser && 'active'}`}>Contact Admin <i className="fa-solid fa-user-plus"></i></button>
                                )}
                            </div>
                        </div>
                        <div className="tableWrap">
                            <table className="table fontSmall textCenter w100">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Requested By</th>
                                        <th align="left">Support Request Content</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(requestsList && (requestsList?.length > 0)) ? requestsList.map(item => (
                                        <tr key={item.id}>
                                            <td data-label="Title">{item.heading}</td>
                                            <td data-label="Requested By">{item.requested_by}</td>
                                            <td align="left" data-label="Support Request Content">{item.content}</td>
                                        </tr>
                                    )) : loading ?
                                        <tr>
                                            <td colSpan={9}><HalfCircleSpinner
                                                size={18}
                                                color='black'
                                                style={{
                                                    display: "inline-block",
                                                    marginLeft: "7px",
                                                    position: "relative",
                                                    top: "2px",
                                                }}
                                            /></td>
                                        </tr> :
                                        <tr>
                                            <td colSpan={8}>No record found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="widgetFooter">
                            <div className="textCenter">
                                {pagination && (Object.keys(pagination)?.length > 0) && (
                                    <Pagination pagination={pagination} handlePageClick={handlePageClick} />
                                )}
                            </div>
                        </div>
                    </article>
                </div>
            </div>

        </>
    );
}

export default SupportRequest;