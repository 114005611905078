import { API_CASE_FILTER } from "../config";
import httpClient from "../httpClient";

const caseFilters = {
    getfilters: (stringValue) => {
        return httpClient.get(`${API_CASE_FILTER}?status=${stringValue}`);
    },
};

export default caseFilters;
